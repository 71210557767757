import { m, tx } from '#/browser-framework';
import { LabeledCheckbox, Named, pointable } from '#/browser-framework/comps';


/*
|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾|
| ☐ All            | <-- .FilterMenu__allContainer
|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾| <--+
| ☑ Chookie        |    |
| ☐ Bookamanny     |    |-: .FilterMenu__options
| ☑ Plong          |    |
|                  |    |
|__________________| <--+
*/
const Checkboxes = {
    view({attrs: {options, affectAll, affectOne}}) {
        return m('.FilterMenu__checkboxes',
            m('.FilterMenu__allContainer',
                m(LabeledCheckbox, {
                    state: options.every(({checked}) => checked),
                    onchange: () => {
                        affectAll();
                    },
                }, m('strong', 'All'))),
            m('.FilterMenu__options',
                options.map(({text, checked}, key) => {
                    return m(LabeledCheckbox, {
                        key,
                        state: checked,
                        onchange: (state) => {
                            affectOne(text, state);
                        },
                    }, text);
                })));
    },
};

/*
Menu that pops out from a table column header allowing filter
selection.

Looks like [ Blah ⊕ ] when closed or like this when open:

+--------+
| Blah ⊕ |
|         ‾‾‾‾‾‾‾‾‾|
| ☐ All            |
|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾|
| ☑ Chookie        |
| ☐ Bookamanny     |
| ☑ Plong          |
|                  |
|__________________|

*/
export default {
    view({attrs: {queriedRequests, filterId}, children}) {
        const open = queriedRequests.openedFilterMenu === filterId;
        const filterOptions = queriedRequests.filters[filterId];

        const cls = tx('FilterMenu', {
            open: open && filterOptions,
        });


        return m(pointable(cls), {
            onpointerup: (e) => {
                e.preventDefault();
                e.stopPropagation();
            },
        },
        m('span.FilterMenu__name', {
            onclick: () => {
                if (open) {
                    queriedRequests.closeAllFilterMenus();
                } else {
                    queriedRequests.openFilterMenu(filterId);
                }
            },
        }, ...children, m(Named, {name: 'icon-filter'})),
        (open && filterOptions)
            ? m(Checkboxes, {
                affectAll: () => {
                    queriedRequests.toggleAllFilters(filterId);
                    m.redraw();
                },
                affectOne: (text, checked) => {
                    queriedRequests.setFilterState(filterId, text, checked);
                },
                options: Array
                    .from(filterOptions.entries())
                    .map(([text, checked]) => {
                        return {
                            text,
                            checked,
                        };
                    }),
            })
            : null);
    },
};
