import axios from 'axios';

// each function in this lib makes an http request to woopra with the appropriate query params
// you can find woopra docs @ https://docs.woopra.com/reference#intro-http-tracking

export const WOOPRA_TRACKING_ROUTE = 'https://www.woopra.com/track/ce/?';
export let woopraEmail;


export const setWoopraEmail = function(emailAddress) {
    woopraEmail = emailAddress;
    return woopraEmail;
};

export const woopraTrack = async (queryParams, window, url = WOOPRA_TRACKING_ROUTE) => {
    const WOOPRA_COOKIE = window.woopra.cookie;
    const WOOPRA_PROJECT = window.woopra.options
        ? window.woopra.options.domain
        : 'evidentid.com';
    const params = { ...queryParams,
        project: WOOPRA_PROJECT,
        cookie: WOOPRA_COOKIE,
        response: 'JSON',
        cv_email: woopraEmail,
    };

    await axios
        .get(url, {
            params,
        })
        .catch((err) => (console.warn(err)));
};

export const woopraTrackRegistration = (email) => {
    setWoopraEmail(email);
    woopraTrack({
        event: 'register',
    }, window);
};

export const woopraTrackLogin = (method, email) => {
    setWoopraEmail(email);
    woopraTrack({
        event: 'login',
        ce_method: method,
    }, window);
};

export const woopraTrackEmailVerification = (clickSend, clickResend) => {
    woopraTrack({
        event: 'send_email_verification',
        ce_click_resend: clickResend,
        ce_click_send: clickSend,
    }, window);
};

export const woopraTrackAcceptTerms = (
    clickAccept,
    doLater) => {
    woopraTrack({
        event: 'accept_terms',
        ce_click_accept: clickAccept,
        ce_do_later: doLater,
    }, window);
};

export const woopraTrackSetupBilling = (
    cardHolderName,
    doLater) => {
    woopraTrack({
        event: 'setup_billing',
        ce_card_holder_name: cardHolderName,
        ce_do_later: doLater,
    }, window);
};

export const woopraTrackVerifyBusiness = ({
    bizName,
    dbaName,
    dunsNumber,
    taxId,
    streetAddress,
    city,
    state,
    zip,
    doLater}) => {
    woopraTrack({
        event: 'verify_business',
        ce_business_name: bizName,
        ce_dba_name: dbaName,
        ce_duns_number: dunsNumber,
        ce_tax_id: taxId,
        ce_street_address: streetAddress,
        ce_city: city,
        ce_state: state,
        ce_zip: zip,
        ce_do_later: doLater,
    }, window);
};

// The user has completed the confirmed Identity
export const woopraTrackConfirmIdentity = (
    country,
    usedPassport,
    doLater) => {
    woopraTrack({
        event: 'confirm_identity',
        ce_country: country,
        ce_usedPassport: usedPassport,
        ce_doLater: doLater,
    }, window);
};

// sends all steps completed thus far
export const woopraTrackCompleteStep = (
    confirmedIdentity,
    setUpBilling,
    verifiedBusiness,
    acceptedTerms) => {
    woopraTrack({
        event: 'completed_steps',
        ce_confirmed_identity: confirmedIdentity,
        ce_setup_billing: setUpBilling,
        ce_verified_business: verifiedBusiness,
        ce_accepted_terms: acceptedTerms,
    }, window);
};
