import { logger, harness, m, router } from '#/browser-framework';
import { Button, Overlay, Spinner } from '#/browser-framework/comps';


export default {
    view({attrs: { App }}) {
        const {dialogState} = App;

        return m('.DeleteIdoRequestsDialog',
            m('.DeleteIdoRequestsDialog__header',
                m('h2.DeleteIdoRequestsDialog__title', 'Are you sure you want to reset this IDO?'),
                m('span.fg-danger.DeleteIdoRequestsDialog__subtitle', 'This action is permanent and cannot be undone.')),
            m('.DeleteIdoRequestsDialog__form',
                m('span', `Type the word DELETE below. This action will
                    delete all requests and attributes associated with this IDO.`),
                m('input.DeleteIdoRequestsDialog__input', harness('delete-demo-ido-request-dialog-text-field', {
                    value: dialogState.confirmText,
                    oninput(e) {
                        dialogState.confirmText = e.target.value;
                    },
                }))),
            m('.DeleteIdoRequestsDialog__controls',
                m('.DeleteIdoRequestsDialog__action',
                    m(Button, {
                        flags: {
                            secondary: true,
                        },
                        onpress() {
                            App.hideDialog();
                        },
                    }, 'Cancel')),
                m('.DeleteIdoRequestsDialog__action',
                    m(Button, harness('delete-demo-ido-request-dialog-button', {
                        disabled: dialogState.waiting || App.dialogState.confirmText !== 'DELETE',
                        flags: {
                            danger: true,
                        },
                        onpress() {
                            dialogState.waiting = true;

                            App.user.selectedRp.deleteAllRequestsByIdoId(dialogState.idOwnerId)
                                .then(() => App.user.selectedRp.queriedRequests.hardRefresh())
                                .catch((deletionError) => {
                                    logger.error(deletionError);
                                })
                                .then(() => {
                                    App.hideDialog();
                                    dialogState.waiting = false;

                                    m.redraw();
                                });


                            router.go(`/${App.user.selectedRp.name}/requests`);
                        },
                    }), 'Delete'))),
            (dialogState.waiting) && m(Overlay, m(Spinner)));
    },
};
