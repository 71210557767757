import { m, harness, router, spa } from '#/browser-framework';
import { Button, Dropdown, FileDropbox } from '#/browser-framework/comps';

import { PendingDialog } from '#/rp-facing/views/PendingDialog';
import { CsvUploadErrorDialog } from '#/rp-facing/views/CsvUploadErrorDialog';


export const makeSendHandler = async function({App, type}) {
    const {
        stagedRequest,
        name,
        queriedRequests,
    } = App.user.selectedRp;

    try {
        await stagedRequest.submit(type);
        queriedRequests.clear({hard: true});
        router.go(`/${name}/requests`);
    } catch (err) {
        stagedRequest.submitting = false;
        App.snackbar.display('Something went wrong, please contact support if the error continues.', false);
        // Rethrow unless this was a spam call.
        if (App.user.selectedRp.stagedRequest.readyToSubmit) {
            throw err;
        }
    }
};

export default {
    view({attrs: {App}}) {
        const {
            readyToSubmit,
            templates,
            processCsv,
            clear,
        } = App.user.selectedRp.stagedRequest;

        return m('.TopToolbar.TemplateToolbar',
            m('.TemplateToolbar__selection',
                m(Dropdown, harness('create-request-toolbar-dropdown', templates))),
            m('.TemplateToolbar__controls',
                m('.TemplateToolbar__action',
                    m(FileDropbox.FileInputNode, {
                        id: 'csv-upload',
                        onchange(files) {
                            const [csv] = files;

                            if (csv instanceof File) {
                                App.showDialog(PendingDialog);
                                spa.redraw();

                                processCsv(csv)
                                    .then(({processed, badRecords}) => {
                                        const total = processed + badRecords.length;
                                        const isFail = Boolean(badRecords.length);

                                        App.user.selectedRp.queriedRequests.clear({ hard: true });
                                        App.snackbar.display(`${processed} of ${total} records processed.`, !isFail);
                                        App.hideDialog();

                                        if (isFail) {
                                            App.showDialog(CsvUploadErrorDialog, {
                                                badRecords,
                                            });
                                        } else {
                                            router.go(`/${App.user.selectedRp.name}/requests`);
                                        }
                                    });
                            }
                        },
                    })),
                m('.TemplateToolbar__action',
                    m(Button, {
                        flags: {
                            secondary: true,
                        },
                        onpress() {
                            FileDropbox.FileInputNode.openFileBrowser('csv-upload');
                        },
                    }, 'Upload CSV')),
                m('.TemplateToolbar__action',
                    m(Button, {
                        flags: {
                            secondary: true,
                        },
                        onpress: clear,
                    }, 'Clear')),
                m('.TemplateToolbar__action',
                    m(Button, {
                        flags: {
                            danger: true,
                        },
                        onpress() {
                            router.go(`/${App.user.selectedRp.name}/requests`);
                        },
                    }, 'Cancel')),
                App.user.maySelectAuthDomain() && (
                    m('.TemplateToolbar__action',
                        m(Button, {
                            flags: {
                                primary: true,
                            },
                            disabled: !readyToSubmit,
                            onpress: (event) => {
                                event.preventDefault();
                                makeSendHandler({App, type: 'web'});
                            },
                        }, 'Send Web Request'))),
                App.user.maySelectAuthDomain() && (
                    m('.TemplateToolbar__action',
                        m(Button, {
                            flags: {
                                primary: true,
                            },
                            disabled: !readyToSubmit,
                            onpress: (event) => {
                                event.preventDefault();
                                makeSendHandler({App, type: 'mobile'});
                            },
                        }, 'Send App Request'))),
                (!App.user.maySelectAuthDomain()) && (
                    m('.TemplateToolbar__action',
                        m(Button, harness('create-request-toolbar-submit-request', {
                            flags: {
                                primary: true,
                            },
                            disabled: !readyToSubmit,
                            onpress: (event) => {
                                event.preventDefault();
                                makeSendHandler({App});
                            },
                        }), 'Send Request')))));
    },
};
