import { spa } from '#/browser-framework';

import QueriedRequests from './QueriedRequests';
import StagedRequest from './StagedRequest';
import SubjectiveRequest from './SubjectiveRequest';
import SubjectiveAttributeArchive from './SubjectiveAttributeArchive';
import {SubjectiveOrganizedAttribute} from './SubjectiveAttributes';
import emailSettings from './settings/email-settings';
import apiConfigSettings from './settings/api-settings';
import brandingSettings from './settings/branding-settings';
import headingMessageSettings from './settings/heading-message-settings';
import richTextSetting from './settings/rich-text-setting';
import { devDocs } from './devDocs';
import cloneDeep from 'lodash.clonedeep';

/*
 * Pack attributes for an RP into an archive to simplify selection
 * and processing.
 */
function packArchive(attributes, rpName) {
    const subjectives = attributes
        .filter(({readableAttributeName}) => {
            return Boolean(readableAttributeName);
        })
        .map((attr) => {
            return SubjectiveOrganizedAttribute(attr, {
                namespace: rpName,
            });
        });

    return SubjectiveAttributeArchive(subjectives);
}


// Through a relyingparty interface you can manage RPRs and account settings
// given an onboarding state.
export default function SubjectiveRelyingParty(rp, client) {
    const api = client.bindRp(client.requestJson, rp.name);
    const requestCsvFromRp = client.bindRp(client.requestCsv, rp.name);

    const queriedRequests = QueriedRequests(api, requestCsvFromRp);

    const self = {
        api,
        name: rp.name,
        displayName: rp.displayName,
        admins: null,
        loaded: false,
        modified: false,
        stagedRequest: null,
        queriedRequests,
        attributeArchive: null,
        focusedSetting: null,
        settings: {
            internal: {
                bingle: false,
                bangle: false,
            },
            crimePolicy: {},
            adverseActionNotification: {
                automatic: false,
                preAdverseActionHtml: '',
                adverseActionHtml: '',
            },
            mvrPolicy: {
                tolerances: {
                    major: {
                        threshold: 3,
                        period: 2,
                    },
                    minor: {
                        threshold: 6,
                        period: 3,
                    },
                },
                selectedTemplate: 0,
                templates: [
                    'Select',
                    'Safe Driver',
                    'Commercial',
                    'Industrial',
                    'Mining',
                ],
                breakdown: {
                    major: Array.from('ABCDEFG').map((l) => {
                        return {
                            text: `Major ${l}`,
                            selected: Math.random() > 0.5,
                        };
                    }),
                    minor: Array.from('ABCDEFG').map((l) => {
                        return {
                            text: `Minor ${l}`,
                            selected: Math.random() > 0.5,
                        };
                    }),
                    ignored: Array.from('ABCDEFG').map((l) => {
                        return {
                            text: `No ${l}`,
                            selected: Math.random() > 0.5,
                        };
                    }),
                },
            },
            apiConfiguration: {
                webhookEvents: [
                    {
                        label: 'Request Activity - Request Complete',
                        name: 'rpRequestCompleted',
                    },
                    {
                        label: 'Subscription - Attribute Value Change',
                        name: 'subscribedAttributeChanged',
                    },
                    {
                        label: 'Request Activity - Submission Complete',
                        name: 'rpRequestSubmissionCompleted',
                    },
                    {
                        label: 'Request Activity - Request Timeout',
                        name: 'rpRequestTimeout',
                    },
                    {
                        label: 'Notification Failure',
                        name: 'notificationFailure',
                    },
                ],
                showNewWebhookForm: false,
                showKeyChars: false,
                editMode: [],
                editHooks: [],
                newWebhook: {
                    name: null,
                    url: null,
                },
            },
            previousState: null,
            unsaved: false,
            saveHistory() {
                if (!this.previousState) {
                    this.previousState = cloneDeep(this);
                    this.unsaved = true;
                    return true;
                }
            },
            // saves history, executes action and re-renders
            // page, when history was saved
            saveHistoryAndRedraw(execute = () => {}) {
                const result = this.saveHistory();
                execute();
                if (result) {
                    spa.redraw();
                }
            },
            restore() {
                const previousState = this.previousState;
                this.clearHistory();
                if (previousState) {
                    Object.assign(this, previousState);
                }
            },
            clearHistory() {
                delete this.previousState;
                this.unsaved = false;
            },
        },

        getRequestDetail: (requestId) =>
            api({ url: `/requests/${requestId}` })
                .then((rpr) => Promise.all([ rpr, (rpr.idOwner.domain === 'evidentapp')
                    ? Promise.resolve()
                    : api({ url: `/requests/${requestId}/idoWebUrl` })]))
                .then(([rpr, webLink]) =>
                    SubjectiveRequest(Object.assign(rpr, { webLink }), {
                        organizedAttributes: false,
                    })),

        createTemplate: ({attributes, description, name, summary}) =>
            api({
                method: 'POST',
                url: '/requestTemplates',
                data: {attributes, description, name, summary},
            }),

        loadTemplates: () =>
            api({ url: '/requestTemplates' }).then(({ requestTemplates }) => {
                self.requestTemplates = requestTemplates.sort((a, b) =>
                    a.name.localeCompare(b.name));

                return self.requestTemplates;
            }),


        // Target this RP for modification. This triggers on-demand
        // loading of relevant attributes and admin information.
        select() {
            if (self.loaded) {
                return Promise.resolve(self);
            }

            const vowArchive = api({url: '/attributeTypes'});
            const vowTandC = api({ url: '/settings/termsAndConditions' });
            const vowSettings = api({ url: '/settings' });
            const vowApiKey = api({ url: '/settings/apiKey'});
            const vowTemplates = self.loadTemplates();

            return Promise
                .all([vowArchive, vowTandC, vowSettings, vowApiKey, vowTemplates])
                .then(([{ attributeTypes }, terms, allSettings, apiKey]) => {
                    const archive = packArchive(attributeTypes || [], rp.name);
                    self.attributeArchive = archive;
                    self.docs = devDocs(self.name, self.requestTemplates, client);
                    Object.assign(self.settings, apiKey);
                    Object.assign(self.settings, terms);

                    self.stagedRequest = StagedRequest({
                        xhrFromRp: api,
                        templates: self.requestTemplates,
                        archive,
                    });
                    const vowPostHooks = apiConfigSettings(name, (data) =>
                        api({ url: '/settings/postHooks',
                            method: 'PUT',
                            data}))
                        .then((setting) => {
                            self.settings.apiConfiguration.editMode = allSettings.postHooks.map(() => false);
                            self.settings.webhooks = setting;
                            self.settings.webhooks.hydrate(allSettings.postHooks);
                        });
                    const vowEmailSetting = emailSettings(name, (data) =>
                        api({ url: '/settings/email', method: 'PUT', data }))
                        .then((setting) => {
                            self.settings.email = setting;
                            self.settings.email.hydrate(allSettings.email);
                        });

                    const vowConfMsgSetting = headingMessageSettings(name, (data) =>
                        api({ url: '/settings/idoConfirmationMessage', method: 'PUT', data }))
                        .then((setting) => {
                            self.settings.confirmationMessage = setting;
                            self.settings.confirmationMessage.hydrate(allSettings.idoConfirmationMessage);
                        });

                    const vowLinkExpMsgSetting = headingMessageSettings(name, (data) => {
                        allSettings.customAuthErrorStrings.TokenExpired = data;

                        return api({
                            url: '/settings/customAuthErrorStrings',
                            method: 'PUT',
                            data: allSettings.customAuthErrorStrings,
                        });
                    }).then((setting) => {
                        self.settings.linkExpirationMessage = setting;
                        self.settings.linkExpirationMessage.hydrate(
                            (allSettings.customAuthErrorStrings || { TokenExpired: '' }).TokenExpired);
                    });

                    const vowFcraSetting = richTextSetting(name, (data) => api({
                        url: '/settings/fcra',
                        method: 'PUT',
                        data,
                    })).then((setting) => {
                        self.settings.fcraDisclosureHtml = setting;
                        self.settings.fcraDisclosureHtml.hydrate(allSettings.fcraHtml);
                    });

                    const vowBrandingSetting = brandingSettings(name, (data) => {
                        Object.assign(self.settings.branding, {branding: data});

                        return api({
                            url: '/settings/branding',
                            method: 'PUT',
                            data,
                        });
                    }).then((setting) => {
                        self.settings.branding = setting;
                        self.settings.branding.hydrate(allSettings.branding);
                    });

                    return Promise.all([
                        vowEmailSetting,
                        vowPostHooks,
                        vowConfMsgSetting,
                        vowBrandingSetting,
                        vowLinkExpMsgSetting,
                        vowFcraSetting,
                    ]);
                })
                .then(() => {
                    self.loaded = true;
                    spa.redraw();

                    return self;
                });
        },

        // Mark agreement status of T&Cs for onboarding purposes.
        agreeToTerms: ({ acceptedTermsAndConditions }) => api({
            url: '/settings/termsAndConditions',
            method: 'PUT',
            data: {
                acceptedTermsAndConditions,
            },
        }).then(() => {
            self._forceOnboardFinished = true;
        }),

        deleteAllRequestsByIdoId: (idOwnerId) => api({
            method: 'DELETE',
            url: `/demo?idOwnerId=${encodeURIComponent(idOwnerId)}`,
        }),

        loadMvrPolicyTemplate(index) {
            self.settings.mvrPolicy.selectedTemplate = index;
        },
    };

    return self;
}
