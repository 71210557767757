import { m, spa } from '#/browser-framework';

import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import {
    StaticWalkthroughLayout,
    Walkthrough,
    CodeBlock,
    SupportEmail,
    TermDefinitions,
} from './DocsLayout';


const Content = {
    view() {
        return m(Walkthrough.block,
            m(Walkthrough.header,
                m('h2', 'Webhooks'),
                m('p',
                    'The Evident platform will notify your application of key updates using webhooks. ',
                    'During your onboarding contact you can provide some URLs to which the Evident ',
                    'platform will sends POST requests to at key moments with a JSON payload. ',
                    'Should one of these POST requests fail, we will attempt to POST to the ',
                    'webhook as many times as you specify, with as much time as you’d like between ',
                    'each retry.')),
            m(Walkthrough.section,
                m('h2', 'Setup'),
                'To set up webhooks, send the following information to ', m(SupportEmail),
                ' and your account will be configured accordingly:',
                m('ul',
                    m('li', 'The event to monitor'),
                    m('li', 'Webhook URL'),
                    m('li', 'Retry wait time on unsuccessful POST'),
                    m('li', 'Number of times to retry'))),
            m(Walkthrough.section,
                m('h2', 'Webhook event types'),
                m('p',
                    m('code', 'rpRequestCompleted'), ' fires when a verification finishes for ',
                    'an end user. The payload indicates the event type and the ID of the ',
                    'completed request.'),
                m(CodeBlock, {
                    lang: 'json',
                    sourceCodeText:
                    '{\n' +
                    '    "eventType": "rpRequestCompleted",\n' +
                    '    "rpRequestId": "c084149a-3047-4022-b0bb-5e8e7e3d68b4",\n' +
                    '}',
                }),
                m('p',
                    m('code', 'notificationFailure'), ' fires when the Evident platform fails ',
                    'to notify someone via email.'),
                m(CodeBlock, {
                    lang: 'json',
                    sourceCodeText: '{\n' +
                    '    "eventType": "notificationFailure",\n' +
                    '    "email": "john.doe@example.com",\n' +
                    '    "recipientType": "id_owner",\n' +
                    '    "status": "bounced",\n' +
                    '}',
                }),
                m(TermDefinitions, {
                    wrapTerm: TermDefinitions.codeLabels,
                    pairs: [
                        [
                            'recipientType',
                            m('p',
                                'If ', m('code', 'id_owner'), ', the recipient is an end-user that owns personal data. ',
                                'If ', m('code', 'relying_party'), ', the recipient is an administrator on your account.'),
                        ],
                        [
                            'email',
                            'The email that did not get a notification',
                        ],
                        [
                            'status',
                            m('p',
                                'If ', m('code', 'bounced'), ', the email was hard-bounced. ',
                                'If ', m('code', 'rejected'), ', an email server refused to ',
                                'deliver the email to the recipient’s address.'),
                        ],
                    ],
                })));
    },
};

export default ensureRpSelection(() => {
    spa.setView(Content, {
        layout: StaticWalkthroughLayout,
    });
});
