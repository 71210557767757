import { logger, validation } from '#/browser-framework';
import { AutoComplete, Dropdown } from '#/browser-framework/comps';
import { createRequestsFromCsvFile } from './bulkRequestCreation';
import { AttributeFiltering } from '#/rp-facing/state/AttributeFiltering';

// Represents RPR staged for submission by a single RP.
export default function StagedRequest({ xhrFromRp, archive, templates }) {
    const self = {
        archive,
        templates: Dropdown.makeViewModel({
            values: templates,
            placeholder: 'My Templates',
            toLabel: ({ name }) => name,
            onSelectionChanged(template) {
                self.archive.clearSelection();
                self.summary = self.description = self.notes = '';
                self.template = null;

                if (template) {
                    self.summary = template.summary;
                    self.description = template.description;
                    self.notes = template.notes;
                    self.template = template;

                    for (const p of template.attributes) {
                        if (self.archive.has(p)) {
                            self.archive.selectAttribute(p, true);
                        } else {
                            // Templates may not be in sync with RP attribute sets.
                            logger.warn(`Template attribute ${p} does not exist in RP attributes`);
                        }
                    }
                }
            },
        }),
        suggestions: AutoComplete.makeViewModel({
            onselect(match) {
                if (match) {
                    archive.selectAttribute(match.origin, true);

                    setImmediate(() => {
                        const inputElement = document.querySelector('#attr-autocomplete');
                        if (inputElement) {
                            inputElement.focus();
                        }
                    });
                }
            },
            computeMatches(needle) {
                const haystack = Array
                    .from(archive.unselectedAttributes())
                    .map(({ univocalName }) => univocalName);
                return new AttributeFiltering(haystack)
                    .getResults(needle);
            },
        }),
        summary: '',
        description: '',
        recipient: '',
        notes: '',
        submitting: false,
        template: null,

        get selectedTemplateId() {
            return self.template ? self.template._id : null;
        },
        get readyToSubmit() {
            return (
                self.description.length > 0
                && self.summary.length > 0
                && validation.isEmail(self.recipient)
                && (
                    self.archive.selectedAttributes().next().done === false ||
                    self.selectedTemplateId
                )
                && !self.submitting
            );
        },

        removeTemplate() {
            self.template = null;
            self.templates.value = 0;
        },

        clear({
            selection = true,
            recipient = true,
            description = true,
            notes = true,
            summary = true,
        } = {}) {
            self.removeTemplate();

            if (recipient) {
                self.recipient = '';
            }

            if (summary) {
                self.summary = '';
            }

            if (description) {
                self.description = '';
            }

            if (notes) {
                self.notes = '';
            }

            if (selection) {
                self.archive.clearSelection();
            }
        },

        processCsv(csvFile) {
            return createRequestsFromCsvFile(xhrFromRp, csvFile, templates);
        },

        async submit(type) {
            if (!self.readyToSubmit) {
                return Promise.reject();
            }

            self.submitting = true;

            const attributesRequested = Array
                .from(self.archive.selectedAttributes())
                .map(({path}) => {
                    return {
                        attributeType: path,
                    };
                });

            const data = {
                attributesRequested,
                recipient: {
                    email: self.recipient,
                },
                notes: self.notes,
                description: self.description,
                summary: self.summary,
            };

            if (self.selectedTemplateId) {
                data.templateId = self.selectedTemplateId;
            }

            if (type !== undefined) {
                data.recipient.domain = (type === 'web')
                    ? 'evidentid.blindtrust.thirdparty.idowner.evidentid.com'
                    : 'evidentapp';
            }

            await xhrFromRp({
                method: 'POST',
                url: '/requests',
                data,
            });

            // Clear only the parts that do nor pertain to a template.
            self.clear({
                recipient: true,
                notes: true,
                selection: false,
                description: false,
                summary: false,
            });

            self.submitting = false;
        },
    };

    return self;
}
