import SubjectiveAttributeCategory from './SubjectiveAttributeCategory';

function* itercategories(categories) {
    const cats = Object.keys(categories);

    for (const c of cats) {
        yield [c, categories[c]];
    }
}

function* iterattributes(categories) {
    // eslint-disable-next-line no-unused-vars
    for (const [_, cat] of itercategories(categories)) {
        yield* cat.attributes();
    }
}

/*
An aggregate of subjective attributes with O(1) attribute lookup and
uniform iteration over attributes, categories and groups.
*/
export default function SubjectiveAttributeArchive(subjectiveAttrs) {
    const categories = {};
    const catalog = {};
    let selectedCategory = '';
    let selectionsMade = 0;

    for (const subjective of subjectiveAttrs) {
        const {
            category,
            path,
            group,
            univocalName,
        } = subjective;

        if (!categories[category]) {
            categories[category] = SubjectiveAttributeCategory();
        }

        const index = categories[category].addAttribute(subjective);

        // For O(1) lookup.
        catalog[path] = catalog[univocalName] = categories[category].getGroup(group)[index];
    }

    return {
        selectAttribute(pathOrDisplayName, selected) {
            // Use autoincrementer to preserve selection order
            if (selected) {
                catalog[pathOrDisplayName].selectionOrder = selectionsMade++;
            }

            catalog[pathOrDisplayName].selected = selected;
        },
        selectCategory(name) {
            selectedCategory = name;
        },
        getCategory(name) {
            return categories[name];
        },
        getAttribute(pathOrDisplayName) {
            return catalog[pathOrDisplayName];
        },
        clearSelection() {
            for (const a of iterattributes(categories)) {
                a.selected = false;
            }
        },
        isAttributeSelected(pathOrDisplayName) {
            return catalog[pathOrDisplayName].selected;
        },
        has(pathOrDisplayName) {
            return pathOrDisplayName in catalog;
        },
        * categories() {
            yield* itercategories(categories);
        },
        * attributes() {
            yield* iterattributes(categories);
        },
        * unselectedAttributes() {
            for (const a of iterattributes(categories)) {
                if (!a.selected) {
                    yield a;
                }
            }
        },
        * selectedAttributes() {
            // I don't like making an array and then iterating over it
            // an extra time, but this is done to keep the interface
            // consistent.
            yield* Array
                .from(iterattributes(categories))
                .filter(({selected}) => {
                    return selected;
                })
                .sort((a, b) => {
                    return a.selectionOrder - b.selectionOrder;
                });
        },
        * groups() {
            // eslint-disable-next-line no-unused-vars
            for (const [_, c] of itercategories(categories)) {
                yield* c.groups();
            }
        },
        get selectedCategory() {
            return selectedCategory;
        },
    };
}
