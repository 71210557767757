import { bem, m } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';
import { purecomp } from '#/browser-framework/vcomps';
import { BinaryData, CapturedDocument, CriminalReport } from '#/evident-attributes/views';


const btx = bem`
AttributeValueDialog
    titleBar
    attributeType
    dialogButton
    body
`;

const BinaryDataView = purecomp(({ value }) => m(BinaryData, { bin: value }));
const CapDocView = purecomp(({ state }) => m(CapturedDocument, state));
const Fallback = purecomp(() => m('p', 'undefined'));

const componentFromAttrVal = (val) => ({
    BinaryData: BinaryDataView,
    CapturedDocument: CapDocView,
    CriminalReport,
})[val.$objectType] || Fallback;

const ExpandedAttribute = purecomp(({ attribute }) =>
    m('.ExpandedAttribute',
        m(componentFromAttrVal(attribute.value), attribute)));

export default {
    view({ attrs: { App } }) {
        const {
            label,
        } = App.dialogState;

        return (App.dialogState)
            ? m(btx.block,
                m(btx.titleBar,
                    m(`h3${btx.attributeType}`, label),
                    m(pointable(btx.dialogButton), {
                        onpointerup() {
                            App.hideDialog();
                        },
                    }, m(Named.Icon, { name: 'x' }))),
                m(btx.body,
                    m(ExpandedAttribute, {
                        attribute: App.dialogState,
                    })))
            : null;
    },
};
