import { bem, tx, logger, m, harness, router, spa, expose } from '#/browser-framework';
import { Named, Spinner, pointable } from '#/browser-framework/comps';
import { WithLeftNav } from '#/rp-facing/views/Layouts';

import { bemStatus } from '#/rp-facing/state/SubjectiveRequest';
import AttributeValue from './AttributeValue';
import DeleteIdoRequestsDialog from './DeleteIdoRequestsDialog';


// Helps limit spam to Sentry, but not by much: This will still fire
// once per leaked attribute per RP per time the user uses the app after
// a refresh.
const reported = {};

function reportLeakedAttribute(type, rpName) {
    if (!reported[`${rpName}${type}`]) {
        const err = new Error(`${type} is not visible to ${rpName}`);
        reported[`${rpName}${type}`] = true;
        logger.error(err);
    }
}

const go = (rpName, rprId, index) =>
    router.go(`/${rpName}/requests/view/${rprId}`, {
        state: {
            index,
        },
    });


const Toolbar = {
    view({
        attrs: {
            rpName,
            enableIdoReset,
            ordinal,
            recipientDisplayString,
            previousRequestId,
            nextRequestId,
            onDeleteAttempt,
        },
    }) {
        return m('.TopToolbar.RequestDetail__toolbar',
            (previousRequestId)
                ? m(pointable('.RequestDetail__toolbarNav.RequestDetail__toolbarLeft'), {
                    onpointerup: () =>
                        go(rpName, previousRequestId, ordinal - 1),
                }, 'Previous')
                : m('.RequestDetail__toolbarLeft'),
            m('.RequestDetail__toolbarMiddle',
                recipientDisplayString,
                m.trust('&nbsp;'),
                (enableIdoReset) && m('.RequestDetail__deleteIdo',
                    m(pointable(Named.Icon), harness('request-detail-page-delete-button', {
                        name: 'delete',
                        onpointerup: onDeleteAttempt,
                    })))),
            (nextRequestId)
                ? m(pointable('.RequestDetail__toolbarNav.RequestDetail__toolbarRight'), {
                    onpointerup: () => go(rpName, nextRequestId, ordinal + 1),
                }, 'Next')
                : m('.RequestDetail__toolbarRight'));
    },
};

export const RequestDetailSection = {
    view({
        attrs: {
            type,
            heading,
        },
        children,
    }) {
        return m(tx('.RequestDetailSection', type),
            m('h2.RequestDetailSection__heading', heading),
            children
                .filter((vnode) => vnode && vnode.children)
                .map((vnode) => {
                    return m('.RequestDetailSection__row', vnode);
                }));
    },
};

const OverviewItem = {
    view({
        attrs: {
            'data-harness': dh,
        },
        children,
    }) {
        return m('.OverviewItem',
            m('span.OverviewItem__label',
                children[0]),
            m('strong.OverviewItem__value', harness(dh, {}),
                children[1]));
    },
};

export const Overview = {
    view({
        attrs: {
            recipient,
            date,
            completedAt,
            summary,
            description,
        },
    }) {
        const format = {
            day: '2-digit',
            year: 'numeric',
            month: 'short',
            hour: 'numeric',
            minute: '2-digit',
        };

        let completedDate;
        if (completedAt) {
            completedDate = new Date(completedAt);
            completedDate = completedDate.toLocaleString('en-US', format);
        }

        return m(RequestDetailSection, {
            heading: 'Overview',
            type: 'Overview',
        },
        m(OverviewItem,
            harness('request-detail-page-email', {}),
            'Sent to',
            recipient),
        m(OverviewItem,
            harness('request-detail-page-date', {}),
            'Sent Date',
            date),
        completedDate
            ? m(OverviewItem,
                'Completed Date',
                completedDate)
            : null,
        m(OverviewItem,
            harness('request-detail-page-summary', {}),
            'Summary',
            summary),
        m(OverviewItem,
            harness('request-detail-page-description', {}),
            'Description',
            description));
    },
};


const RequestLink = {
    view({
        attrs: {
            url,
        },
    }) {
        return m(RequestDetailSection, {
            heading: 'Request Link',
            type: 'RequestLink',
        },
        m('.RequestLink__control',
            m('input.RequestLink__url', harness('request-page-link-to-request', {
                value: url,
                readonly: true,
            })),
            m(pointable('.RequestLink__copyButton'), {
                onpointerup: () => {
                    document.querySelector('.RequestLink__url').select();
                    document.execCommand('Copy');
                },
            }, 'Copy Request Link')));
    },
};

const Notes = {
    view({
        attrs: {
            notesText,
        },
    }) {
        return m(RequestDetailSection, {
            heading: 'Notes',
            type: 'Notes',
        }, notesText);
    },
};

const StatusDetailMessages = {
    'Additional Inputs Needed': 'Certain attributes were unable to be verified. The Identity Owner has been notified of the additional information required to continue the verificaiton.',
    'Pre-Adverse Action': 'The Identity Owner has been notified of Pre-Adverse Action, and may choose to dispute the findings.',
};

const RequestStatusBar = {
    view({
        attrs: {
            status,
            formattedStatus,
            statusDetail,
        },
    }) {
        const detailMsg = StatusDetailMessages[statusDetail];
        // Transform status to BEM modifier
        return m(tx('RequestStatusBar', `RequestStatusBar--${bemStatus(status)}`),
            harness('request-detail-page-status'),
            m('div', formattedStatus),
            detailMsg ? m('.RequestStatusBar__details', detailMsg) : null);
    },
};

const AttributeResult = {
    view({
        attrs: {
            app,
            label,
            attr,
        },
    }) {
        return m('.AttributeResult', harness('requested-attribute-result', expose({}, {'attribute-path': attr.path})),
            m('span.AttributeResult__label', label),
            m('span.AttributeResult__value',
                m(AttributeValue, {
                    app,
                    attr,
                })));
    },
};

const RequestedAttributes = {
    view({
        attrs: {
            attributes,
            rp,
            app,
        },
    }) {
        return m(RequestDetailSection, {
            heading: 'Requested Attributes',
            type: 'RequestedAttributes',
        },
        (attributes.length === 0)
            ? 'No attributes to report.'
            : attributes.map((attr) => {
                let definition = rp.attributeArchive.getAttribute(attr.path);

                // Data error: The request detail contains an attribute
                // that is said to not belong to the RP. Report this
                // upstream.
                if (!definition) {
                    reportLeakedAttribute(attr.path, rp.name);

                    definition = {
                        univocalName: attr.path,
                    };
                }

                return m(AttributeResult, {
                    label: definition.univocalName,
                    attr,
                    app,
                });
            }));
    },
};


const RequestDetail = {
    view({
        attrs: {
            viewModel: app,
            vars: {
                detail,
            },
        },
    }) {
        const rp = app.user.selectedRp;
        if (!rp || !detail) {
            return m(Spinner);
        }

        const {
            recipient: {
                fullname = '',
                email,
                idOwnerId,
            } = {},
            notes,
            date,
            completedAt,
            attributes = [],
            webLink = '',
            description = '',
            summary = '',
            status = '',
            formattedStatus,
            statusDetail,
        } = detail;

        const { index } = router.procure().state || {};
        const ordinal = parseInt(index, 10);

        let prev = null;
        let next = null;

        if (ordinal >= 0) {
            prev = rp.queriedRequests.requests[ordinal - 1];
            next = rp.queriedRequests.requests[ordinal + 1];
        }

        const {
            block,
            sections,
            port,
            starboard,
        } = bem`RequestDetail sections port starboard`;

        const recipientDisplayString = fullname || (email || idOwnerId);

        return [
            m(Toolbar, {
                ordinal,
                enableIdoReset: app.user.mayResetIdo(),
                recipientDisplayString,
                previousRequestId: (prev) ? prev.id : null,
                nextRequestId: (next) ? next.id : null,
                rpName: rp ? rp.name : null,
                onDeleteAttempt() {
                    app.idOwnerIdToDelete = idOwnerId;

                    app.showDialog(DeleteIdoRequestsDialog, {
                        idOwnerId,
                        confirmText: '',
                    });
                },
            }),
            m(block,
                m(RequestStatusBar, {
                    status,
                    formattedStatus,
                    statusDetail,
                }),
                m(sections,
                    m(port,
                        m(RequestedAttributes, {
                            app,
                            attributes,
                            rp: app.user.selectedRp,
                        }),
                        m(Notes, {
                            notesText: (notes || '').trim() || 'There are no notes on this request.',
                        })),
                    m(starboard,
                        m(Overview, {
                            recipient: recipientDisplayString,
                            date: date.format('MMM DD YYYY, h:mm A'),
                            summary,
                            description,
                            completedAt,
                        }),
                        (webLink) && m(RequestLink, {
                            url: webLink,
                        }),
                    ))),
        ];
    },
};

export default ([rpname, id]) => {
    const vars = {};

    spa.setView(RequestDetail, {
        layout: WithLeftNav,
        vars,
    });

    spa.viewModel.user.dominion
        .selectRp(rpname)
        .then((rp) => {
            return rp.getRequestDetail(id);
        })
        .then((detail) => {
            vars.detail = detail;
            spa.redraw();
        });
};
