import { userInput } from '#/browser-framework';


const { field, inputlock } = userInput;


export default async function headingMessageSettings(rpname, submit) {
    const lock = await inputlock({
        id: `${rpname}-hm-settings`,
        async fire() {
            await submit(lock.dehydrate());
        },
        fields: {
            headingText: field({
                id: 'hm-settings-heading-text',
            }),
            messageText: field({
                id: 'hm-settings-message-text',
            }),
        },
    });

    lock.dehydrate = () => {
        const dump = lock.dump();

        dump.headingText = dump.headingText.trim();
        dump.messageText = dump.messageText.trim();

        return dump;
    };

    lock.hydrate = (v = {}) => lock.load(v);

    return lock;
}
