import { m, tx, spa, strings } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';

import { WithLeftNav, AdminLayout } from '#/rp-facing/views/Layouts';
import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';


const { capitalizeWord, slugify } = strings;

const Draggable = {
    view({
        attrs: {
            selected,

        },
        children,
    }) {
        return m(tx('Draggable', { selected }),
            children);
    },
};

function mapSwimmers({text, selected}) {
    return m(Draggable, {selected}, text);
}

const ViolationTolerance = {
    view({attrs: {infraction, threshold, period, changeThreshold, selectPeriod}}) {
        return m('.ViolationTolerance',
            m('label.FlushedLabelText',
                `Maximum Number of ${capitalizeWord(infraction)} Infractions allowed by policy`),
            m('.ViolationTolerance__form',
                m('ViolationTolerance__number[contenteditable]', {
                    onkeypress: (e) => {
                        if (e.target.innerHTML.length === 2) {
                            e.preventDefault();
                        }
                    },
                    oninput: (e) => {
                        changeThreshold(e.target.innerHTML);
                    },
                }, m.trust(`${threshold}`)),
                m('ViolationTolerance__years', 'violations within'),
                m(Dropdown, {
                    id: slugify(infraction),
                    value: period,
                    select: selectPeriod,
                    options: [
                        '1 year',
                        '2 years',
                        '3 years',
                        '4 years',
                        '5 years',
                    ],
                })));
    },
};

const Swimlane = {
    view({attrs: {heading}, children}) {
        return m('.Swimlane',
            m('.Swimlane__heading', heading),
            m('.Swimlane__swimmers', children));
    },
};


const Page = {
    view({attrs: App}) {
        if (!App.user.dominion || App.user.selectedRp) {
            return m(AdminLayout, {App, subtitle: 'Motor Vehicles Records Policy'});
        }

        const {mvrPolicy} = App.user.selectedRp.settings;

        return m(AdminLayout, {App, subtitle: 'Motor Vehicles Records Policy'},
            m('.MvrSettings',
                m('.MvrSettings__top',
                    m('aside.MvrSettings__templates',
                        m('label.FlushedLabelText',
                            m('span', 'Template:')),
                        m(Dropdown, {
                            id: 'mvr-policy-template',
                            value: mvrPolicy.selectedTemplate,
                            select: App.user.selectedRp.loadMvrPolicyTemplate,
                            options: mvrPolicy.templates,
                        })),
                    m('aside.MvrSettings__tolerance',
                        m(ViolationTolerance, {
                            infraction: 'major',
                            threshold: mvrPolicy.tolerances.major.threshold,
                            period: mvrPolicy.tolerances.major.period,
                            changeThreshold: (v) => {
                                mvrPolicy.tolerances.major.threshold = v;
                            },
                            selectPeriod: (index) => {
                                mvrPolicy.tolerances.major.period = index;
                            },
                        })),
                    m('aside.MvrSettings__tolerance',
                        m(ViolationTolerance, {
                            infraction: 'minor',
                            threshold: mvrPolicy.tolerances.minor.threshold,
                            period: mvrPolicy.tolerances.minor.period,
                            changeThreshold: (v) => {
                                mvrPolicy.tolerances.minor.threshold = v;
                            },
                            selectPeriod: (index) => {
                                mvrPolicy.tolerances.minor.period = index;
                            },
                        })))
            ),
            m('label.FlushedLabelText',
                m('span', `Drag crimes to categorize them as major or minor infractions.
                    You can select multiple items to drag by clicking one item and, while
                    holding down Shift, clicking another item in the same column.`)),
            m('.MvrSettings__swimlanes',
                m(Swimlane, {
                    heading: 'Major Infractions',
                }, mvrPolicy.breakdown.major.map(mapSwimmers)),
                m(Swimlane, {
                    heading: 'Minor Infractions',
                }, mvrPolicy.breakdown.minor.map(mapSwimmers)),
                m(Swimlane, {
                    heading: 'Not Considered',
                }, mvrPolicy.breakdown.ignored.map(mapSwimmers))));
    },
};

export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    pendingLayout: WithLeftNav,
    settingName: 'mvrPolicy',
});
