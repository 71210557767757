import { events, logger, router, spa } from '#/browser-framework';
import { Snackbar } from '#/browser-framework/comps';
import { presenceMonitor } from '#/browser-framework/presence';

import { LandingRoute } from '#/rp-facing/core/landing';
import { startRouting } from '#/rp-facing/core/routing';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';
import ExpiryDialog, { SEC_BEFORE_EXPIRY } from '#/rp-facing/views/ExpiryDialog';
import { ErrorView } from '#/rp-facing/views/pages/ErrorPages/ErrorPage';

import user from './user';


// Control the application from this object.
export default function rpfacing() {
    const iface = {};

    iface.userInitialTargetRoute = router.referrer;
    iface.docsEnabled = deploy.WEB_PUBLIC_RPWEB_DOCS_ENABLED === 'true';

    iface.presenceMon = presenceMonitor(spa.$window, {
        msUntilIdle: ((60 * 60) - SEC_BEFORE_EXPIRY) * 1000,
        onidle: () => iface.showDialog(ExpiryDialog),
        storageImpl: spa.$window.localStorage, // share timing info across tabs.
    });

    iface.showError = (error) => {
        spa.error = error || spa.error;
        logger.error(spa.error);

        spa.setView(ErrorView, {
            layout: RestrictedLayout,
        });
    };

    // Use to control binary visual indicators
    // during execution of a non-blocking function.
    iface.busySection = (fn) =>
        Promise
            .resolve()
            .then(() => {
                iface.busy = true;
                spa.redraw();
            })
            .then(fn)
            .catch((e) => {
                spa.error = e;
                iface.busy = false;
                spa.redraw();

                throw e;
            })
            .then(() => {
                iface.busy = false;
                spa.redraw();
            });

    iface.goToLandingPage = () => new LandingRoute(iface.user, iface.userInitialTargetRoute)
        .getRoute()
        .then((route) => {
            // Break any redirect loop.
            iface.userInitialTargetRoute = null;
            router.go(route);
        })
        .catch((e) => iface.showError(e));

    iface.attachSession = ({ user: firebaseProfile, jwt, tokenResult }) => {
        const newUser = user(firebaseProfile, jwt, tokenResult);

        if (firebaseProfile) {
            iface.presenceMon.startMonitoring();
        } else {
            iface.presenceMon.stopMonitoring();
        }

        return newUser
            .loadFormModels()
            .then(() => {
                if (iface.user) {
                    iface.user.clear();
                }

                iface.user = newUser;

                return (iface.user.firebaseProfile && iface.user.firebaseProfile.emailVerified)
                    ? iface.user.onboarding.deriveOnboardingState()
                    : null;
            })
            .then(() => {
                if (iface.routing) {
                    return iface.goToLandingPage();
                } else {
                    startRouting(iface);
                    iface.routing = true;
                }
            });
    };

    iface.snackbar = Snackbar.makeViewModel();

    iface.showDialog = spa.redrawAfter((comp, dialogState = {}) =>
        ([iface.dialog, iface.dialogState] = [comp, dialogState]));

    iface.hideDialog = spa.redrawAfter(() => {
        iface.dialog = null;
    });

    events.on('user-copied-text', (text = 'selection') => {
        iface.snackbar.display(`Copied ${text} to clipboard.`, true);
    });

    events.on('toggle-apikey-visibility', () => {
        iface.user.selectedRp.settings.apiConfiguration.showKeyChars =
        !iface.user.selectedRp.settings.apiConfiguration.showKeyChars;
    });

    events.on('open-new-webhook-form', () => {
        iface.user.selectedRp.settings.saveHistory();
        iface.user.selectedRp.settings.apiConfiguration.showNewWebhookForm = true;
    });

    events.on('close-new-webhook-form', () => {
        iface.user.selectedRp.settings.apiConfiguration.showNewWebhookForm = false;
        iface.user.selectedRp.settings.apiConfiguration.newWebhook.name = '';
        iface.user.selectedRp.settings.apiConfiguration.newWebhook.url = '';
    });

    events.on('open-webhook-editor', (i) => {
        iface.user.selectedRp.settings.saveHistory();
        iface.user.selectedRp.settings.apiConfiguration.editMode[i] = true;
        iface.user.selectedRp.settings.apiConfiguration.editHooks.push({
            webhookIndex: i,
            url: iface.user.selectedRp.settings.webhooks.fields.postHooks.value[i].url,
            name: iface.user.selectedRp.settings.webhooks.fields.postHooks.value[i].name,
        });
    });

    events.on('delete-webhook', (i) => {
        iface.user.selectedRp.settings.saveHistory();
        iface.user.selectedRp.settings.webhooks.fields.postHooks.value.splice(i, 1);
    });

    events.on('accept-webhook-changes', (i) => {
        iface.user.selectedRp.settings.webhooks.fields.postHooks.value[i].name =
        iface.user.selectedRp.settings.apiConfiguration.editHooks.find((obj) => (i === obj.webhookIndex)).name;
        iface.user.selectedRp.settings.webhooks.fields.postHooks.value[i].url =
        iface.user.selectedRp.settings.apiConfiguration.editHooks.find((obj) => (i === obj.webhookIndex)).url;
        iface.user.selectedRp.settings.apiConfiguration.editMode[i] = false;
        iface.user.selectedRp.settings.apiConfiguration.editHooks =
        iface.user.selectedRp.settings.apiConfiguration.editHooks.filter((obj) => (i !== obj.webhookIndex));
    });

    events.on('create-new-webhook', () => {
        iface.user.selectedRp.settings.apiConfiguration.showNewWebhookForm = false;
        iface.user.selectedRp.settings.webhooks.fields.postHooks.value
            .push({
                name: iface.user.selectedRp.settings.apiConfiguration.newWebhook.name,
                url: iface.user.selectedRp.settings.apiConfiguration.newWebhook.url});
        iface.user.selectedRp.settings.apiConfiguration.editMode.push(false);
        iface.user.selectedRp.settings.apiConfiguration.newWebhook.name = '';
        iface.user.selectedRp.settings.apiConfiguration.newWebhook.url = '';
    });

    events.on('cancel-webhook-changes', (i) => {
        iface.user.selectedRp.settings.apiConfiguration.editMode[i] = false;
        iface.user.selectedRp.settings.apiConfiguration.editHooks
        = iface.user.selectedRp.settings.apiConfiguration.editHooks.filter((obj) => (i !== obj.webhookIndex));
    });

    events.on('set-new-webhook-url', (url) => {
        iface.user.selectedRp.settings.apiConfiguration.newWebhook.url = url;
    });

    events.on('edit-webhook-url', (i, url) => {
        iface.user.selectedRp.settings.apiConfiguration.editHooks.find((obj) => (i === obj.webhookIndex)).url = url;
    });

    events.on('edit-webhook-event', (i, e) => {
        iface.user.selectedRp.settings.apiConfiguration.editHooks
            .find((obj) => (i === obj.webhookIndex)).name = iface.user.selectedRp.settings.apiConfiguration.webhookEvents[e].name;
    });

    events.on('set-new-webhook-event', (e) => {
        iface.user.selectedRp.settings.apiConfiguration.newWebhook.name =
        (iface.user.selectedRp.settings.apiConfiguration.webhookEvents[e])
            ? iface.user.selectedRp.settings.apiConfiguration.webhookEvents[e].name
            : null;
    });

    events.on('firebase-auth-new-state', iface.attachSession);

    return iface;
}
