import moment from 'moment';
import b64 from 'urlsafe-base64';

import { m, blobs, debounce, logger, router } from '#/browser-framework';

import SubjectiveRequest from './SubjectiveRequest';


/*
 * QueriedRequests object -> Query string
 */
function createQueryString(data) {
    const query = {
        dateSortAsc: data.ascending,
        limit: data.limit,
    };

    if (data.startDate) {
        query.startDate = data.startDate.valueOf();
    }

    if (data.endDate) {
        query.endDate = data.endDate.valueOf();
    }

    if (data.search) {
        // Strip slashes to avoid regex use.
        query.textSearchPattern = data.search.replace(/\//g, '').trim();
    }

    const fkeys = Object.keys(data.filters);

    for (const k of fkeys) {
        if (data.filters[k].size > 0) {
            query[k] = Array
                .from(data.filters[k].entries())
                .filter(([, v]) => v)
                .map(([filterValue]) => filterValue);

            // Optimize by acknowledging that specifying all possible
            // filter values is the same as having no filter at all.
            if (query[k].length === data.filters[k].size) {
                delete query[k];
            }
        }
    }

    if (data.seqId > 0) {
        query.seqId = data.seqId;
    }

    logger.debug(query);

    return m.buildQueryString({
        rpRequestParams: b64.encode(btoa(JSON.stringify(query))),
        bust: (new Date()).getTime(),
    });
}


/*
 * Transform a completion time data record to view data for the
 * completion times chart.
 */
function postProcessCompletionTimeRecord({
    begin,
    end,
    count,
    ordinal,
    colors,
    maxWidth,
}) {
    let label;
    const isnum = (n) => typeof n === 'number';

    if (isnum(begin) && isnum(end)) {
        label = `${begin} &ndash; ${end} hours`;
    } else if (begin && !isnum(end)) {
        label = `Over ${begin} hours`;
    } else {
        label = `Over ${begin} hours`;
    }

    return {
        bgcolor: colors[ordinal],
        label,
        width: (maxWidth === 0)
            ? 0
            : Math.floor((count / maxWidth) * 80),
    };
}


/*
 * Return data used to render a CompletionTimes chart.
 */
function postProcessCompletionTimes(completionTimes) {
    // Bars in the chart are sized relative to the longest bar.
    const maxWidth = Math.max(...completionTimes.map(
        ([, count]) => count));

    // Every time category has a positionally assigned color.
    const colors = [
        '#30B394',
        '#8DAB61',
        '#D49E3C',
        '#E97C39',
        '#E95B45',
    ];

    return completionTimes.map(([[begin, end], count], i) => {
        return postProcessCompletionTimeRecord({
            begin,
            end,
            count,
            ordinal: i,
            colors,
            maxWidth,
        });
    });
}

/**
 * @param {object} sentVsComplete - contains summary data on the last 3 days of requests
 * @param {number} sentVsComplete.sent - the total number of sent requests
 * @param {number} sentVsComplete.shared - of the sent requests the number that have been shared
 * @param {Array<object>} sentVsComplete.dayMetrics - an array of date and request metrics
 *
*/
export const postProcessDailySamples = function(sentVsComplete) {
    const permutation = new Map();
    let maxY = -1;

    const {dayMetrics} = sentVsComplete;
    for (let i = 0; i < dayMetrics.length; ++i) {
        const [dateRecord, dayStats] = dayMetrics[i];
        const {month, year, day} = dateRecord;
        const datestring = `${month}/${day}/${year}`;

        const nsent = dayStats.sent || 0;
        const nshared = dayStats.shared || 0;
        const nexpired = dayStats.expired || 0;

        permutation.set(datestring, {
            sent: nsent,
            shared: nshared,
            expired: nexpired,
        });

        if (nsent > maxY) {
            maxY = nsent;
        }
    }

    return {
        sent: sentVsComplete.sent,
        shared: sentVsComplete.shared,
        dayMetrics: Array.from(permutation.entries()),
        maxY,
    };
};


/*
 * Return an integer from 0 to 100 reflecting the percent of requests
 * in shared status.
 */
function computeShareRate({sent, shared}) {
    return Math.floor(((sent > 0) ? shared / sent : 0) * 100);
}


/*
 * Return transformed metrics condusive to display in charts.
 */
function postProcessMetrics({completionTimes, sentVsComplete}) {
    return {
        completionTimes: postProcessCompletionTimes(completionTimes),
        shareRate: computeShareRate(sentVsComplete),
        sentVsComplete: postProcessDailySamples(sentVsComplete),
    };
}


/*
 * Download requests as CSV.
 */
function downloadRequestsAsCsv(queriedRequests, requestCsvFromRp) {
    const shallow = Object.assign({}, queriedRequests);

    // Adapt query to re-request every request we've loaded so far.
    delete shallow.seqId;
    shallow.limit = shallow.requests.length;

    const query = createQueryString(shallow);

    requestCsvFromRp({
        url: `/requests?${query}`,
    }).then((xo) => {
        const today = new Date();
        const Y = today.getFullYear();
        const M = today.getMonth() + 1;
        const D = today.getDate();

        blobs.downloadFileUsingBinString(xo.responseText, `${Y}-${M}-${D}.csv`);
    });
}


/*
 * A collection of RPRs populated from a service according to search,
 * filter and sort criteria. Through an instance you may search,
 * filter and sort requests for an RP.
 */
export default function QueriedRequests(requestJsonFromRp, requestCsvFromRp) {
    const self = {
        limit: 50,
        search: '',
        waitingForMore: false,
        startDate: null,
        endDate: null,
        ascending: false,
        openedFilterMenu: null,
        filters: {},
        filtersDirty: true,
        requests: [],
        seqId: null,
        analytics: null,

        get noMoreToLoad() {
            return self.seqId < 0;
        },

        get waitingForInitial() {
            return self.waitingForMore && self.requests.length === 0;
        },

        hasRequests() {
            return self.requests.length > 0;
        },

        async loadAnalytics() {
            const analyticsQueryObj = {};

            analyticsQueryObj.startDate = (self.startDate)
                ? self.startDate.valueOf()
                : moment().subtract(3, 'days').startOf('day').valueOf();

            analyticsQueryObj.endDate = (self.endDate)
                ? self.endDate.valueOf()
                : moment().endOf('day').valueOf();

            const analyticsQueryString =
                m.buildQueryString(analyticsQueryObj);

            const metrics = await requestJsonFromRp({url: `/requestAnalytics?${analyticsQueryString}`});

            self.analytics = postProcessMetrics(metrics);

            m.redraw();
        },

        async loadMore() {
            if (self.waitingForMore) {
                return self.waitingForMore;
            }

            const qs = createQueryString(self);
            m.redraw();

            try {
                self.waitingForMore = requestJsonFromRp({url: `/requests?${qs}`});
                const {requests} = await self.waitingForMore;

                const subjectives = requests.map((rpr) => {
                    return SubjectiveRequest(rpr, {
                        organizedAttributes: true,
                    });
                });

                if (subjectives.length > 0) {
                    self.requests.push(...subjectives);

                    // Store latest sequence id so we can load more later.
                    self.seqId =
                        subjectives[subjectives.length - 1].seqId;
                }

                if (subjectives.length < self.limit) {
                    self.seqId = -1;
                }

                self.filtersDirty = false;

                // Compute analytics without blocking
                self.loadAnalytics();

                m.redraw();

                // Mithril 1.1.5 does not give us an easy way
                // to know when it has finished redrawing,
                // but we need to be sure more requests are visible
                // before acting like we're ready to load more requests.
                // Blindly guess when rendering finishes.
                setTimeout(() => {
                    self.waitingForMore = false;
                    m.redraw();
                }, 500);
            } catch (e) {
                self.waitingForMore = false;

                router.go('/error');

                throw e;
            }
        },

        clear({hard = false} = {}) {
            self.seqId = self.analytics = null;

            if (hard) {
                self.startDate = self.endDate = null;
            }

            self.requests = [];
        },

        hardRefresh() {
            self.clear();

            return self.loadMore();
        },

        debouncedHardRefresh: debounce(() => {
            self.hardRefresh();
        }, 800),

        loadFilterMenu: (id, options) => {
            self.filters[id] = options.reduce((p, c) => {
                p.set(c, true);

                return p;
            }, new Map());
        },

        exportToCsv() {
            return downloadRequestsAsCsv(self, requestCsvFromRp);
        },

        getFilterState: (id, option) => {
            return self.filters[id].get(option);
        },

        setFilterState(id, option, checked) {
            self.filters[id].set(option, checked);

            self.filtersDirty = true;
        },

        areAllFiltersOn(id) {
            return Array
                .from(self.filters[id].entries())
                .every(([, checked]) => checked);
        },

        toggleAllFilters(id) {
            const toApply = !self.areAllFiltersOn(id);

            for (const option of self.filters[id].keys()) {
                self.filters[id].set(option, toApply);
            }

            self.filtersDirty = true;
        },

        closeAllFilterMenus() {
            self.openedFilterMenu = '';
            m.redraw();

            if (self.filtersDirty) {
                self.hardRefresh();
            }
        },

        openFilterMenu(id) {
            self.openedFilterMenu = id;
        },

        switchSortDirection() {
            self.ascending = !self.ascending;
            self.hardRefresh();
        },

        resetDateRange() {
            self.startDate = null;
            self.endDate = null;
            self.hardRefresh();
        },

        setDateRange(...newRange) {
            const [start, end] = newRange
                .map((date) => moment(date).startOf('day'));

            const rangeChanged = !start.isSame(self.startDate)
                || !end.isSame(self.endDate);

            if (rangeChanged) {
                self.startDate = start;
                self.endDate = end;
                self.hardRefresh();
            }
        },

        setSearchString(searchText) {
            self.search = searchText;
            self.debouncedHardRefresh();
        },
    };

    return self;
}
