import { events, logger, spa, support } from '#/browser-framework';
import { bindFirebase } from '#/browser-framework/firebase';
import { installGoogleTagManager } from '#/browser-framework/marketing';
import json from '#/universal-framework/json';

import rpfacing from '#/rp-facing/state';
import { PendingPage } from '#/rp-facing/views/Layouts';

import '#/rp-facing/styles/screen.less';

const installMarketingTelemetry = () =>
    ((deploy.WEB_PUBLIC_RPWEB_GTM_CONTAINER_ID)
        ? installGoogleTagManager(spa.$window, deploy.WEB_PUBLIC_RPWEB_GTM_CONTAINER_ID)
        : Promise.resolve(() => null))
        .catch((e) => logger.error(e)); // Telemetry error should not block site use.

spa.start(() => {
    spa.viewModel = rpfacing();
    spa.setView(PendingPage);

    return installMarketingTelemetry()
        .then(() =>
            ((support.cookies)
                ? bindFirebase(spa.$window, events, json.object(deploy.WEB_PUBLIC_RPWEB_FIREBASE_CONFIG))
                : Promise.reject(new Error('Cookie support needed'))))
        .catch(spa.viewModel.showError);
});
