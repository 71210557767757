import { bem } from '#/browser-framework';

export const formBem = bem`
    StandardForm
        fieldset
        field
`;

export const fieldBem = bem`
    ${formBem.field}
        title
        label
        input
        color-input
        file
`;

export const fileBem = bem`
    ${fieldBem.file}
        preview
        image
        name
        delete
`;
