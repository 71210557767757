import { bem, m, harness, router, spa } from '#/browser-framework';
import { Button, PhoneNumberInput } from '#/browser-framework/comps';
import { computePricingLink } from '#/rp-facing/core/salesInteractor';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';


const PhoneNumberPrompt = {
    btx: bem`
        PhoneNumberPrompt
            form
            heading
            subHeading
            request
            label
            field`,
    view({
        attrs: {
            viewModel: {
                user,
                showError,
            },
        },
    }) {
        const { onboarding } = user;
        const { btx } = PhoneNumberPrompt;

        if (!onboarding) {
            return null;
        }

        return m(btx.block,
            m(`h2${btx.heading}`,
                'Create your Evident account'),
            m(btx.subHeading,
                'Start running verifications in minutes with your Evident account. ',
                'Need help? ', m('a', {
                    href: computePricingLink({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phone: onboarding.onboardPhoneNumberFormModel.value,
                        email: user.email,
                    }),
                }, 'Talk to our sales team.')),
            m(btx.form,
                m(`h2${btx.request}`,
                    'Please provide a phone number where you can be contacted.'),
                m(btx.field,
                    m(btx.label, 'Your phone number'),
                    m(PhoneNumberInput, harness('onboarding-phone-number', {
                        id: 'phone',
                        value: onboarding.onboardPhoneNumberFormModel.value,
                        onchange: onboarding.onboardPhoneNumberFormModel.change,
                    }))),
                m(Button, {
                    flags: {
                        round: true,
                        primary: true,
                    },
                    disabled: !onboarding.onboardPhoneNumberFormModel.valid,
                    onpress() {
                        onboarding.onboardPhoneNumberFormModel.submit().then(() => {
                            router.go('/setup/overview');
                        }).catch(() => {
                            showError();
                        });
                    },
                }, 'Continue')));
    },
};


export default () => {
    spa.setView(PhoneNumberPrompt, {
        layout: RestrictedLayout,
    });
};
