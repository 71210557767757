import { colors, userInput } from '#/browser-framework';

const { colorModel, inputStringToColor, toHex } = colors;
const { inputlock, field } = userInput;

export default async function brandingSettings(rpname, submit) {
    async function validatefile(v) {
        const gotSomething = Boolean(v.file || v.defaultUrl);

        if (!gotSomething) {
            throw new Error('File needed');
        } else if (v.file && v.file.size > 1024 * 1000) {
            throw new Error('Please keep the file to less than 1 MB');
        } else if (!v.file && v.defaultUrl) {
            return;
        }

        await new Promise((resolve) => {
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                v.dataUrl = reader.result;

                resolve();
            });

            reader.readAsDataURL(v.file);
        });
    }

    const lock = await inputlock({
        id: `${rpname}-branding-settings`,
        fields: {
            dbaName: field({
                id: 'branding-settings-dba-name',
                validate(v) {
                    if (!v.length) {
                        throw new Error('This field is required.');
                    }
                },
            }),
            wordmark: field({
                id: 'branding-settings-wordmark',
                validate: validatefile,
            }),
            primaryColor: field({
                id: 'branding-settings-primary-color',
                initialValue: colorModel(),
                validate(v) {
                    if (!inputStringToColor(v.input).model) {
                        throw new Error(`${v.input} is not a color`);
                    }
                },
            }),
        },
        async fire() {
            await submit(lock.dehydrate());
        },
    });

    lock.dehydrate = () => {
        const dump = lock.dump();

        function pickurl(dehydrated, key, { defaultUrl, dataUrl }) {
            if (dataUrl) {
                dehydrated[`${key}DataUrl`] = dataUrl;
            } else if (defaultUrl) {
                dehydrated[`${key}Url`] = defaultUrl;
            }
        }

        const out = {
            primaryColor: toHex(dump.primaryColor).input,
            dbaName: dump.dbaName,
        };

        pickurl(out, 'wordmark', dump.wordmark);

        return out;
    };

    lock.hydrate = (s = {}) => {
        const wordmarkLabel = (s.wordmarkUrl)
            ? 'Click here to update your wordmark'
            : 'Click here to upload a new wordmark';

        return lock.load({
            dbaName: s.dbaName,
            primaryColor: inputStringToColor(s.primaryColor || '#000'),
            wordmark: {
                initialLabel: wordmarkLabel,
                label: wordmarkLabel,
                defaultUrl: s.wordmarkUrl,
                dataUrl: null,
            },
        });
    };

    return lock;
}
