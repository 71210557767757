import { bem, m, harness, router, spa, tx } from '#/browser-framework';
import { Button, Dropdown, PhoneNumberInput } from '#/browser-framework/comps';
import US_SUBDIVISIONS from '#/ido-lib/states.json';
import { woopraTrackVerifyBusiness, woopraTrackCompleteStep } from '#/browser-framework/woopraLib';

const STATES_AND_TERRITORIES = US_SUBDIVISIONS
    .sort(([a], [b]) => a.localeCompare(b));
import { WithLeftNav } from '#/rp-facing/views/Layouts';

const formbtx = bem`
    BizVerificationForm
        heading
        label
        fieldset
        field
`;

const RequiredMark = {
    view() {
        return m('span.fg-danger', ' *');
    },
};

const BasicInformationForm = {
    btx: bem`
        BasicInformationForm
    `,
    view({
        attrs: {
            formModel,
        },
    }) {
        const { block } = BasicInformationForm.btx;

        return m(tx(formbtx.block, block),
            m(formbtx.heading),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'Registered legal business name', m(RequiredMark)),
                    m('input', harness('biz-legal-name', {
                        id: 'biz-legal-name',
                        value: formModel.legalName,
                        oninput(e) {
                            formModel.legalName = e.target.value;
                        },
                    })))),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'DBA name', m(RequiredMark)),
                    m('input', harness('biz-dba-name', {
                        id: 'biz-dba-name',
                        value: formModel.dbaName,
                        oninput(e) {
                            formModel.dbaName = e.target.value;
                        },
                    })))),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'DUNS number'),
                    m('input', harness('biz-duns-number', {
                        id: 'biz-duns-number',
                        value: formModel.dunsNumber,
                        oninput(e) {
                            formModel.dunsNumber = e.target.value;
                        },
                    }))),
                m(formbtx.field,
                    m(formbtx.label, 'Tax ID', m(RequiredMark)),
                    m('input', harness('biz-tax-id', {
                        id: 'biz-tax-id',
                        value: formModel.taxId,
                        oninput(e) {
                            formModel.taxId = e.target.value;
                        },
                    })))),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'Registered business phone number', m(RequiredMark)),
                    m(PhoneNumberInput, harness('biz-phone-number', {
                        id: 'biz-phone-number',
                        value: formModel.phoneNumber,
                        onchange({ value, qualifiedValue, valid }) {
                            formModel.phoneNumber = value;
                            formModel.qualifiedValue = qualifiedValue;
                            formModel.phoneNumberValid = valid;
                        },
                    })))));
    },
};


const RegisteredAddress = {
    btx: bem`
        RegisteredAddress
    `,
    view({
        attrs: {
            formModel,
        },
    }) {
        const { block } = RegisteredAddress.btx;


        return m(tx(formbtx.block, block),
            m(formbtx.heading),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'Street Address'),
                    m('input', harness('biz-street-address', {
                        id: 'biz-street-address',
                        value: formModel.streetAddress,
                        oninput(e) {
                            formModel.streetAddress = e.target.value;
                        },
                    })))),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'City'),
                    m('input', harness('biz-city', {
                        id: 'biz-city',
                        value: formModel.city,
                        oninput(e) {
                            formModel.city = e.target.value;
                        },
                    })))),
            m(formbtx.fieldset,
                m(formbtx.field,
                    m(formbtx.label, 'State'),
                    m(Dropdown, harness('biz-state', {
                        options: STATES_AND_TERRITORIES.map(([name]) => name),
                        placeholder: 'Select a state or territory',
                        value: formModel.stateIndex,
                        select(index, actual) {
                            formModel.stateIndex = index;
                            formModel.state = STATES_AND_TERRITORIES[actual];
                        },
                    }))),
                m(formbtx.field,
                    m(formbtx.label, 'Zip'),
                    m('input', harness('biz-zip', {
                        id: 'biz-zip',
                        value: formModel.postcode,
                        oninput(e) {
                            formModel.postcode = e.target.value;
                        },
                    })))));
    },
};


const BusinessVerification = {
    btx: bem`
        BusinessVerification
            heading
            subHeading
            formHeading
            form
            left
            right
            controls`,
    view({
        attrs: {
            viewModel: {
                user: {
                    onboarding: {
                        businessFormModel,
                    },
                },
            },
            viewModel: {
                user: {
                    onboarding,
                },
            },
        },
    }) {
        const { btx } = BusinessVerification;

        return m(btx.block,
            m(`h2${btx.heading}`,
                'Verify your business'),
            m(btx.subHeading,
                'Please confirm some basic details to help us verify your business.'),
            m(btx.form,
                m(btx.left,
                    m(`h2${btx.formHeading}`,
                        'Basic information'),
                    m(BasicInformationForm, {
                        formModel: businessFormModel,
                    })),
                m(btx.right,
                    m(`h2${btx.formHeading}`,
                        'Registered address', m(RequiredMark)),
                    m(RegisteredAddress, {
                        formModel: businessFormModel,
                    }))),
            m('.OnboardingStepControls',
                m(Button, {
                    flags: {
                        secondary: true,
                    },
                    onpress: () => {
                        woopraTrackVerifyBusiness({
                            bizName: businessFormModel.legalName,
                            dbaName: businessFormModel.dbaName,
                            dunsNumber: businessFormModel.dunsNumber,
                            taxId: businessFormModel.taxId,
                            streetAddress: businessFormModel.streetAddress,
                            city: businessFormModel.city,
                            state: (businessFormModel.state
                                ? businessFormModel.state[0]
                                : ''),
                            zip: businessFormModel.postcode,
                            doLater: true});
                        return router.go('/setup/overview');
                    },
                }, 'Do this later'),
                m(Button, {
                    disabled: !businessFormModel.maySubmit(),
                    flags: {
                        primary: true,
                        round: true,
                    },
                    onpress: () => {
                        woopraTrackVerifyBusiness({
                            bizName: businessFormModel.legalName,
                            dbaName: businessFormModel.dbaName,
                            dunsNumber: businessFormModel.dunsNumber,
                            taxId: businessFormModel.taxId,
                            streetAddress: businessFormModel.streetAddress,
                            city: businessFormModel.city,
                            state: (businessFormModel.state
                                ? businessFormModel.state[0]
                                : ''),
                            zip: businessFormModel.postcode,
                            doLater: false});
                        woopraTrackCompleteStep(Boolean(onboarding.isUserVerified()),
                            Boolean(onboarding.hasBillingInfo()),
                            true,
                            Boolean(onboarding.areTermsSigned()));

                        return businessFormModel
                            .submit()
                            .then(() =>
                                router.go('/setup/overview'))
                            .catch((e) =>
                                spa.viewModel.showError(e));
                    },
                }, 'Continue')));
    },
};

export default () => {
    spa.setView(BusinessVerification, {
        layout: WithLeftNav,
    });
};
