import browserLogger from './browserLogger';


const _localStorageFallback = ($window, channelName) => {
    const iface = {
        name: channelName,
        postMessage: (message) => {
            $window.localStorage.setItem(channelName, JSON.stringify(message));
            $window.localStorage.removeItem(channelName);
        },
        onmessage: null,
        onmessageerror: null,
    };

    const listener = (ev) => {
        if (ev.key === channelName) {
            try {
                const message = JSON.parse(ev.newValue);

                if (message !== undefined && typeof iface.onmessage === 'function') {
                    iface.onmessage(message);
                }
            } catch (e) {
                if (typeof iface.onmessageerror === 'function') {
                    iface.onmessageerror(e);
                } else {
                    browserLogger.error(e);
                }
            }
        }
    };

    iface.close = () => $window.removeEventListener('storage', listener);

    $window.addEventListener('storage', listener);

    return iface;
};

/*
A client-side broadcast channel may deliver messages from one JS runtime to
another within the browsing context set by the origin and documents
open on a browser. This lets multiple top-level windows speak
to one another. Use to ease state syncing when network calls are not necessary.

Gracefully degrades from BroadcastChannel- to LocalStorage-based implementation.
*/
export const clientSideChannel = ($window, channelName) =>
    (($window.BroadcastChannel)
        ? new $window.BroadcastChannel(channelName)
        : _localStorageFallback($window, channelName));
