import { submitWebAttributes } from '#/ido-lib/idoWebClient';


export function businessFormModel(request) {
    const iface = {};

    const shareWith = [...new Set(Object.values(request.input).reduce((p, {shareWith: sw}) => {
        return p.concat([...sw]);
    }, []))];

    const attr = (value) => ({ value, shareWith });

    iface.maySubmit = () => (
        iface.legalName &&
        iface.dbaName &&
        iface.phoneNumberValid &&
        iface.streetAddress &&
        iface.city &&
        iface.state &&
        iface.postcode &&
        iface.taxId
    );

    iface.submit = () =>
        submitWebAttributes(iface.encode())
            .then(() => {
                for (const [attrType, ia] of Object.entries(request.input)) {
                    if (attrType.startsWith('business.') && attrType.indexOf('.evidentid_self_service.') === -1) {
                        ia.complete = true;
                    }
                }
            });

    iface.encode = () => {
        const {
            city,
            dbaName,
            dunsNumber,
            legalName,
            postcode,
            qualifiedValue,
            state,
            streetAddress,
            taxId,
        } = iface;

        return {
            'business.tax_id': attr(taxId),
            'business.name': attr(legalName),
            'business.full_address': attr(`${streetAddress}, ${city}, ${state[1]} ${postcode}`),
            'business.doing_business_as': attr(dbaName),
            'business.phone': attr(qualifiedValue),
            'business.duns_number': attr(dunsNumber || null),
        };
    };

    return iface;
}
