import { bem } from '#/browser-framework';


export const DelegateScanner = bem`
    DelegateScanner
        switch
        form
        controls
        action
`;
