import { m } from '#/browser-framework';

import { XCodeSample, SupportEmail } from './DocsLayout';


export const VerifyApiReissueTokenDocs = {
    view({
        attrs: {
            reissueTokenRequestExample,
        },
    }) {
        return [
            m('p', 'If needed, you can request a new token using a request ID.'),
            m(XCodeSample, reissueTokenRequestExample),
        ];
    },
};



export const VerifyApiCreateRequestDocs = {
    view({
        attrs: {
            createRequestRequestExample,
            createRequestResponseExample,
        },
    }) {
        return [
            m('p',
                'VerifyAPI is a RESTful JSON API that allows you to create a request ',
                'for verification, and retrieve information about an existing request.'),
            m('p',
                'You will need your username and an API key to authenticate yourself ',
                'with VerifyAPI using HTTP Basic Auth. If you did not receive your ',
                'credentials as you signed up, please send an email to ', m(SupportEmail), '. ',
                'You should have one API key for production use and one API for sandbox use.'),
            m('p',
                m('strong', 'Your API keys are secrets! '),
                'You should call VerifyAPI endpoints from your server, and only your server. ',
                'Be mindful of any leaks in how your key is used to ensure maximum protection.'),
            m('h3', 'How to create a verification request'),
            m('p',
                'Call VerifyAPI to create a request to verify information belonging to an end-user.'),
            m(XCodeSample, createRequestRequestExample),
            m('p',
                'A verification request consists of ', m('dfn', 'attributes'), '. Attributes are verifiable ',
                'data items used to build your own report of what you expect to be verified. ',
                'Check your API reference for more information on what you can request. ',
                'The end-user will need to authenticate and submit personal data to the system for verification. ',
                'Once the request is created, the end-user has 28 days from the time of their last submission to ',
                'finish before the overall request times out.'),
            m(XCodeSample, createRequestResponseExample),
            m('p',
                'VerifyAPI\'s response indicates that a request was created.',
                'Your end-user can use ', m('code', 'userIdentityToken'), ' to authenticate with an Evident web service.',
                'Unless you have cause to represent the end-user, you should not use this token yourself.'),
        ];
    },
};


export const VerifyApiReviewRequestDocs = {
    view({
        attrs: {
            getRequestResponseExample,
            lookupCodeExample,
            links,
        },
    }) {
        return [
            m('p',
                'To check the status and results of a request, look up your request by id on VerifyAPI.'),
            m(XCodeSample, lookupCodeExample),
            m('p',
                'The response will look like this:'),
            m(XCodeSample, getRequestResponseExample),
            m('p',
                'But since some verifications take longer than others, it would be best ',
                'to use ', m('a', { href: links.webhooks }, 'Webhooks'), ' to allow the ',
                'Evident platform to notify your application when results are available.'),
        ];
    },
};
