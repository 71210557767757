import { spa } from '#/browser-framework';
import { once } from '#/universal-framework/async';
import SubjectiveRelyingParty from '#/rp-facing/state/SubjectiveRelyingParty';
import { RpNotAvailable } from '../state/errors';

function rejectRpSelection(rpName) {
    return Promise.reject(RpNotAvailable({
        message: `RP ${rpName} is not available`,
        extra: { name: rpName },
    }));
}

async function selectRp(availableRps, selectedName, iface) {
    if (!availableRps.size) {
        return rejectRpSelection(selectedName);
    }
    const name = selectedName ? selectedName : Array.from(availableRps.keys())[0];
    const selectedRp = availableRps.get(name);

    if (!selectedRp) {
        return rejectRpSelection(selectedName);
    }

    iface.rp = await selectedRp.select();
    return iface.rp;
}


export function rpAdminController(client) {
    const iface = {};

    // Load all rps available to the user.
    // Memoized; Call iface.load.release() between iface.load() calls.
    iface.load = once(() =>
        ((client)
            ? client.requestJson({ url: '/relyingParties' })
            : Promise.resolve({ relyingParties: [] }))
            .then(({ relyingParties }) =>
                Promise.all(relyingParties.map((rp) =>
                    SubjectiveRelyingParty(rp, client))))
            .then((unsortedRelyingParties) =>
                unsortedRelyingParties
                    .sort((a, b) =>
                        a.displayName.localeCompare(b.displayName))
                    .reduce((p, srp) =>
                        p.set(srp.name, srp), new Map())));

    // Select an RP on which to operate.
    iface.selectRp = spa.redrawAfter((name) =>
        iface.load().then((availableRps) => {
            return selectRp(availableRps, name, iface);
        }));

    return iface;
}
