import Papa from 'papaparse';

import { bem, blobs, m } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';


export const CsvRowError = {
    btx: bem`
    CsvRowError
        index
        field
    `,
    view({
        attrs: {
            rowIndex,
        },
        children,
    }) {
        const { btx } = CsvRowError;

        return m(btx.block,
            m(btx.index, rowIndex),
            children.map((t, key) => m(btx.field, {key}, t)));
    },
};

export const CsvUploadErrorDialog = {
    btx: bem`
    CsvUploadErrorDialog
        title
        titleBar
        instructions
        dialogButton
        body
    `,
    view({ attrs: { App } }) {
        const { badRecords } = App.dialogState;
        const { btx } = CsvUploadErrorDialog;

        return m(btx.block,
            m(btx.titleBar,
                m(`h3${btx.title}`, 'We encountered some errors.'),
                m(pointable(btx.dialogButton), {
                    onpointerup() {
                        App.hideDialog();
                    },
                }, m(Named.Icon, { name: 'x' }))),
            m(btx.body,
                m(btx.instructions,
                    m('p', `
                    We created as many requests in your CSV file as we could, but
                    some records were in error. We prepared a new CSV file for you
                    with only the requests that could not be created. `, m('a', {
                        onclick() {
                            const csv = Papa.unparse(badRecords, { quotes: true });

                            blobs.downloadFileUsingBinString(csv, 'fixme.csv');
                        },
                    }, 'Please download that file here'),
                    ', make corrections, then upload the corrected file.')),
                m(btx.instructions,
                    m('strong', 'Do not upload your original CSV file again.'),
                    m('p', 'This may duplicate requests that are already out.'))));
    },
};
