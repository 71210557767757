import moment from 'moment';

import { logger } from '#/browser-framework';
import { naiveTitleCase } from '#/universal-framework/strings';

import {
    SubjectiveOrganizedAttribute,
    SubjectiveReportedAttribute,
} from './SubjectiveAttributes';


export const formatStatus = (status, statusDetail) => {
    const displayedStatus = status || 'Pending';

    // Dont change the title case of the statusDetail.
    return statusDetail || naiveTitleCase(displayedStatus.replace(/_/g, ' '));
};

export const bemStatus = (status) =>
    status.replace(/_/g, '-');


export default function SubjectiveRequest(objective, {
    organizedAttributes,
}) {
    const {
        attributes = [],
        createdAt,
        createdBy,
        webLink,
        notes,
        completedAt,
        idOwner: {
            id: idOwnerId,
            domain,
            email,
        },
        description,
        summary,
        id,
        status,
        statusDetail,
        seqId,
    } = objective;

    // TODO: This is a shim. Replace with contracts on service calls.
    if (!status) {
        logger.error(new Error(`Request ${id} has a falsey status: ${status}`));
    }

    return Object.assign({}, {
        id,
        webLink: (domain === 'evidentapp')
            ? ''
            : webLink,
        attributes: attributes.map((organizedAttributes)
            ? SubjectiveOrganizedAttribute
            : SubjectiveReportedAttribute),
        notes,
        seqId,
        createdAt,
        completedAt,
        date: moment(createdAt),
        recipient: {
            fullname: email || 'MISSING EMAIL',
            idOwnerId,
        },
        description,
        summary,
        sender: createdBy || 'Unknown Person',
        status: status || 'pending',
        statusDetail,
        formattedStatus: formatStatus(status, statusDetail),
    });
}
