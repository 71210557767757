import { m, spa } from '#/browser-framework';
import { Button, Spinner } from '#/browser-framework/comps';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';
import { woopraTrackSendEmailVerification } from '#/browser-framework/woopraLib';



// Possible email verification states.
const STATES = {
    // "We only just got here and don't know the user's verification status"
    INITIALIZING: Symbol(),

    // "We saw the user did not verify their email address, and we automatically sent
    // a verification email to the user without issue."
    AUTO_EMAIL_SENT: Symbol(),

    // "We saw the user did not verify their email address, and we failed to automatically send
    // a verification email." -- Used to pop up UI to let user send their own email.
    AUTO_EMAIL_NOT_SENT: Symbol(),

    // Used to carry user to onboarding flow
    EMAIL_VERIFIED: Symbol(),

    // Used to invite user to try again, create a new account, or contact support.
    EMAIL_NOT_VERIFIED: Symbol(),
};

let fsmState = STATES.INITIALIZING;

const sendEmail = () =>
    spa
        .$window
        .firebase
        .auth()
        .currentUser
        .sendEmailVerification({
            url: Object.assign(new URL(spa.$window.location.href), {
                hash: '#!/setup/contact',
            }).toString(),
        });

const SendButton = {
    view({
        attrs: {
            disabled,
            onpress = () => {},
        },
        children,
    }) {
        return m(Button, {
            flags: {
                primary: true,
            },
            disabled,
            onpress,
        }, children);
    },
};


const OfferRetryVerification = {
    sending: false,
    alreadySent: false,
    error: null,
    view({ state }) {
        return [
            (state.error) && m('p.fg-danger', 'The service seems down right now. Try again in a few minutes.'),
            m('p', `We sent you an email to verify your account.
            If you do not see an email, please click the button below
            to send a new one.`),
            m(SendButton, {
                disabled: state.sending || state.alreadySent,
                onpress() {
                    state.sending = true;
                    spa.redraw();

                    sendEmail()
                        .then(() => {
                            woopraTrackSendEmailVerification(false, true);
                            state.alreadySent = true;
                        })
                        .catch((e) => {
                            state.error = e;
                        })
                        .then(() => {
                            state.sending = false;
                            spa.redraw();
                        });
                },
            },
            (state.alreadySent)
                ? 'Email sent'
                : 'Send new verification email'),
        ];
    },
};


const PassedVerification = {
    view() {
        return m('p', 'Your email has been verified! ',
            m('a', { href: '#!/auth' }, 'Please log in again.'));
    },
};

const FailedVerification = {
    view() {
        return m('p',
            `Sorry, but your email could not be verified.
            This can happen if the verification link has expired
            or due to a temporary technical issue. You can send a new email
            now, but if this error persists after trying again, please
            contact support.`);
    },
};

const VerifyEmail = {
    view({
        attrs: {
            viewModel,
        },
    }) {
        if (fsmState === STATES.INITIALIZING) {
            return m(Spinner);
        }

        return m('.VerifyEmail', m({
            [STATES.EMAIL_VERIFIED]: PassedVerification,
            [STATES.EMAIL_NOT_VERIFIED]: FailedVerification,
            [STATES.AUTO_EMAIL_SENT]: OfferRetryVerification,
            [STATES.AUTO_EMAIL_NOT_SENT]: OfferRetryVerification,
        }[fsmState], viewModel));
    },
};


let lock = false;
export default ([actioncode]) => {
    spa.setView(VerifyEmail, {
        layout: RestrictedLayout,
    });

    let vow = Promise.resolve();

    if (spa.viewModel.user.ascertained) {
        if (actioncode) {
            vow = spa.$window.firebase.auth()
                .applyActionCode(actioncode)
                .verifyEmail(actioncode)
                .then(() => (fsmState = STATES.EMAIL_VERIFIED))
                .catch(() => (fsmState = STATES.EMAIL_NOT_VERIFIED))
                .then(() => spa.redraw());
        } else if (!lock) {
            lock = true;

            vow = sendEmail()
                .then(() => (fsmState = STATES.AUTO_EMAIL_SENT))
                .catch(() => (fsmState = STATES.AUTO_EMAIL_NOT_SENT));
        }
    }

    vow.then(() => spa.redraw());
};
