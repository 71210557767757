import preval from 'preval.macro';

import { m, spa } from '#/browser-framework';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import {
    bindNavigation,
    CodeBlock,
    DynamicWalkthroughLayout,
    TermDefinitions,
    Walkthrough,
    WithRp,
    XCodeSample,
} from './DocsLayout';

import {
    VerifyApiCreateRequestDocs,
    VerifyApiReviewRequestDocs,
    VerifyApiReissueTokenDocs,
} from './VerifyApiDocs';


const VERIFY_ASSURE_GITHUB_LINK = 'https://github.com/evidentid/example-integration-verifyapi-assuresdk';

const ASSURE_SDK_VERSION = preval`
module.exports = require(
    process.env.WEB_PRIVATE_HOME_DIR +
    '/src/assure-sdk/package.json').version;`;

const ASSURE_SDK_URL = `https://cdn.evidentid.com/sdk/${ASSURE_SDK_VERSION}/assure-sdk.js`;


const {
    TableOfContents,
    step1,
    step2,
    step3,
    step4,
    errorHandling,
} = bindNavigation('/docs/verify-api-assure-sdk', [
    ['Step 1. Create a request using VerifyAPI', 'step1'],
    ['Step 2. Send user token to a web UI using AssureSDK', 'step2'],
    ['Step 3. Collect user submission on your UI', 'step3'],
    ['Step 4. Review results when ready', 'step4'],
    ['Error handling', 'errorHandling'],
]);

const Content = WithRp({
    view({
        attrs: {
            name: rpName,
            docs: {
                links,
                content: {
                    assureSdkSubmitExample,
                    createRequestRequestExample,
                    createRequestResponseExample,
                    getRequestResponseExample,
                    reissueTokenRequestExample,
                    lookupCodeExample,
                },
            },
        },
    }) {
        return m(Walkthrough.block,
            m(Walkthrough.header,
                m('h2', 'VerifyAPI and AssureSDK'),
                m('p',
                    'This guide shows you how to integrate your back-end and branded web browser ',
                    'UI with the Evident platform. With this approach you avoid handling personal ',
                    'information on your servers, but still allow end-users to submit their data for ',
                    'verification through your experience. ', m('a', { href: links.webhooks }, 'Webhooks'),
                    ' will notify your application when results are ready for review. To supplement this ',
                    'guide, check out our example integration ', m('a', { href: VERIFY_ASSURE_GITHUB_LINK }, ' on GitHub')),
                m(TableOfContents, { rpName })),
            m(Walkthrough.section,
                m(step1),
                m(VerifyApiCreateRequestDocs, {
                    createRequestRequestExample,
                    createRequestResponseExample,
                }),
                m(VerifyApiReissueTokenDocs, {
                    reissueTokenRequestExample,
                })),
            m(Walkthrough.section,
                m(step2),
                m('p',
                    'Once you have a request for Evident to verify an end user’s personal information, ',
                    'that user will need to fill out a form to submit data to Evident and ultimately ',
                    'fulfill your request. The user will need their issued ', m('code', 'userIdentityToken'), ' to do this.',
                    'Since you have control over the UX for data collection, you only need to set up AssureSDK to ',
                    'submit the data to the Evident platform.'),
                m('p',
                    'To set up AssureSDK, ',
                    m('a', { href: ASSURE_SDK_URL }, 'download it here'),
                    ' or load it from our CDN in an HTML page using this ', m('code', '<script>'), ' tag.'),
                m(CodeBlock, {
                    lang: 'html',
                    sourceCodeText: '<script \n' +
                    '    type="text/javascript" \n' +
                    `    src="${ASSURE_SDK_URL}"></script>`,
                }),
            ),
            m(Walkthrough.section,
                m(step3),
                m('p',
                    'Once AssureSDK loads in your page, call ', m('code', 'EvidentID.AssureSDK.setUp()'), ' using the ',
                    m('code', 'userIdentityToken'), ' provided to you when you created a request with VerifyAPI to start ',
                    'the end user\'s session. Once the session is established, you can submit attributes to the platform.',
                    'The ', m('a', { href: VERIFY_ASSURE_GITHUB_LINK }, 'example integration'), ' shows a complete setup.'),
                m(XCodeSample, assureSdkSubmitExample)),
            m(Walkthrough.section,
                m(step4),
                m(VerifyApiReviewRequestDocs, {
                    getRequestResponseExample,
                    links,
                    lookupCodeExample,
                })),
            m(Walkthrough.section,
                m(errorHandling),
                m('p', 'Public AssureSDK API functions will throw ', m('code', 'Error'), ' objects under a few rules:'),
                m('ul',
                    m('li',
                        'The ', m('code', 'Error'), ' object may have a ', m('code', 'reason'),
                        ' and/or an ', m('code', 'extra'), ' property. If the error is of a built-in ',
                        'type like ', m('code', 'TypeError'), ', the ',
                        m('code', 'reason'), ' code MAY be present. If it is, it will ',
                        'contain the value most closely related to the error message.'),
                    m('li',
                        'If an ', m('code', 'extra'), ' property appears, it will hold information related to the error.'),
                    m('li',
                        'A reason code has the format ', m('code', '<facility>/<cause>'), ' where ',
                        m('code', '<facility>'), ' is the scope of the error and ',
                        m('code', '<cause>'), ' is the specific problem within that facility.')),
                m(CodeBlock, {
                    lang: 'javascript',
                    sourceCodeText: '// Sync\n' +
                        'try {\n' +
                        '  AssureSDK.createDocumentPageFromDataUrl(...)\n' +
                        '} catch (e) {\n' +
                        '  if (e.reason) console.log(e.reason, e.extra); // base/bad-arguments undefined\n' +
                        '}\n' +
                        '\n' +
                        '// Async\n' +
                        'AssureSDK.submitAttributes(...).catch((e) => {\n' +
                        '  if (e.reason) console.log(e.reason, e.extra); // service/rate-limited 14\n' +
                        '});',
                }),
                m('h3', 'AssureSDK reason codes'),
                m(TermDefinitions, {
                    wrapTerm: TermDefinitions.codeLabels,
                    pairs: [
                        [
                            'auth/unauthorized',
                            'The authorization token is invalid or expired.',
                        ],
                        [
                            'base/bad-arguments',
                            'The arguments provided to a function call are invalid and cannot be used.',
                        ],
                        [
                            'base/no-promise-ctor',
                            'A Promise constructor (or equivalent) was not present at the time setUp() was called',
                        ],
                        [
                            'base/uninitialized',
                            [
                                'Client code did not call the ',
                                m('code', 'setUp()'), ' function before using ',
                                'AssureSDK to submit attribute data.',
                            ],
                        ],
                        [
                            'idscan/bad-back',
                            [
                                'The back of the ID card did not pass ',
                                'pre-submission validation checks. You ',
                                'may ask the user to try a new image.',
                            ],
                        ],
                        [
                            'idscan/bad-front',
                            [
                                'The front of the ID card did not pass pre-submission ',
                                'validation checks. You may ask the user to try a new image.',
                            ],
                        ],
                        [
                            'idscan/missing-back',
                            'Did not get a file for the back of the ID.',
                        ],
                        [
                            'idscan/missing-front',
                            'Did not get a file for the front of the ID.',
                        ],
                        [
                            'idscan/missing-both',
                            'Did not get a file for either side of an ID.',
                        ],
                        [
                            'service/cant-connect',
                            'The SDK was unable to connect to Evident’s services.',
                        ],
                        [
                            'service/rate-limited',
                            [
                                'The client is making too many requests. ',
                                m('code', 'extra'),
                                ' holds a Number of seconds to wait before trying again.',
                            ],
                        ],
                        [
                            'service/internal-error',
                            'Evident’s services encountered an unspecified error during processing.',
                        ],
                        [
                            'submit/invalid-attribute-value',
                            [
                                'One of the provided attribute values was not valid, and was rejected from processing. ',
                                m('code', 'extra'), ' is a String naming the offending attribute type.',
                            ],
                        ],
                        [
                            'submit/unknown-attribute',
                            [
                                'One of the specified attribute types in a submission was not recognized. ',
                                m('code', 'extra'), ' is a String naming the offending attribute type.',
                            ],
                        ],
                    ],
                })));
    },
});

export default ensureRpSelection(() => {
    spa.setView(Content, {
        layout: DynamicWalkthroughLayout,
    });
}, {
    requireTerms: true,
    pendingLayout: WithLeftNav,
});
