import { m, spa } from '#/browser-framework';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import {
    bindNavigation,
    DynamicWalkthroughLayout,
    Walkthrough,
    WithRp,
    XCodeSample,
} from './DocsLayout';

import {
    VerifyApiCreateRequestDocs,
    VerifyApiReviewRequestDocs,
    VerifyApiReissueTokenDocs,
} from './VerifyApiDocs';



const {
    TableOfContents,
    step1,
    step2,
    step3,
    step4,
} = bindNavigation('/docs/verify-api-submit-api', [
    ['Step 1. Create a request using VerifyAPI', 'step1'],
    ['Step 2. Submit data using SubmitAPI', 'step2'],
    ['Step 3. Wait for webhook notification', 'step3'],
    ['Step 4. Review results', 'step4'],
]);

const Content = WithRp({
    view({
        attrs: {
            name: rpName,
            docs: {
                links,
                content: {
                    createRequestResponseExample,
                    createRequestRequestExample,
                    getRequestResponseExample,
                    lookupCodeExample,
                    reissueTokenRequestExample,
                    submitAttributesRequestExample,
                },
            },
        },
    }) {
        return m(Walkthrough.block,
            m(Walkthrough.header,
                m('h2', 'VerifyAPI and SubmitAPI'),
                m('p',
                    'This guide shows you how to integrate with the Evident platform in a way that allows ',
                    'complete customization of the end-to-end experience. Know that in this context your ',
                    'server ', m('strong', 'gains visibility into personal data.'), ' Use this approach when ',
                    'your envisioned experience can support all related security and regulatory responsibilities.'),
                m(TableOfContents, { rpName })),
            m(Walkthrough.section,
                m(step1),
                m(VerifyApiCreateRequestDocs, {
                    createRequestRequestExample,
                    createRequestResponseExample,
                }),
                m(VerifyApiReissueTokenDocs, {
                    reissueTokenRequestExample,
                })),
            m(Walkthrough.section,
                m(step2),
                m('p',
                    'Like VerifyAPI, SubmitAPI is a RESTful JSON service. SubmitAPI powers the interactions ',
                    'for any experience in which someone represents an owner of personal data. ',
                    'For the purposes of this guide, a SubmitAPI client represents that owner. ',
                    'Your client will need to authenticate using the ', m('code', 'userIdentityToken'), 'provided by ',
                    'VerifyAPI as a bearer token.'),
                m(XCodeSample, submitAttributesRequestExample)),
            m(Walkthrough.section,
                m(step3),
                m('p',
                    'Once data is submitted, the platform will begin running verifications. ',
                    'Some verifications take longer than others, so it is best to use ',
                    m('a', { href: links.webhooks }, 'webhooks'), ' to capture a notification ',
                    'for when a request is complete.')),
            m(Walkthrough.section,
                m(step4),
                m(VerifyApiReviewRequestDocs, {
                    getRequestResponseExample,
                    links,
                    lookupCodeExample,
                })));
    },
});


export default ensureRpSelection(() => {
    spa.setView(Content, {
        layout: DynamicWalkthroughLayout,
    });
}, {
    requireTerms: true,
    pendingLayout: WithLeftNav,
});
