export class AttributeFiltering {
    /**
     * @param {string[]} attributes - an array of attribute to search from
     */
    constructor(attributes) {
        this.attributes = attributes;
        this.separator = ' ➔ ';
        this.separatorReplacement = ' ';
        this.resultsLimit = 30;
    }

    /**
     * @typedef Fragment
     * @property {string} text - part of attribute
     * @property {boolean} isMatch - describes, if fragment matches search
     */

    /**
     * @typedef {Object} ExplodedType
     * @property {Fragment[]} exploded - parts containing meta information
     * @property {string} origin - original attribute name
     */

    /**
     * @param {string} keyword - keyword for search
     * @returns {ExplodedType[]} - limited search results, sorted by name
     */
    getResults(keyword) {
        this.keyword = keyword.toLowerCase();
        const results = [];

        for (const attribute of this.attributes) {
            const { occurrences, processed } = this._findAndReplaceSeparators(attribute);
            const exploded = this._explodeMatches(processed, this.keyword);

            if (exploded.length > 1) {
                results.push({
                    exploded: this._restoreSeparators(occurrences, exploded),
                    origin: attribute,
                });

                if (results.length >= this.resultsLimit) {
                    break;
                }
            }
        }

        return this._sortResults(results);
    }

    _findAndReplaceSeparators(attribute) {
        const occurrences = [];
        let lastIndex = 0;
        let toProcess = attribute;
        let processed = '';

        while (toProcess) {
            const index = toProcess.indexOf(this.separator);
            const found = index !== -1;

            processed += toProcess.substring(0, found ? index : undefined);
            if (found) {
                lastIndex = lastIndex + index + 1;
                occurrences.push(lastIndex);
                processed += this.separatorReplacement;
                toProcess = toProcess.substring(this.separator.length + index);
            } else {
                toProcess = '';
            }
        }
        return { occurrences, processed };
    }

    _explodeMatches(attribute) {
        const result = [];
        let toProcess = attribute;
        let operationIndex = 0;

        while (toProcess) {
            const foundIndex = toProcess.toLowerCase().indexOf(this.keyword);
            if (foundIndex === -1) {
                result.push(AttributeFiltering.formatFragment(operationIndex, attribute.length, toProcess, false));
                toProcess = '';
            } else {
                const formerString = toProcess.substring(0, foundIndex);
                if (formerString) {
                    const end = operationIndex + formerString.length;
                    result.push(AttributeFiltering.formatFragment(operationIndex, end, formerString, false));
                }

                const found = toProcess.substring(foundIndex, foundIndex + this.keyword.length);
                const operationEndIndex = operationIndex + foundIndex + found.length;
                const startIndex = operationIndex + formerString.length;
                result.push(AttributeFiltering.formatFragment(startIndex, operationEndIndex, found, true));

                toProcess = attribute.substring(operationEndIndex);
                operationIndex = operationEndIndex;
            }
        }
        return result;
    }

    _restoreSeparators(occurrences, exploded) {
        return exploded.map(({ isMatch, end, start, string }) => {
            let text = '';
            let operationIndex = 0;
            occurrences.forEach((index) => {
                if (index > start && index <= end) {
                    text += string.substring(operationIndex, index - start - 1);
                    text += this.separator;
                    operationIndex = index - start;
                }
            });
            text += string.substring(operationIndex);
            return { text, isMatch };
        });
    }

    _sortResults(results) {
        return results
            .sort((a, b) => a.origin.localeCompare(b.origin));
    }

    static formatFragment(start, end, string, isMatch) {
        return { start, end, string, isMatch };
    }
}

