import { bem, m, harness, spa, router, tx } from '#/browser-framework';
import { Button, Named } from '#/browser-framework/comps';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { computePricingLink } from '#/rp-facing/core/salesInteractor';
import PlanSelectionPackages from './PlanSelectionPackages';


const StandardVerifications = {
    btx: bem`StandardVerifications
        heading
        list`,
    view() {
        const {
            block,
            heading,
            list,
        } = StandardVerifications.btx;

        return m(block,
            m(heading, 'Standard verifications include:'),
            m(`ul${list}`,
                m('li', 'United States Coast Guard Credentials'),
                m('li', 'FAA Pilots Certifications'),
                m('li', 'State Contractor Licenses'),
                m('li', 'Scuba Certification Verification'),
                m('li', 'First Aid Certification'),
                m('li', 'DOT Number Verification'),
                m('li', 'Challenge History'),
                m('li', 'Vehicle Registration Verification'),
                m('li', 'CMS / OIG Exclusion List'),
                m('li', 'W9 Verification'),
                m('li', 'And more')));
    },
};

const SelectPlanButton = {
    view({
        attrs: {
            onpress,
        },
        children,
    }) {
        return m(Button, harness('submit-button', {
            flags: {
                round: true,
                primary: true,
            },
            onpress,
        }), children);
    },
};

const PlanHeader = {
    btx: bem`
        PlanHeader
            title
            price
    `,
    view({
        children,
    }) {
        const { btx } = PlanHeader;

        return m(btx.block,
            children);
    },
};

const PlanSummary = bem`PlanSummary content`;
const PlanDetail = bem`PlanDetail head item addendum callToAction custom `;

const PlanItems = {
    btx: bem`PlanItems
        item`,
    view({
        children,
    }) {
        const { btx } = PlanItems;

        return m(btx.block,
            children);
    },
};

const Plan = {
    btx: bem`Plan bullet`,
    view({
        children,
    }) {
        const { btx } = Plan;

        return m(btx.block,
            children);
    },
};


const LittleCheck = {
    view() {
        return m(tx(Plan.btx.bullet, 'LittleCheck'), m(Named.Icon, {
            name: 'checkmark',
        }));
    },
};

const StandardPlan = {
    view({
        attrs: {
            packageData,
            onselect,
        },
    }) {
        const { btx: ph } = PlanHeader;
        const { btx: pi } = PlanItems;

        return m(Plan,
            m(PlanHeader,
                m(`h3${ph.title}`, packageData.title),
                m(ph.price, `$ ${packageData.pricing.price} / ${packageData.pricing.unit}`),
                m(SelectPlanButton, {
                    onpress: onselect,
                }, 'Select')),
            m(PlanSummary.block,
                m(PlanSummary.content,
                    packageData.summary)),
            m(PlanDetail.block,
                m(PlanDetail.head,
                    packageData.title === 'Build Your Own'
                        ? 'Options:'
                        : 'Includes:'),
                packageData.details.map((detail) => (
                    m(pi.item,
                        m(LittleCheck),
                        m('span', detail)))),
                (packageData.callToAction && m(PlanDetail.callToAction, packageData.callToAction)) ||
                (packageData.custom && m(PlanDetail.custom, packageData.custom)) ||
                (packageData.addendum && m(PlanDetail.addendum, packageData.addendum))));
    },
};

const PlanSelection = {
    btx: bem`
    PlanSelection
        heading
        subHeading
        plans
        callToAction`,
    view({
        attrs: {
            viewModel: {
                user,
            },
        },
    }) {
        const { btx } = PlanSelection;
        const {
            onboarding: {
                packageSelectionFormModel,
            },
        } = user;

        return m(btx.block,
            m(`h2${btx.heading}`,
                'Next Step: select a plan that works for your business'),
            m(btx.subHeading,
                'We offer a set of core services, or your can create your own custom package as unique as your business.'),
            m(btx.plans,
                Object.values(PlanSelectionPackages).map((packageData) => (
                    m(StandardPlan, {
                        packageData,
                        onselect() {
                            if (packageData.title === 'Build Your Own') {
                                router.go('/setup/custom-package-contact');
                            } else {
                                packageSelectionFormModel.submit(packageData.submissionValues).then(() => {
                                    router.go('/setup/schedule-onboarding-call');
                                });
                            }
                        },
                    })))),

            m(btx.callToAction,
                'Not sure which option is right for you, or need help customizing the perfect package? ',
                m('a', {
                    href: computePricingLink({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phone: user.onboarding.onboardPhoneNumberFormModel.value,
                        email: user.email,
                    }),
                }, 'Talk to our sales team.')),
            m(btx.callToAction,
                '* May be subject to additional state or country pass-through fees. ',
                m('a', {
                    href: 'https://www.evidentid.com/fees/',
                    target: '_blank',
                }, 'Learn more.')));
    },
};

export default () => {
    spa.setView(PlanSelection, {
        layout: WithLeftNav,
    });
};
