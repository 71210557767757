import { async, spa, router } from '#/browser-framework';
import { simplex } from '#/evident-attributes/encoding/id-scanning';
import { createBinaryDataFromDataUrl } from '#/evident-attributes/encoding/documents';
import { delegationController } from '#/ido-lib/delegationController';
import { getVerificationRequestDetails, submitWebAttributes } from '#/ido-lib/idoWebClient';

import { QuadrilateralLattice } from '#/rp-facing/views/QuadrilateralLattice';


export function idScanFormModel(request, {
    countries = ['USA', 'Canada'],
    user,
} = {}) {
    const iface = {};

    iface.front = simplex();
    iface.back = simplex();
    iface.passport = simplex();

    iface.lattice = QuadrilateralLattice.makeViewModel({
        width: 1000,
        height: 600,
        pointFillColor: '#f00',
    });

    iface.delegationController = delegationController('');

    iface.stage = spa.redrawAfter((key, files) => {
        iface[key].file = files && files[0];

        return iface[key].computeDataUrl();
    });

    iface.poll = () => {
        const poller = async.poll({
            peri() {
                return getVerificationRequestDetails(request.id);
            },
            post(value) {
                const deps = value.attribute_sharing_agreements
                    .reduce((p, { dependencies }) => p.concat(dependencies.dependentAttributes), [])
                    .filter(({ attributeType }) =>
                        attributeType.endsWith('document_type'));

                if (deps.every(({ status }) => status === 'exists')) {
                    poller.stop();
                    iface._markComplete();
                    router.go('/setup/overview');
                } else if (router.here() !== '/setup/delegation') {
                    poller.stop();
                }
            },
        });

        return poller.start();
    };

    iface.maySubmit = () => {
        const withFiles = (iface.type === 'passport')
            ? Boolean(iface.passport.dataUrl)
            : iface.front.dataUrl && iface.back.dataUrl;

        return withFiles && iface.country;
    };

    iface._markComplete = () => {
        for (const [attrType, ia] of Object.entries(request.input)) {
            if (attrType.startsWith('identity_assurance.document_verification')) {
                ia.complete = true;
            }
        }
    };

    iface.encode = () => {
        return false;
    };

    iface.submit = () => {
        iface.submitting = true;
        spa.redraw();

        const shareWith = Object.values(request.input).reduce((p, { attrType, shareWith: sw }) =>
            ((attrType.startsWith('identity_assurance.document_verification'))
                ? p.concat(Array.from(sw))
                : p), []);

        const payload = (iface.type === 'passport')
            ? ({
                'identity_assurance.document_verification.passport.image': {
                    shareWith,
                    value: createBinaryDataFromDataUrl(iface.passport.dataUrl),
                },
            })
            : ({
                'identity_assurance.document_verification.drivers_license.image.front': {
                    shareWith,
                    value: createBinaryDataFromDataUrl(iface.front.dataUrl),
                },
                'identity_assurance.document_verification.drivers_license.image.back': {
                    shareWith,
                    value: createBinaryDataFromDataUrl(iface.back.dataUrl),
                },
            });

        Object.assign(payload, {
            'identity_assurance.document_verification.document_type': {
                shareWith,
                value: {
                    country: iface.country,
                    id_type: (iface.type === 'passport')
                        ? 'passport'
                        : 'drivers_license',
                },
            },
        });

        return submitWebAttributes(payload)
            .then(() => {
                iface._markComplete();
                iface.submitting = false;
                spa.redraw();
            });
    };

    iface.onDelegationRequest = () =>
        user.client
            .requestJson({ url: '/userInfo' })
            .then(({ authToken }) => iface.delegationController.sendText({
                authToken,
                continueUrl: `${location.origin}/#!/setup/delegate/start/${request.id}`,
            }))
            .then(() => router.go('/setup/delegation'))
            .then(() => iface.poll());

    iface.countries = countries;
    iface.countryIndex = idScanFormModel.countryIndex;
    iface.country = null;

    return iface;
}
