import { m, harness, router, spa } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';
import { RpView } from '#/rp-facing/views/RpView';
import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import { TermsAndConditionsControl } from './TermsAndConditionsControl';


const TermsAndConditions = {
    view({
        attrs: {
            accepted,
            proceed,
            onAcceptChange,
        },
    }) {
        return m('.TermsAndConditions',
            m(TermsAndConditionsControl, {
                accepted,
                onAcceptChange,
            }),
            m('.TermsAndConditions__proceed',
                m(Button, harness('terms-and-conditions-next-button', {
                    flags: {
                        primary: true,
                    },
                    disabled: !accepted,
                    onpress() {
                        if (accepted) {
                            proceed();
                        }
                    },
                }), 'Next')));
    },
};


// For when the user has an onboarded account, and only needs to sign T&Cs.
const IsolatedTermsAndConditions = RpView({
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        const {
            acceptedTermsAndConditions,
        } = App.user.selectedRp.settings;

        return m('.IsolatedTermsAndConditions',
            m(TermsAndConditions, {
                accepted: acceptedTermsAndConditions,
                onAcceptChange: (s) => {
                    App.user.selectedRp.settings.acceptedTermsAndConditions = s;
                },
                proceed() {
                    App.user.selectedRp
                        .agreeToTerms({acceptedTermsAndConditions})
                        .then(() => {
                            App.user.selectedRp.settings.tcSigned = true;

                            return App.goToLandingPage();
                        })
                        .catch((e) => {
                            App.err = e;
                            router.go('/error');
                        });
                },
            }));
    },
});


export default ensureRpSelection(() => {
    spa.setView(IsolatedTermsAndConditions, { layout: RestrictedLayout });
}, {
    requireTerms: false,
    pendingLayout: RestrictedLayout,
});
