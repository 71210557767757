import { bem, m } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';


export const PendingDialog = {
    btx: bem`
    PendingDialog
        body
    `,
    view() {
        const { btx } = PendingDialog;

        return m(btx.block,
            m(btx.body,
                m(Spinner),
                m('p', 'Please wait...')));
    },
};
