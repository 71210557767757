import { bem, m, tx, harness } from '#/browser-framework';
import { ConsentCheckbox } from '#/browser-framework/comps';

import { TermsAndConditionsLanguage } from './TermsAndConditionsLanguage';


export const TermsAndConditionsControl = {
    btx: bem`
        TermsAndConditionsControl
            heading
            language
            accept`,
    view({
        attrs: {
            accepted,
            onAcceptChange,
        },
    }) {
        const { btx } = TermsAndConditionsControl;

        return m(btx.block,
            m(btx.language,
                m(TermsAndConditionsLanguage)),
            m(`label${tx(btx.accept, {checked: accepted})}`, {
                for: 'accepted',
            },
            m(ConsentCheckbox, harness('terms-and-conditions-consent-checkbox', {
                id: 'accepted',
                checked: accepted,
                onchange: onAcceptChange,
            }), 'I acknowledge that I have read and accept these terms and conditions.')));
    },
};
