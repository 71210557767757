import { m, harness } from '#/browser-framework';
import { AutoComplete, AutoCompleteEntries, pointable } from '#/browser-framework/comps';
import { ConfirmPopupEvents } from '#/rp-facing/views/ConfirmPopup';
import stateEventEmitter from '#/rp-facing/state/stateEventEmitter';


const SelectedAttrChip = {
    view({attrs: {onpointerup, sa}}) {
        return m('span.SelectedAttrChip', harness('create-request-page-chip-from-chip-wall'),
            m('span.SelectedAttrChip__label', sa.univocalName),
            m(pointable('i.SelectedAttrChip__icon.fa.fa-times'), harness('chip-wall-input-remove-button', {onpointerup})));
    },
};


/*

Looks like this when empty:

------------------------------------------------------------------
| <AutoComplete>                                                 |
------------------------------------------------------------------


Then it fills up with selected attributes

------------------------------------------------------------------
| [blah x] [blah x] [blah x] [blah x] [blah x][blah x]           |
| [blah x] [blah x] [blah x] <AutoComplete>                      |
------------------------------------------------------------------

*/

export default {
    view({attrs: {archive, suggestions, stagedRequest}}) {
        return m('.CreateRequestAttributeChips',
            m('h2', 'Selected Attributes'),
            m('label.CreateRequestAttributeChips__LooksLikeInput[for="attr-autocomplete"]', Array
                .from(archive.selectedAttributes())
                .map((sa, i) => {
                    return m(SelectedAttrChip, {
                        sa,
                        i,
                        stagedRequest,
                        onpointerup: () => {
                            // Warn user before removing attribute from a template.
                            const shouldWarn = stagedRequest && stagedRequest.template && stagedRequest.template.attributes.includes(sa.path);

                            if (shouldWarn) {
                                stateEventEmitter.emit(ConfirmPopupEvents.SHOW, {
                                    onConfirmCallback() {
                                        // on confirm remove the attribute and no longer show the template
                                        archive.selectAttribute(sa.path, false);
                                        stagedRequest.removeTemplate();
                                    },
                                });
                            } else {
                                archive.selectAttribute(sa.path, false);
                            }
                        },
                    });
                })
                .concat([
                    m(AutoComplete, Object.assign(harness('create-request-auto-complete-input',
                        {
                            id: 'attr-autocomplete',
                            attached: false,
                            placeholder: 'Type for suggestions',
                        }), suggestions)),
                    m(AutoCompleteEntries, Object.assign({
                        id: 'attr-autocomplete',
                    }, suggestions)),
                ])));
    },
};
