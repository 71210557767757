import { m, tx, harness, expose, router, strings } from '#/browser-framework';
import { pointable } from '#/browser-framework/comps';

import UserBadge from '#/rp-facing/views/UserBadge';
import { bemStatus } from '#/rp-facing/state/SubjectiveRequest';


export default {
    view({attrs: {rp, requests}}) {
        return requests.map((request, i) => {
            const { formattedStatus, sender, status, summary } = request;
            const admin = request.sender || {};
            const testData = {
                ido: request.recipient.fullname,
                status,
                sender: admin.displayName || sender,
                summary,
            };

            return m(pointable('tr'), harness('request-table-element', expose({
                onpointerdown: () => {
                    router.go(`/${rp.name}/requests/view/${request.id}`, {
                        state: {index: i},
                    });
                },
            }, testData)),
            m('td.RequestTable__DateColumn', harness('request-table-element-date-cell'),
                request.date.format('MM/DD/YYYY, h:mm a')),
            m('td.RequestTable__IdoColumn', harness('request-table-element-ido-cell'),
                strings.ellipsis(request.recipient.fullname, 40)),
            m('td.RequestTable__TypeColumn', harness('request-table-element-type-cell'),
                summary),
            m('td.RequestTable__SenderColumn', harness('request-table-element-sender-cell'),
                m(UserBadge, {
                    headshot: admin.photoUrl,
                    fullname: admin.displayName || sender,
                    bgcolor: '#000',
                })),
            m('td.RequestTable__StatusColumn', harness('request-table-element-status-cell'),
                m(tx('Status', `Status--${bemStatus(status)}`),
                    m('span.Status__light', {
                        style: {
                            color: {
                                'Rejected': 'red',
                                'Pending': 'red',
                                'Expired': 'red',
                                'Unknown': 'red',
                                'Shared': 'green',
                            }[status],
                        },
                    }, m.trust('&#9679;')),
                    m('span.Status__status', formattedStatus))));
        });
    },
};
