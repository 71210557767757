class RouteSequence {
    constructor(sequence) {
        this.sequence = sequence;
    }

    async getRoute() {
        for (let i = 0; this.sequence.length > i; i++) {
            const [ condition, route ] = this.sequence[i];
            const result = await RouteSequence.executeIfFunction(condition);

            if (result) {
                const routeResolved = await RouteSequence.executeIfFunction(route);
                if (routeResolved) {
                    return routeResolved;
                }
            }
        }
        return Promise.reject(new Error('No route was found.'));
    }

    static executeIfFunction(functionOrValue) {
        return typeof functionOrValue === 'function'
            ? functionOrValue()
            : functionOrValue;
    }
}

export default RouteSequence;
