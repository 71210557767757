import dateFormat from 'dateformat';
import JSZip from 'jszip';

import { Button, Named } from '#/browser-framework/comps';
import { bem, m, strings } from '#/browser-framework';
import { dateObjectToLocaleString, dateObjectToJsDate } from '#/universal-framework/dates';

import { addressToString } from './addresses';
import { binaryDataToDataUrl } from './encoding/documents';
import { pickLatestDispositionDate } from './crimes';


export const Name = {
    view({
        attrs: {
            prefix,
            first,
            middle,
            last,
            suffix,
        },
    }) {
        return [prefix, first, middle, last, suffix].filter((x) => x).join(' ');
    },
};


export const Address = {
    view({ attrs: value }) {
        return m('pre.Address', addressToString(value));
    },
};


export const AuthorizedPartyBinding = {
    view({ attrs: { authorized_party_email: email, rp_entity_id: rei } }) {
        const { CN } = rei.split('/').reduce((p, c) => {
            if (c) {
                const [k, v] = c.split('=');

                p[k] = v;
            }

            return p;
        }, {});

        return `${email} ➔ ${CN}`;
    },
};

export const BinaryData = {
    view({
        attrs: {
            bin,
            paranoid,
            vnode = () => {
                const dataUrl = binaryDataToDataUrl(bin);
                const fallback = m('p.BinaryDataPreview__fallback', bin.mime_type);

                return (bin.mime_type.startsWith('image/'))
                    ? m('img', { src: dataUrl, style: 'image-orientation: from-image' })
                    : (paranoid)
                        ? fallback
                        : (bin.mime_type === 'application/pdf')
                            ? m('iframe', { src: dataUrl })
                            : fallback;
            },
        },
    }) {
        return m('.BinaryData', vnode(bin));
    },
};

export const BusinessProfile = {
    view({
        attrs: {
            /* eslint-disable camelcase */
            civil_policy_compliance_status,
            criminal_policy_compliance_status,
            identity_assurance_status,
            operating_country,
            operating_province,
            service_category,
            /* eslint-enable camelcase */
        },
    }) {
        return m('table.BusinessProfile',
            m('tbody',
                m('tr',
                    m('th', 'Service category'),
                    m('td', strings.capitalizeWord(service_category))),
                m('tr',
                    m('th', 'Operating country'),
                    m('td', operating_country.toUpperCase())),
                m('tr',
                    m('th', 'Operating province'),
                    m('td', operating_province.toUpperCase())),
                m('tr',
                    m('th', 'Civil policy compliance status'),
                    m('td', civil_policy_compliance_status)),
                m('tr',
                    m('th', 'Criminal policy compliance status'),
                    m('td', criminal_policy_compliance_status)),
                m('tr',
                    m('th', 'Identity assurance status'),
                    m('td', identity_assurance_status))));
    },
};

export const EvidentDate = {
    view({
        attrs,
    }) {
        return dateObjectToLocaleString(attrs, 'en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    },
};

export const DateRange = {
    view({
        attrs: {
            start,
            end,
            format,
            ifMissing = '?',
        },
    }) {
        return m('.DateRange',
            m('span.DateRange__start',
                (start)
                    ? dateFormat(start, format)
                    : ifMissing),
            m.trust('&ndash;'),
            m('span.DateRange__end',
                (end)
                    ? dateFormat(end, format)
                    : ifMissing));
    },
};

export const Degree = {
    view({
        attrs: {
            degree: {
                attendance_end_date: endDate,
                attendance_start_date: startDate,
                degree_award_date: awardDate,
                degree_field_of_study: fields,
                degree_title: title,
                school_name: school,
            },
        },
    }) {
        return m('.Degree',
            m('.Degree__where',
                `${title}, ${school}`),
            m('.Degree__status',
                m('.Degree__graduated',
                    (awardDate)
                        ? `Awarded on ${dateFormat(dateObjectToJsDate(awardDate), 'mmm yyyy')}`
                        : 'Did not graduate'),
                m('.Degree__enrolledRange',
                    m(DateRange, {
                        start: (startDate) ? dateObjectToJsDate(startDate) : null,
                        end: (endDate) ? dateObjectToJsDate(endDate) : null,
                        format: 'mmm yyyy',
                    }))),
            m('.Degree__fields',
                'Fields of study: ',
                (fields || []).slice(0).sort((a, b) => a.localeCompare(b)).join(', ')));
    },
};

export const DegreeList = {
    view({
        attrs: {
            degrees,
        },
    }) {
        return m('.DegreeList',
            degrees
                .sort(({ degree_award_date: a }, { degree_award_date: b }) =>
                    dateObjectToJsDate(a) < dateObjectToJsDate(b))
                .map((degree, key) =>
                    m(Degree, { degree, key })));
    },
};

export const Jurisdiction = {
    view({
        attrs: {
            county,
            state,
            country,
            fips_number: fn,
        },
    }) {
        return m('span.Jurisdiction',
            `${(fn) ? `[${fn}] ` : ''}(${county}), ${state}, ${country}`);
    },
};

const CriminalReportCaseCharge = {
    view({
        attrs: {
            disposition,
            offense_severity: classification,
            sentence,
            specific_offense: charge,
        },
    }) {
        return m('table.CriminalReportCaseCharge', { cellspacing: 0 },
            m('tbody',
                m('tr',
                    m('th', 'Charge:'),
                    m('td', charge)),
                m('tr',
                    m('th', 'Classification:'),
                    m('td', classification)),
                m('tr',
                    m('th', 'Disposition:'),
                    m('td', disposition)),
                m('tr',
                    m('th', 'Sentence:'),
                    m('td', (sentence && sentence.description)
                        ? sentence.description
                        : ''))));
    },
};

const CriminalReportCaseChargeList = {
    view({
        attrs: {
            offenses,
        },
    }) {
        return m('.CriminalReportCaseChargeList',
            m('h4', `${offenses.length} charges for this case`),
            offenses.map((charge, key) => m(CriminalReportCaseCharge, Object.assign({ key }, charge))));
    },
};

const CriminalReportCaseDetails = {
    btx: bem`CriminalReportCaseDetails`,
    view({
        attrs: {
            caseNumber,
            fileDate,
            dispositionDate,
            jurisdiction,
        },
    }) {
        const { block } = CriminalReportCaseDetails.btx;

        return m(block,
            m('h4', 'Case details'),
            m('table',
                m('tbody',
                    m('tr',
                        m('th', 'Case Number:'),
                        m('td', caseNumber)),
                    m('tr',
                        m('th', 'File Date:'),
                        m('td', (fileDate) && m(EvidentDate, fileDate))),
                    m('tr',
                        m('th', 'Disposition Date:'),
                        m('td', (dispositionDate) && m(EvidentDate, dispositionDate))),
                    m('tr',
                        m('th', 'Jurisdiction:'),
                        m('td', jurisdiction)))));
    },
};

export const CapturedDocument = {
    makeViewModel: ({
        $window,
        value,
    }) => {
        const iface = {};
        iface.selectedPage = 0;

        iface.prevPage = function prevPage() {
            iface.selectedPage = ((iface.selectedPage - 1) + iface.pages.length) % iface.pages.length;
        };

        iface.nextPage = function prevPage() {
            iface.selectedPage = ((iface.selectedPage + 1) + iface.pages.length) % iface.pages.length;
        };

        iface.downloadAll = function downloadAll() {
            iface.zipping = true;
            const title = value.title || 'Untitled';

            const zip = new JSZip();

            let i = 1;
            for (const { mime_type: mimeType, dataUrl } of iface.pages) {
                // e.g. 'image/svg+xml' -> ['image', 'svg+xml'] -> 'svg+xml' -> ['svg', 'xml'] -> 'svg'
                const ext = mimeType.split('/').pop().split(/\W/g).shift();

                zip.file(
                    `${title}-page-${i++}.${ext}`,
                    dataUrl.split(',').pop(),
                    { base64: true });
            }

            zip.generateAsync({ type: 'base64' }).then((base64) => {
                $window.location.href = `data:application/zip;base64,${base64}`;
                iface.zipping = false;
                m.redraw();
            });
        };

        iface.pages = value.content.map((DocumentPage) => {
            return Object.assign({
                dataUrl: binaryDataToDataUrl(DocumentPage.content),
            }, DocumentPage.content);
        });

        return iface;
    },
    view({
        attrs: {
            zipping,
            selectedPage,
            pages,
            prevPage,
            downloadAll,
            nextPage,
        },
    }) {
        return m('.CapturedDocument',
            (pages.length === 0)
                ? m('p', 'There are no pages in the attached document')
                : m.fragment({}, [
                    m('.CapturedDocument__toolbar',
                        m(Button, { onpress: prevPage },
                            m(Named.Icon, { name: 'chevron-left' })),
                        m('.CapturedDocument__location',
                            `File ${selectedPage + 1} of ${pages.length}`),
                        (zipping)
                            ? m(Named.Icon, { name: 'download' })
                            : m('.CapturedDocument__download',
                                m(Button, { onpress: downloadAll },
                                    m(Named.Icon, { name: 'download' }))),
                        m(Button, { onpress: nextPage },
                            m(Named.Icon, { name: 'chevron-right' }))),
                    m('.CapturedDocument__page',
                        m(BinaryData, {
                            bin: pages[selectedPage],
                        })),
                ]));
    },
};


export const CriminalReport = {
    view({
        attrs: {
            value: cases,
        },
    }) {
        if (!Array.isArray(cases)) {
            return null;
        }

        return m('.CriminalReport',
            cases.map((c, key) => m.fragment({ key }, [
                m(CriminalReportCaseDetails, {
                    caseNumber: c.offenses[0].case_number,
                    fileDate: c.offenses[0].case_filing_date,
                    dispositionDate: pickLatestDispositionDate(c.offenses),
                    jurisdiction: c.jurisdiction,
                }),
                m(CriminalReportCaseChargeList, c),
            ])));
    },
};
