import { m, spa } from '#/browser-framework';
import { WithLeftNav } from '../../Layouts';
import { withSelectedSetting } from '../../../core/routing/routeHandlerDecorators';
import NotFoundIcon from '#/rp-facing/images/error_not-found.svg';

export const PageNotFound = {
    view() {
        return m('.PageNotFound',
            m('img.PageNotFound__icon', {
                src: NotFoundIcon,
            }),
            m('.PageNotFound__description',
                m('h2.PageNotFound__summary', 'The page you\'re looking for could not be found'),
                m('p',
                    'Looks like something went wrong on our end. If you\'re seeing this page repeatedly,',
                    m('br'),
                    'please',
                    m('a.PageNotFound__url', {
                        href: 'mailto:support@evidentid.com',
                    },
                    'get in touch with us'),
                    'and let us know what happened.'),
            ),
        );
    },
};

export default withSelectedSetting(() => {
    spa.setView(PageNotFound, {
        layout: WithLeftNav,
        vars: {
            extraClass: 'PageNotFoundLayout',
        },
    });
}, {
    pendingLayout: WithLeftNav,
});
