import { m, harness, strings } from '#/browser-framework';


export default {
    view({attrs: stagedRequest}) {
        return m('.RequestMessageForm', {onsubmit: (e) => e.preventDefault()},
            m('.RequestMessageForm__header',
                m('label',
                    'Send Information Request to:',
                    m('input.RequestMessageForm__input[type=email]', harness('create-request-form-input-email', {
                        id: 'create-rpr-email',
                        oninput: ({target: {value}}) => {
                            stagedRequest.recipient = value;
                        },
                        value: stagedRequest.recipient,
                    }))),
                m('label[for="rpr-summary"]',
                    'Summary / Type (ex: Background Check, Insurance Verification, etc):',
                    m('input.RequestMessageForm__input[type=text]', harness('create-request-form-input-summary', {
                        id: 'create-rpr-summary',
                        oninput: ({target: {value}}) => {
                            stagedRequest.summary = value;
                        },
                        value: stagedRequest.summary,
                    })),
                    m('div.RequestMessageForm__subjectPreview',
                        'This will show in email subject lines as ',
                        m.trust('&ldquo;'),
                        m('em',
                            'Action Required to Complete Your ',
                            m('strong', strings.ellipsis(stagedRequest.summary, 50) || '...')),
                        m.trust('&rdquo;'))),
            ),
            m('.RequestMessageForm__body',
                m('label[for="rpr-message"]',
                    'Information Request Message:',
                    m('textarea#create-rpr-message[name=rpr-message]', harness('create-request-form-input-message', {
                        oninput: ({target: {value}}) => {
                            stagedRequest.description = value;
                        },
                        value: stagedRequest.description,
                    })))
            ));
    },
};
