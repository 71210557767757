import { spa } from '#/browser-framework';
import { submitWebAttributes } from '#/ido-lib/idoWebClient';

import { CONTENT_HASH } from '#/rp-facing/views/pages/Onboarding/TermsAndConditionsLanguage';

export function termsFormModel(request, { user }) {
    const iface = {};

    const attrType = 'business.evidentid_self_service.tc_acceptance';

    iface.maySubmit = () =>
        iface.accepted;

    iface.submit = () =>
        submitWebAttributes({
            [attrType]: {
                shareWith: request.input[attrType].shareWith,
                value: {
                    $objectType: 'UserConsent',
                    consentContext: 'evidentRpTermsAndConditions',
                    contentHash: CONTENT_HASH,
                    accepted: true,
                    signer: user.email,
                    signedAt: Math.floor(Date.now() / 1000),
                },
            },
        }).then(() => {
            request.input[attrType].complete = true;

            spa.redraw();
        });

    return iface;
}
