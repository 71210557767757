import { m, tx, expose } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';


const alphabetizeGroups = (a, b) => {
    return a[0].localeCompare(b[0]);
};

const alphabetizeAttrsInGroup = (a, b) => {
    return a.name.localeCompare(b.name);
};

/*
A "sprawl" is a heading followed by chips for all attributes in
a single attribute group. It looks like this:

GROUP NAME

[----- +] [--------------- +]
[--- +] [--- +] [--- +] [- +]
[------------ +] [-------- +]
[----- +] [----- +] [----- +]

*/
const AttributeSprawl = {
    view({attrs: {category, select}}) {
        const children = (category)
            ? Array.from(category.groups()).sort(alphabetizeGroups).map(([name, group]) => {
                return m('section.AttributeSprawl__group',
                    m('h3.AttributeSprawl__label', name),
                    m('div.AttributeSprawl__chips', group.sort(alphabetizeAttrsInGroup).map((attr) => {
                        return m(pointable(`span.SprawlChip${tx({selected: attr.selected})}`), {
                            title: attr.path,
                            onpointerup: () => select(attr.path, !attr.selected),
                        },
                        m('.SprawlChip__name', attr.name),
                        m('span.SprawlChip__icon',
                            m(Named.Icon, {name: (attr.selected) ? 'minus' : 'plus'})));
                    })));
            })
            : m('p', 'Select a category on the left to build your request.');

        return m('.AttributeSprawl', children);
    },
};

/*
This looks like:


[ ● 📂 Category Name      94]

You click these to select a category and break it down into groups
using an AttributeSprawl.
*/
const AttributeTreeCategory = {
    view({attrs: {
        attrCount,
        containsSelectedAttrs,
        selectCategory,
        open,
        label,
    }}) {
        const onpointerup = () => selectCategory(label);
        const comp = tx('AttributeTreeCategory', {
            hasSelected: containsSelectedAttrs,
            open,
        });

        const visibility = (containsSelectedAttrs)
            ? 'visible'
            : 'hidden';

        return m(pointable(comp), {onpointerup},
            m('i.AttributeTreeCategory__circle', {style: {visibility}}, '●'),
            m('span.AttributeTreeCategory__folder',
                m(Named.Icon, {name: `folder${(open) ? '-open' : ''}`})),
            m('span.AttributeTreeCategory__name', label),
            m('em.AttributeTreeCategory__count', attrCount));
    },
};

/*
This looks like:
------------------------------

Available Attributes

[ ● 📂  Category A        23]
[   📂  Category B       117]
[ ● 📂  Category C         9]
[   📂  Category D        35]
[   📂  Category E         2]
[ ● 📂  Category F        42]
[   📂  Category G        78]
*/
const AttributeTree = {
    view({attrs: {selectedCategory, selectCategory, categories}}) {
        return m('.AttributeTree',
            m('h2.AttributeTree__heading', 'Available Attributes'),
            ...Array.from(categories()).sort(([a], [b]) => a.localeCompare(b)).map(([label, cat]) => m(AttributeTreeCategory, {
                selectCategory,
                label,
                open: label === selectedCategory,
                containsSelectedAttrs: cat.selectedCount > 0,
                attrCount: cat.attrCount,
            })));
    },
};

const HiddenAttributeList = {
    view({attrs: { archive }}) {
        return Array.from(archive.attributes()).map((attrObject) => {
            const onclick = () => archive.selectAttribute(attrObject.path, !attrObject.selected);
            return m('div', expose({ onclick }, { 'attribute-type': attrObject.path }));
        });
    },
};

const AttributeSelector = {
    view({attrs: {archive}}) {
        const testingEnabled = Boolean(deploy.WEB_PUBLIC_EVALUATING);
        return m('.AttributeSelector',
            m(AttributeTree, archive),
            m(AttributeSprawl, {
                category: archive.getCategory(archive.selectedCategory),
                select: archive.selectAttribute,
            }),
            (testingEnabled) ? m(HiddenAttributeList, {archive}) : null);
    },
};

export default AttributeSelector;
