import { userInput } from '#/browser-framework';

const { field, inputlock} = userInput;

export default async function webhookSettings(rpname, submit) {
    const lock = await inputlock({
        async fire() {
            await submit(lock.dehydrate());
        },
        fields: {
            postHooks: field({
                id: 'settings-posthooks',
            }),
        },
    });

    lock.dehydrate = () => {
        const dump = lock.dump();
        return dump;
    };

    lock.hydrate = (s) => lock.load({
        postHooks: s,
    });

    return lock;
}
