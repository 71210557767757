import { m, harness } from '#/browser-framework';
import { fieldBem, formBem } from '#/rp-facing/styles/FormsBem';


function charUsage(limit, str) {
    const numLimit = parseInt(limit, 10);
    const constrained = !isNaN(numLimit);

    return (constrained)
        ? numLimit - str.length
        : Infinity;
}

export default {
    view({attrs: {
        id,
        heading,
        headingLabel,
        messageLabel,
        message,
        headingLimit = Infinity,
        messageLimit = Infinity,
        changeHeading,
        changeMessage,
    }}) {
        const hUsage = charUsage(headingLimit, heading);
        const mUsage = charUsage(messageLimit, message);

        return m('.HeadingAndMessageForm',
            m(formBem.block,
                m(formBem.fieldset,
                    m(formBem.field,
                        m(`label.${fieldBem.label}`, {for: `${id}-heading`}, headingLabel),
                        m(`input.${fieldBem.input}`, harness(`${id}-heading`, {
                            id: `${id}-heading`,
                            value: heading,
                            oninput: (e) => {
                                if (charUsage(headingLimit, e.target.value) < 0) {
                                    return;
                                }
                                changeHeading(e.target.value);
                            },
                        })),
                        hUsage !== Infinity && m('span', `${hUsage} characters left`),
                    ))),
            m(formBem.fieldset,
                m(formBem.field,
                    m(`label.${fieldBem.label}`, {for: `${id}-heading`}, messageLabel),
                    m(`textarea.${fieldBem.input}`, harness(`${id}-message`, {
                        id: `${id}-message`,
                        value: message,
                        oninput: (e) => {
                            if (charUsage(messageLimit, e.target.value) < 0) {
                                return;
                            }

                            changeMessage(e.target.value);
                        },
                    })),
                    mUsage !== Infinity && m('span', `${mUsage} characters left`),
                )));
    },
};
