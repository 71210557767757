import { m, spa, events, router } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';
import { ExtPromise } from '#/universal-framework/async';

export const LeaveAdminView = {
    opened: false,
    routePreloader: Promise.resolve(),
    settings: {},
    close() {
        this.opened = false;
        router.rejectPendingRoute();
    },
    oninit() {
        this.handler = () => {
            if (this.settings.unsaved) {
                this.routePreloader = new ExtPromise();
                router.addRoutePreloader(this.routePreloader);
                this.opened = true;
            }
            spa.redraw();
        };
        events.on('route-change', this.handler);
    },
    onremove() {
        events.removeListener(this.handler);
    },
    discardChanges() {
        this.settings.restore();
        this.routePreloader.resolve();
        this.opened = false;
    },
    view({ attrs: { settings } }) {
        this.settings = settings;
        if (!this.opened) {
            return null;
        }

        return [
            m('div.LeaveAdminPrompt__mask', {
                onclick: () => this.close(),
            }),
            m('div.LeaveAdminPrompt__modal', {
                onclick: () => this.close(),
            }, m('div.LeaveAdminPrompt__modal-body',
                m('div.LeaveAdminPrompt__title',
                    'Your changes on this page have',
                    m('br'),
                    'not been saved yet'
                ),
                m('p.LeaveAdminPrompt__text',
                    'Are you sure you want to navigate away from page',
                    m('br'),
                    'without saving? Any changes you\'ve made will be discarded if',
                    m('br'),
                    'you choose not to save them',
                    m('br')
                ),
                m('div.LeaveAdminPrompt__buttons',
                    m(Button, {
                        flags: {
                            secondary: true,
                        },
                        onpress: () => this.discardChanges(),
                    }, 'Discard changes'),
                    m(Button, {
                        flags: {
                            primary: true,
                        },
                        onpress: () => this.close(),
                    }, 'Save my changes first')))),
        ];
    },
};
