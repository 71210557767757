import { errors, m, spa } from '#/browser-framework';
import { Named } from '#/browser-framework/comps';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';
import { AccessDeniedErrors } from '../../../state/errors';
import { AccessDenied } from './AccessDenied';

function getDisplayMessages(error) {
    const hasSummary = error
        && error.extra
        && error.extra.displaySummary
        && error.extra.displayMessage;
    return hasSummary
        ? error.extra
        : errors.Unexpected().extra;
}

const ErrorComponent = {
    view({
        attrs: {
            error,
        },
    }) {
        const {
            displaySummary,
            displayMessage,
        } = getDisplayMessages(error);

        return m('.ErrorPage',
            m('.ErrorPage__icon',
                m(Named.Icon, { name: 'alert' })),
            m('h2.ErrorPage__summary', displaySummary),
            m('p', displayMessage));
    },
};

function checkForDeniedPage({ reason }) {
    const isDeniedError = AccessDeniedErrors
        .find((err) => err.reason === reason);
    return isDeniedError ? AccessDenied : ErrorComponent;
}

export const ErrorView = {
    view({
        attrs: {
            error,
        },
    }) {
        spa.error = errors.lookup(error.reason)() || errors.Unexpected();
        const view = checkForDeniedPage(error) || ErrorComponent;

        spa.setView(view, {
            layout: RestrictedLayout,
        });
    },
};
