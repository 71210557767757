import { submitWebAttributes, sendConsent } from '#/ido-lib/idoWebClient';


export function packageSelectionFormModel(request) {
    const iface = {
        additionalInfo: '',
        selectedServices: {},
        isSubmitting: false,
    };

    const pkgSelectionAttrType = 'business.evidentid_self_service.package_selection';

    iface.maySubmit = () =>
        iface.accepted;

    iface.submit = (selection, additionalInfo = '', services = []) => {
        const shareWith = request.input[pkgSelectionAttrType].shareWith;
        iface.isSubmitting = true;
        return submitWebAttributes({
            [pkgSelectionAttrType]: {
                shareWith,
                value: selection,
            },
            'business.evidentid_self_service.additional_information': {
                shareWith,
                value: additionalInfo,
            },
            'business.evidentid_self_service.verification_services': {
                shareWith,
                value: services,
            },
        }).then(() => {
            request.input[pkgSelectionAttrType].complete = true;

            return sendConsent(request.id);
        }).finally(() => {
            iface.isSubmitting = false;
        });
    };

    return iface;
}
