import { router } from '#/browser-framework';
import { ROUTE_TABLE } from '#/rp-facing/core/routing/routeTable';
import { PageNotFound } from '#/rp-facing/views/pages/ErrorPages/404';

export function startRouting() {
    for (const { title, route, handler } of ROUTE_TABLE) {
        router.on(title, route, handler);
    }

    router.registerFallback(
        '404',
        PageNotFound);

    router.start({
        titleBrand: 'Evident',
        titleSeparator: ' - ',
        fallback() {
            router.go('/');
        },
    });
}
