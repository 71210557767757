import { m, spa } from '#/browser-framework';

import { WithLeftNav, AdminLayout } from '#/rp-facing/views/Layouts';
import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';
import HeadingAndMessageForm from '#/rp-facing/views/HeadingAndMessageForm';


const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        const { fields } = App.user.selectedRp.settings.confirmationMessage;

        return m(AdminLayout, { App, subtitle: 'Confirmation Message' },
            m(HeadingAndMessageForm, {
                id: 'confirmation',
                headingLabel: 'Confirmation Headline:',
                messageLabel: 'Confirmation Message:',
                headingLimit: 100,
                messageLimit: 1000,
                heading: fields.headingText.value,
                message: fields.messageText.value,
                changeHeading: (v) => {
                    App.user.selectedRp.settings.saveHistory();
                    fields.headingText.value = v;
                },
                changeMessage: (v) => {
                    App.user.selectedRp.settings.saveHistory();
                    fields.messageText.value = v;
                },
            }));
    },
};

export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    pendingLayout: WithLeftNav,
    settingName: 'confirmationMessage',
});
