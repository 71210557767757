import { m, tx } from '#/browser-framework';
import { pointable } from '#/browser-framework/comps';


export default {
    makeViewModel({
        options,
        onSelectionChanged,
    }) {
        const iface = {
            options,
            value: 0,
            getSelectedLabel: () =>
                iface.options[iface.value],
            select(index) {
                iface.value = index;
                onSelectionChanged(index, iface.options[index]);
            },
        };

        return iface;
    },
    view({attrs: {options, select, value}}) {
        return m('.ComboButton',
            options.map((label, index) => {
                return m(pointable(tx({selected: value === index})), {
                    key: index,
                    onpointerup: () => select(index),
                }, label);
            }));
    },
};
