import { harness, m, userInput, spa, bem } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';
import { woopraTrackRegistration, woopraTrackEmailVerification} from '#/browser-framework/woopraLib';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';
import { computePricingLink } from '#/rp-facing/core/salesInteractor';

import { GoogleButton, FacebookButton } from './Sso';


const { enterToSubmit, textFieldBehavior } = userInput;

const RegistrationFormBtx = bem`
    RegistrationForm
        error
        idoInstructions
        row
        heading
        body`;

const RegistrationPageBtx = bem`
    RegistrationPage
        heading
        header
        body
        form`;


const EmailPasswordForm = {
    view({
        attrs: {
            registration,
        },
    }) {
        const { error, fields: { email, password, repeat } } = registration;

        const ets = enterToSubmit(registration);

        return m(RegistrationFormBtx.block,
            error && m(RegistrationFormBtx.error, harness('rp-facing-registration-error-message'),
                error.message),
            m(`label[for=${email.id}]`, 'Email address:'),
            m('input.TextField.spread', harness('email-field',
                ets(textFieldBehavior(email, { type: 'email', placeholder: 'email@example.com' })))),
            email.errorText && m('p.fg-danger', email.errorText),
            m(`label[for=${password.id}]`, 'Password (12+ characters):'),
            m('input.TextField.spread', harness('password-field',
                ets(textFieldBehavior(password, { type: 'password', placeholder: 'Password' })))),
            password.errorText && m('p.fg-danger', password.errorText),
            m(`label[for=${repeat.id}]`, 'Repeat password:'),
            m('input.TextField.spread', harness('password-field',
                ets(textFieldBehavior(repeat, { type: 'password', placeholder: 'Repeat password' })))),
            repeat.errorText && m('p.fg-danger', repeat.errorText),
            m(Button, harness('submit-button', {
                flags: {
                    primary: true,
                    round: true,
                },
                disabled: !registration.maySubmit,
                onpress: () => {
                    woopraTrackEmailVerification(true, false);
                    woopraTrackRegistration(email.value);
                    return registration.submit();
                },
            }), 'Create Account'));
    },
};


const RegistrationForm = {
    view({
        attrs: {
            viewModel: rpfacing,
        },
    }) {
        const { user: { registration } } = rpfacing;

        return m(RegistrationPageBtx.block,
            m(`header${RegistrationPageBtx.header}`,
                m(`h2${RegistrationPageBtx.heading}`,
                    'Create your Evident business account'),
                m('p',
                    'Start running verifications in minutes with your Evident account. ' +
                    'Need help? ', m('a', {
                        href: computePricingLink(),
                    }, 'Talk to our sales team.'))),
            m(RegistrationPageBtx.body,
                m(`h2${RegistrationFormBtx.heading}`,
                    'New account with single sign-on (SSO)'),
                m('p', 'or'),
                m(`h2${RegistrationFormBtx.heading}`,
                    'New account with email login'),
                m(RegistrationFormBtx.block,
                    m(GoogleButton),
                    m(FacebookButton),
                    m(`a${RegistrationFormBtx.idoInstructions}`, {
                        href: 'https://www.evidentid.com/individual-verification/',
                    }, 'I need to verify my information with a business.')),
                m('div'),
                m(EmailPasswordForm, { registration })),
            m('p',
                'Already have an account? ',
                m('a[href=#!/auth]',
                    harness('rp-facing-native-register-to-login'),
                    'Sign in.')));
    },
};


export default () => {
    spa.viewModel.user.loadFormModels().then(() => {
        spa.setView(RegistrationForm, {
            layout: RestrictedLayout,
        });
    });
};
