import { m, spa } from '#/browser-framework';
import { RichTextEditor, Spinner } from '#/browser-framework/comps';

import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';
import { AdminLayout, WithLeftNav } from '#/rp-facing/views/Layouts';
import TabbedView from '#/rp-facing/views/TabbedView';
import LabeledToggle from '#/rp-facing/views/LabeledToggle';



const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        if (!App.user.selectedRp) {
            return m(Spinner);
        }

        const { settings } = App.user.selectedRp;

        return m(AdminLayout, {App, subtitle: 'Adverse Action Notification Message'},
            m(LabeledToggle, {
                id: 'auto-adverse',
                checked: settings.adverseActionNotification.automatic,
                onchange: (state) => {
                    settings.adverseActionNotification.automatic = state;
                },
            }, 'Send automatic messages as a result of pre-adverse or adverse actions:'),
            m(TabbedView, {
                labels: [
                    'Pre-Adverse Action Notification Message',
                    'Adverse Action Notification Message',
                ],
            },
            m(RichTextEditor, {
                id: 'pre-adverse-action',
                onchange: (html) => {
                    settings.adverseActionNotification.preAdverseActionHtml = html;
                },
            }, m.trust(settings.adverseActionNotification.preAdverseActionHtml)),
            m(RichTextEditor, {
                id: 'adverse-action',
                onchange: (html) => {
                    settings.adverseActionNotification.adverseActionHtml = html;
                },
            }, m.trust(settings.adverseActionNotification.adverseActionHtml))));
    },
};

export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    settingName: 'adverseActionNotification',
});
