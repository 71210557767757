import { m, bem, harness, router, spa } from '#/browser-framework';
import { Button, Overlay, Spinner, Snackbar } from '#/browser-framework/comps';
import EvidentBrand from '#/browser-framework/images/evident-wordmark-dark.svg';

import TopLevelNav from '#/rp-facing/views/TopLevelNav';
import SessionControls from '#/rp-facing/views/SessionControls';
import { LeaveAdminView } from './pages/LeavingAdmin';

const Dialog = {
    view({children}) {
        return m(Overlay, children);
    },
};

export const PendingPage = {
    view() {
        return m(Overlay, m(Spinner));
    },
};

export const AppToolbar = {
    view({
        attrs: App,
    }) {
        const here = router.here();
        const shouldLinkToHome = (
            here === '/' ||
            here === '/auth' ||
            here === '/register'
        );

        return m('.AppToolbar',
            m('img.AppToolbar__brand', {
                'style': 'cursor:pointer',
                src: EvidentBrand,
                onclick: () => {
                    if (shouldLinkToHome) {
                        spa.$window.location.assign('https://www.evidentid.com');
                    } else {
                        router.go('/');
                    }
                },
            }),
            m('.AppToolbar__controls',
                (App.user && !App.user.isGuest()) && m(SessionControls, App)));
    },
};

export const SubToolbar = {
    view({attrs: {subtitle}, children}) {
        return m('.AdminLayout__toolbar.TopToolbar',
            m('span.Subtitle', subtitle),
            children);
    },
};

export const MobileLayout = {
    view({
        children,
    }) {
        return m('.MobileLayout', children);
    },
};

export const WithLeftNav = {
    view(args) {
        const {
            attrs: {
                viewModel: App,
                vars: { extraClass },
            },
            children,
        } = args;
        return m('.WithLeftNav', { class: extraClass ? extraClass : '' },
            m('.WithLeftNav__top', m(AppToolbar, App)),
            m('.WithLeftNav__bottom',
                m('.WithLeftNav__nav',
                    m(TopLevelNav, {
                        user: App.user,
                        docsEnabled: App.docsEnabled,
                        pendingRoute: router.here(),
                    })),
                m('.WithLeftNav__content', (children.length === 0) ? m(Spinner) : children),
                (App.dialog) && m(Dialog, m(App.dialog, { App })),
                m(Snackbar, App.snackbar)));
    },
};

export const ScheduleCallMeronomy = bem`
ScheduleCall
    heading
    subHeading
    form
    graphic
    nextStepHeading
    instructions
    `;


export const AdminLayout = {
    view({attrs: {App, subtitle}, children}) {
        const hasHistory = App.user.selectedRp.settings.previousState;
        return [
            m(LeaveAdminView, {
                settings: App.user.selectedRp.settings,
            }),
            m('.AdminLayout__toolbar.TopToolbar',
                m('span.Subtitle', subtitle),
                (App.user.selectedRp && App.user.selectedRp.focusedSetting) && m('.AdminLayout__controls',
                    m(Button, harness('administration-save-settings-button', {
                        flags: {
                            abort: true,
                        },
                        disabled: !hasHistory,
                        onpress() {
                            App.user.selectedRp.settings.restore();
                        },
                    }), 'Cancel'),
                    m(Button, harness('administration-save-settings-button', {
                        flags: {
                            primary: true,
                        },
                        disabled: !App.user.selectedRp.focusedSetting.maySubmit || !hasHistory,
                        onpress() {
                            App.user.selectedRp.focusedSetting
                                .submit()
                                .then(() => {
                                    App.snackbar.display('Saved! It may take a couple of minutes for changes to apply.', true);
                                    App.user.selectedRp.settings.clearHistory();
                                })
                                .catch(() => {
                                    App.snackbar.display('There was a problem saving your settings. Try again in a few minutes.', false);
                                });
                        },
                    }), 'Save Settings'))),
            m('.AdminLayout', children),
        ];
    },
};


export const RestrictedLayout = {
    view({
        attrs: {
            viewModel: App,
        },
        children,
    }) {
        return m('.RestrictedLayout',
            m(AppToolbar, App),
            (children.length === 0)
                ? m(PendingPage)
                : children,
            App.dialog && m(Dialog, m(App.dialog, { App })));
    },
};
