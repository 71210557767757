import { m, spa } from '#/browser-framework';
import { caseful, Spinner } from '#/browser-framework/comps';


// HOC used to render `comp` ONLY when a selected RP is available in memory.
export function RpView(comp) {
    const WithRp = Object.assign({}, comp, {
        view({
            attrs,
            children,
        }) {
            return m(comp, attrs, children);
        },
    });

    return caseful(WithRp, Spinner, () =>
        spa.viewModel.user.selectedRp);
}
