import { submitWebAttributes } from '#/ido-lib/idoWebClient';


export function onboardPhoneNumberFormModel(request) {
    const iface = {};
    const { shareWith } = request.input['identity.phonenumber'];

    iface.value = '';

    iface.change = (data) =>
        Object.assign(iface, data);

    iface.maySubmit = () =>
        Boolean(iface.valid);

    iface.submit = () => {
        const { countryCode, value } = iface;
        const digits = value.replace(/\D/g, '');

        return submitWebAttributes({
            'identity.phonenumber': {
                shareWith,
                value: {
                    $objectType: 'PhoneNumber',
                    country_code: countryCode,
                    area_code: digits.substring(0, 3),
                    prefix: digits.substring(3, 6),
                    line_number: digits.substring(6, 10),
                },
            },
        });
    };

    return iface;
}
