const PlanSelectionServices = [
    'Motor Vehicle Records',
    'Employment Verification',
    'Identity Verification',
    'Education Verification',
    'Criminal Background / Watchlist',
    'Flight & Watercraft Licenses',
    'Professional Contractor Licenses',
    'First Aid & CPR',
    'Address Verification',
    'Business Entity Verifications',
    'Insurance Verification',
    'Other',
    'Civil Background',
];

export default PlanSelectionServices;
