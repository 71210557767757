import { m, spa } from '#/browser-framework';

import { WithLeftNav, AdminLayout } from '#/rp-facing/views/Layouts';
import HeadingAndMessageForm from '#/rp-facing/views/HeadingAndMessageForm';
import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';


const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        const {fields} = App.user.selectedRp.settings.linkExpirationMessage;

        return m(AdminLayout, {App, subtitle: 'Link Expiration'},
            m(HeadingAndMessageForm, {
                id: 'link-expiration',
                headingLabel: 'Expiration Headline:',
                messageLabel: 'Expiration Message:',
                headingLimit: 100,
                messageLimit: 1000,
                heading: fields.headingText.value,
                message: fields.messageText.value,
                changeHeading: (v) => {
                    App.user.selectedRp.settings.saveHistory();
                    fields.headingText.value = v;
                },
                changeMessage: (v) => {
                    App.user.selectedRp.settings.saveHistory();
                    fields.messageText.value = v;
                },
            }));
    },
};


export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    pendingLayout: WithLeftNav,
    settingName: 'linkExpirationMessage',
});
