import { logger, spa, router } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';
import { getUserIdToken } from '#/browser-framework/firebase';


// Captures error state where router is started before view model was initialized.
export const appReady = (f) => (...a) => ((spa.viewModel)
    ? f(...a)
    : (() => {
        logger.error('router.start() called when view model does not exist.');
    })());

// Allows only users that are logged in
export const loggedInOnly = (f) => appReady((...a) => ((spa.viewModel.user.isGuest())
    ? router.go('/auth', { replace: true })
    : f(...a)));

// Allows only users that are not logged in
export const loggedOutOnly = (f) => appReady((...a) => ((spa.viewModel.user.isGuest())
    ? f(...a)
    : spa.viewModel.goToLandingPage()));

// Allows only users that are logged in and have a verified email
export const verifiedEmailOnly = (f) => loggedInOnly((...a) =>
    ((spa.viewModel.user.hasVerifiedEmail())
        ? f(...a)
        : spa.viewModel.goToLandingPage()));

// Allows only users that are logged in and have NOT verified their email.
export const unverifiedEmailOnly = (f) => loggedInOnly((...a) =>
    ((spa.viewModel.user.hasVerifiedEmail())
        ? spa.viewModel.goToLandingPage()
        : f(...a)));

// Allows only users that are considered done onboarding.
export const onboardedOnly = (f) => loggedInOnly((...a) =>
    ((spa.viewModel.user.isDoneOnboarding())
        ? f(...a)
        : spa.viewModel.goToLandingPage()));

// Allows only users that are NOT done onboarding.
export const notOnboardedOnly = (f) => loggedInOnly((...a) =>
    ((spa.viewModel.user.isDoneOnboarding())
        ? spa.viewModel.goToLandingPage()
        : f(...a)));

export const docsEnabled = (f) => (...a) =>
    ((spa.viewModel.docsEnabled)
        ? f(...a)
        : spa.viewModel.goToLandingPage());

export const withFreshUserIdToken = (f) => (...a) =>
    getUserIdToken(true)
        .then(() => f(...a))
        .catch((e) => {
            spa.error = e;
            logger.error(e);

            return f(...a);
        });

/*
This decorator is for routes that use `:rpname` as the first positional parameter.
This transforms the argument at that position into a loaded SubjectiveRelyingParty
instance while controlling visual feedback during any waiting period. Use to control
common transitional concerns for routes that operate on an RP.
*/
export const ensureRpSelection = (f, { pendingLayout, requireTerms = true } = {}) =>
    (...args) => {
        spa.setView(Spinner, { layout: pendingLayout });

        return spa.viewModel.busySection(() =>
            ((spa.viewModel.user.dominion)
                ? spa.viewModel.user.dominion.selectRp(args[0][0])
                    .then((rp) => {
                        // This allows a route handler author to operate on the RP view-model
                        args[0][0] = rp;

                        return (requireTerms)
                            ? (!rp.settings.tcSigned && !/\.?evidentid.com$/.test(spa.viewModel.user.email))
                                ? router.go(`/${rp.name}/terms`, { replace: true })
                                : f(...args)
                            : f(...args);
                    })
                    .catch((e) => spa.viewModel.showError(e))
                : spa.viewModel.goToLandingPage()));
    };


// Use for handlers dedicated to showing interfae
export const withSelectedSetting = (f, { settingName, pendingLayout, requireTerms = true }) =>
    ensureRpSelection((...a) => {
        const [[rp]] = a;

        rp.focusedSetting = rp.settings[settingName];

        return f(...a);
    }, {
        pendingLayout,
        requireTerms,
    });


// Consumes :anchor route argument to maintain scrollTo behavior
export const scrollToAnchor = (f) =>
    (...a) => {
        const anchor = a[0][a[0].length - 1];
        const WAIT_MAX_MS = 15000;
        let interval;
        let timeout;

        if (anchor) {
            // Poll instead of using MutationObserver for legibility.
            interval = setInterval(() => {
                const node = spa.$window.document.getElementById(anchor);

                if (node) {
                    const { y } = node.getBoundingClientRect();

                    spa.$window.scrollTo(0, y);

                    clearInterval(interval);
                    clearInterval(timeout);
                }
            }, 50);

            timeout = setTimeout(() => clearInterval(interval), WAIT_MAX_MS);
        }

        return f(...a);
    };
