import { m, harness, router, spa } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';
import { CountryDropdown } from '#/ido-lib/forms/IdScan/views/CountryDropdown';

import { onboardingController } from '#/rp-facing/core/onboarding';
import { MobileLayout } from '#/rp-facing/views/Layouts';

import { DelegateScanner } from './DelegateScanner';

const DelegationCountrySelection = {
    view({
        attrs: {
            viewModel: {
                onboarding: {
                    idScanFormModel,
                },
            },
        },
    }) {
        return m(DelegateScanner.block,
            m(DelegateScanner.switch,
                'Where is your ID from?'),
            m(DelegateScanner.form,
                m(CountryDropdown, {
                    q: idScanFormModel,
                })),
            m(DelegateScanner.controls,
                m(DelegateScanner.action,
                    m(Button, harness('submit', {
                        flags: {
                            primary: true,
                            round: true,
                        },
                        onpress() {
                            router.go('/setup/delegate/duplex');
                        },
                    }), 'Continue'))));
    },
};


export default ([rprId], { canary }) => {
    if (!spa.viewModel.onboarding) {
        spa.viewModel.onboarding = onboardingController();
    }

    spa.viewModel.onboarding.deriveIdoOnboardingState(canary, rprId).then(() => {
        spa.setView(DelegationCountrySelection, {
            layout: MobileLayout,
        });
    });
};
