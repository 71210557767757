function putIntercomBeforeAllScripts($window, appId) {
    const scriptNode = $window.document.createElement('script');
    const firstScript = $window.document.getElementsByTagName('script')[0];

    scriptNode.type = 'text/javascript';
    scriptNode.async = true;
    scriptNode.src = `https://widget.intercom.io/widget/${appId}`;

    firstScript.parentNode.insertBefore(scriptNode, firstScript);
}


// Expanded version of the bootstrapper from the Intercom docs.
export function intercomInstance($window, intercomSettings, {
    beforeLoad = true,
} = {}) {
    if (typeof $window.Intercom === 'function') {
        window.Intercom('reattach_activator');
        window.Intercom('update', intercomSettings);
    } else {
        // Base intercom interface just queues argument lists
        const i = function() {
            i.c(arguments);
        };

        i.q = [];
        i.c = (args) => {
            i.q.push(args);
        };

        $window.Intercom = i;

        const inject = () => putIntercomBeforeAllScripts($window, intercomSettings.app_id);

        if (beforeLoad) {
            if ($window.attachEvent) {
                $window.attachEvent('onload', inject);
            } else {
                $window.addEventListener('load', inject, false);
            }
        } else {
            inject();
        }
    }

    return (...a) => $window.Intercom(...a);
}
