// XHR bindings to rpweb service

import errors from '#/universal-framework/errors';
import { router } from '#/browser-framework';
import { getUserIdToken } from '#/browser-framework/firebase';
import _frameworkXhr from '#/browser-framework/xhr';

// Allows optional mocking via devtools
function _replExchange(prompt, initialVal) {
    return new Promise((resolve) => {
        console.log(prompt);
        console.log('Store your response in `$answer`.');
        console.log('Initial value set in $answer:', initialVal);
        window.$answer = initialVal;

        window.done = () => {
            const val = window.$answer;

            delete window.done;
            delete window.$answer;

            resolve(val);
        };
    });
}

function _handleError(xhrError) {
    if (xhrError.xhrObj.status === 401) {
        router.go('/auth');
    } else {
        const { auth_error: authErr } = xhrError.xhrObj;

        if (authErr) {
            switch (authErr) {
            case 'ApiKeyFailure':
            case 'TokenDomainError':
            case 'TokenSubjectDomainMismatch':
            case 'TokenInvalidDomainSpecified':
            case 'Unknown':
                throw errors.NoAuthService();

            default:
                router.go('/auth');
                break;
            }
        } else {
            throw xhrError;
        }
    }
}

export function rpWebClient() {
    const iface = {};

    // XHR client that authenticates using session information.
    iface.request = (o) => {
        const isTokenNotTrusted = Boolean(o.distrustBearerToken);
        return getUserIdToken(isTokenNotTrusted)
            .then((jwt) => {
                const overridden = o.config || (() => {
                });

                o.config = (xo) => {
                    xo.setRequestHeader('Authorization', `Bearer ${jwt}`);
                    overridden(xo);
                };

                o.url = deploy.WEB_PUBLIC_MICROSERVICE_URL_PREFIX +
                    ((o.rpCommonName) ? `/relyingParties/${o.rpCommonName}${o.url}` : o.url);

                return _frameworkXhr(o).catch(_handleError);
            });
    };

    iface.mock = (o) =>
        _replExchange(`Mock response for ${o.url}`, '');

    iface.requestJson = _frameworkXhr.decorateJsonComms(iface.request);

    // Authenticated CSV client
    iface.requestCsv = _frameworkXhr.extend(iface.request, (o) =>
        Object.assign(o, {
            config(xo) {
                xo.setRequestHeader('Accept', 'text/csv');
            },
        }));

    iface.bindRp = (baseXhr, rpCommonName) => (o) =>
        baseXhr(Object.assign({}, o, { rpCommonName }));

    return iface;
}
