import { m, spa } from '#/browser-framework';
import { RichTextEditor } from '#/browser-framework/comps';

import { WithLeftNav, AdminLayout } from '#/rp-facing/views/Layouts';
import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';


const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        const settings = App.user.selectedRp.settings;
        return m(AdminLayout, {App, subtitle: 'FCRA Disclosure'},
            m(RichTextEditor, {
                id: `${App.user.selectedRp.name}-fcra-disclosure`,
                onchange(html) {
                    settings.saveHistoryAndRedraw(() => {
                        settings.fcraDisclosureHtml.modifiedByUser = true;
                        settings.fcraDisclosureHtml.fields.markup.value = html;
                    });
                },
                getValue() {
                    return {
                        value: settings.fcraDisclosureHtml.fields.markup.value,
                        preventRerender: settings.fcraDisclosureHtml.modifiedByUser,
                    };
                },
            }, m.trust(settings.fcraDisclosureHtml.fields.markup.value)));
    },
};


export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    pendingLayout: WithLeftNav,
    settingName: 'fcraDisclosureHtml',
});
