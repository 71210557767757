import { contract, firstTrue, lets, signature, types } from '#/universal-framework/functions';

/* eslint-disable complexity */

const {
    hopefully,
    string,
} = types;

const maybeString = hopefully(string);

export const formatUsaCityStatePair = contract(
    (city, state) =>
        firstTrue([
            [(city && state), `${city}, ${state}`],
            [(city && !state), ''], // Having only a city is ambiguous
            [(!city && state), state],
        ], ''),
    signature(() => ({
        domain: [maybeString, maybeString],
        range: string,
        displayName: 'formatUsaCityStatePair',
    })));

export const formatUsaStreetAddress = contract(
    (strnum, strname, unit) =>
        firstTrue([
            [(strnum && strname && unit), `${strnum} ${strname} #${unit}`],
            [(strnum && strname && !unit), `${strnum} ${strname}`],
            [(strnum && !strname && unit), ''],
            [(strnum && !strname && !unit), ''],
            [(!strnum && strname && unit), strname],
            [(!strnum && strname && !unit), strname],
            [(!strnum && !strname && unit), ''],
            [(!strnum && !strname && !unit), ''],
        ], ''),
    signature(() => ({
        domain: [maybeString, maybeString, maybeString],
        range: string,
        displayName: 'formatUsaStreetAddress',
    })));


export function addressToString(value) {
    const line1 = (value.post_office_box_id)
        ? `PO BOX ${value.post_office_box_id}`
        : formatUsaStreetAddress(
            value.street_number,
            value.street_name,
            value.street_unit);

    const line2 = (value.neighborhood && !value.postal_code_suffix)
        ? value.neighborhood
        : '';

    let line3 = formatUsaCityStatePair(value.city, value.state);

    if (value.postal_code) {
        if (line3 !== '') {
            line3 += ' ';
        }
        line3 += value.postal_code;
    }
    if (value.postal_code_suffix) {
        if (line3 !== '') {
            line3 += '-';
        }
        line3 += value.postal_code_suffix;
    }

    let line4 = '';
    if (value.county) {
        line4 += `${value.county} county`;
    }
    if (value.country) {
        if (line4 !== '') {
            line4 += ', ';
        }
        line4 += value.country;
    }

    let result = '';
    if (line1 !== '') {
        result += result === '' ? '' : ' / ';
        result += line1;
    }
    if (line2 !== '') {
        result += result === '' ? '' : ' / ';
        result += line2;
    }
    if (line3 !== '') {
        result += result === '' ? '' : ' / ';
        result += line3;
    }
    if (line4 !== '') {
        result += result === '' ? '' : ' / ';
        result += line4;
    }
    return result;
}
/* eslint-enable complexity */

export const googleTypesToAddressKey = (gtypes) => ({
    'street_number': 'street_number',
    'route': 'street_name',
    'postal_code': 'postal_code',
    'postal_code_suffix': 'postal_code_suffix',
    'subpremise': 'street_unit',
    'neighborhood,political': 'neighborhood',
    'locality,political': 'city',
    'administrative_area_level_2,political': 'county',
    'administrative_area_level_1,political': 'state',
    'country,political': 'country',
}[gtypes.join(',')]);

export const googleAddressComponentsToAddressObject = contract(
    (addrComps) =>
        addrComps.reduce(
            (p, c) =>
                lets([googleTypesToAddressKey(c.types)],
                    (key) => ((key)
                        ? Object.assign(p, { [key]: c.short_name })
                        : p)),
            { $objectType: 'Address' }),
    signature(({ and, arrayOf, object, objectOf }) => ({
        domain: [arrayOf(object)],
        range: and(
            objectOf(string),
            (v) => Object.keys(v).indexOf('undefined') === -1),
    })));
