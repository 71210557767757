import { m, strings } from '#/browser-framework';
import FALLBACK_HEADSHOT from '#/browser-framework/images/anon-avatar.svg';
import { Avatar } from '#/browser-framework/comps';

import API_ICON from '#/rp-facing/images/api-icon.svg';

export default {
    view({attrs: {headshot, fullname, bgcolor}}) {
        const vm = {diameter: 36, headshot};

        if (fullname.indexOf(' ') > -1) {
            const [first, last] = Avatar.initialsFromName(fullname);

            vm.firstInitial = first;
            vm.lastInitial = last;
        } else if (!vm.headshot) {
            vm.headshot = (fullname === 'API')
                ? API_ICON
                : FALLBACK_HEADSHOT;
        }

        vm.bgcolor = (vm.headshot)
            ? 'transparent'
            : bgcolor;

        return m('.UserBadge',
            m(Avatar, vm),
            m('span.UserBadge__fullname',
                strings.ellipsis(fullname, 30)));
    },
};
