

import { bem, harness, m, router, spa } from '#/browser-framework';
import { Button, Overlay, Spinner, pointable } from '#/browser-framework/comps';

import { woopraTrackConfirmIdentity, woopraTrackCompleteStep } from '#/browser-framework/woopraLib';


import { WithLeftNav } from '#/rp-facing/views/Layouts';

import { IdScanImageCaptureFront } from '#/ido-lib/views/images';
import { PhoneDelegationInput } from '#/ido-lib/views/PhoneDelegationInput';
import { LargeFormatIdSimplexControl } from '#/ido-lib/forms/IdScan/views/LargeFormatIdSimplexControl';

import { CountryDropdown } from '#/ido-lib/forms/IdScan/views/CountryDropdown';


const LargeFormatForm = bem`
    LargeFormatForm
        heading
        subHeading
        form
        footer
        switch
`;


// Input form to upload an ID on a device with accomodating screen space.
const PassportInput = {
    view({
        attrs: {
            idScanFormModel,
        },
    }) {
        return m(LargeFormatForm.block,
            m(`h2.${LargeFormatForm.heading}`,
                'I already have a photo I can upload'),
            m(`p.${LargeFormatForm.subHeading}`,
                `Upload a photo of the identification
                page of your passport against a dark
                surface.`),
            m(LargeFormatForm.form,
                m(LargeFormatIdSimplexControl, harness('large-format-passport-card', {
                    id: 'passport-id-page',
                    dataUrl: idScanFormModel.passport.dataUrl,
                    onchange(files) {
                        idScanFormModel.stage('passport', files);
                    },
                }), 'Identification page of passport')),
            m(CountryDropdown, {
                q: idScanFormModel,
            }),
            m(LargeFormatForm.footer,
                'No passport? ', m(pointable(LargeFormatForm.switch), {
                    onpointerup() {
                        idScanFormModel.type = 'duplex';
                    },
                }, 'Use a driver\'s license or ID card instead.')));
    },
};


const DuplexInput = {
    view({
        attrs: {
            idScanFormModel,
        },
    }) {
        return m(LargeFormatForm.block,
            m(`h2.${LargeFormatForm.heading}`,
                'I have photos that I can upload'),
            m(`p.${LargeFormatForm.subHeading}`,
                'Upload photos of the front and back sides of your ' +
                'ID card or driver\'s license on a dark surface.'),
            m(LargeFormatForm.form,
                m('.DuplexForm',
                    m(LargeFormatIdSimplexControl, harness('large-format-id-front-card', {
                        id: 'id-card-front',
                        dataUrl: idScanFormModel.front.dataUrl,
                        onchange(files) {
                            idScanFormModel.stage('front', files);
                        },
                    }), 'Front side'),
                    m(LargeFormatIdSimplexControl, harness('large-format-id-back-card', {
                        id: 'id-card-back',
                        dataUrl: idScanFormModel.back.dataUrl,
                        onchange(files) {
                            idScanFormModel.stage('back', files);
                        },
                    }), 'Back side'))),
            m(CountryDropdown, {
                q: idScanFormModel,
            }),
            m(LargeFormatForm.footer,
                'No card? ', m(pointable(LargeFormatForm.switch), {
                    onpointerup() {
                        idScanFormModel.type = 'passport';
                    },
                }, 'Use a passport instead.')));
    },
};


const DelegationForm = {
    view({
        attrs: {
            delegationController,
            onDelegationRequest,
        },
    }) {
        return m(LargeFormatForm.block,
            m(`h2.${LargeFormatForm.heading}`, 'Let me take a photo with my phone'),
            m(`p.${LargeFormatForm.subHeading}`, `We'll send you a text message with
                    instructions to complete your upload via your mobile phone.`),
            m(IdScanImageCaptureFront),
            m(PhoneDelegationInput, {
                id: 'id-delegation-phone',
                delegationController,
                onDelegationRequest,
            }));
    },
};



const OnboardingIdScan = {
    btx: bem`OnboardingIdScan
        heading
        subHeading
        methods
        controls`,
    view({
        attrs: {
            viewModel: {
                user: {
                    onboarding: {
                        idScanFormModel,
                    },
                },
            },
            viewModel: {
                user: {
                    onboarding,
                },
            },
        },
    }) {
        const { btx } = OnboardingIdScan;
        const usedPassport = idScanFormModel.type === 'passport';

        return m(btx.block,
            m(`h2${btx.heading}`,
                'Confirm your identity'),
            m(`p${btx.subHeading}`,
                'Please provide a photograph an ID document laying on a dark ' +
                'surface. All details should be clearly visible.'),
            m(btx.methods,
                m((idScanFormModel.type === 'passport') ? PassportInput : DuplexInput, {
                    idScanFormModel,
                }),
                m(DelegationForm, {
                    delegationController: idScanFormModel.delegationController,
                    onDelegationRequest: idScanFormModel.onDelegationRequest,
                })),
            m('.OnboardingStepControls',
                m(Button, {
                    flags: {
                        secondary: true,
                    },
                    onpress: () => {
                        woopraTrackConfirmIdentity(null, usedPassport, true);
                        return router.go('/setup/overview');
                    },
                }, 'Do this later'),
                m(Button, harness('submit-id', {
                    flags: {
                        primary: true,
                        round: true,
                    },
                    onpress: () => {
                        woopraTrackConfirmIdentity(idScanFormModel.countries[idScanFormModel.countryIndex - 1][1], usedPassport, false);
                        woopraTrackCompleteStep(true,
                            Boolean(onboarding.hasBillingInfo()),
                            Boolean(onboarding.isBusinessVerified()),
                            Boolean(onboarding.areTermsSigned()));
                        idScanFormModel.submit().then(() => {
                            router.go('/setup/overview');
                        });
                    },
                    disabled: !idScanFormModel.maySubmit(),
                }), 'Upload')),
            (idScanFormModel.submitting) && m(Overlay, m(Spinner)));
    },
};

export default () => {
    spa.setView(OnboardingIdScan, {
        layout: WithLeftNav,
    });
};
