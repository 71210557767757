import { bem, events, m, spa, validation } from '#/browser-framework';
import { copyTextToClipboard } from '#/browser-framework/behaviors';
import { Button, Dropdown, Named, pointable} from '#/browser-framework/comps';
import { purecomp } from '#/browser-framework/vcomps';

import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';
import { WithLeftNav, AdminLayout} from '#/rp-facing/views/Layouts';


const { isUrl } = validation;

const btxWebhookForm = bem`NewWebhookForm fields buttons url eventType`;
const btxWebhookTable = bem`WebhookTable header readRow updateRow`;
const btxApiKey = bem`ApiKeySetting header input title copyButton visibility`;

const WebhookTableRowRead = purecomp(({hook, i, webhookEvents}) => {
    const rowHook = webhookEvents.find((webhookEvent) => (webhookEvent.name === hook.name));
    hook.label = rowHook
        ? rowHook.label
        : '...loading';

    return m(btxWebhookTable.readRow,
        m('span', hook.label),
        m('span', hook.url),
        m(pointable(Named.Icon), {
            name: 'pencil',
            onpointerup: () => {
                events.emit('open-webhook-editor', i);
            },
        }),
        m(pointable(Named.Icon), {
            name: 'delete',
            onpointerup: () => {
                events.emit('delete-webhook', i);
            }}));
});

const WebhookTableRowUpdate = purecomp(({hook, i, webhookEvents, editHooks}) => (
    m(btxWebhookTable.updateRow,
        m(Dropdown, {
            extraClass: 'StandardForm__field--input',
            value: webhookEvents
                .map((event) => event.name)
                .indexOf(editHooks.find((obj) => (i === obj.webhookIndex)).name),
            options: webhookEvents.map((e) => e.label),
            select(index, e) {
                events.emit('edit-webhook-event', i, e);
            }}),
        m('input.StandardForm__field--input', {
            defaultValue: hook.url,
            oninput(e) {
                events.emit('edit-webhook-url', i, e.target.value);
            },
        }),
        m(pointable(Named.Icon), {
            name: 'checkmark',
            onpointerup: () => {
                if (isUrl(editHooks.find((obj) => (i === obj.webhookIndex)).url)) {
                    events.emit('accept-webhook-changes', i);
                }
            },
        }),
        m(pointable(Named.Icon), {
            name: 'x',
            onpointerup: () => {
                events.emit('cancel-webhook-changes', i);
            },
        }))));

const WebhookTableRowHeader = purecomp(() => (
    m(btxWebhookTable.header,
        m('span', 'Event'),
        m('span', 'Webhook URL'))));


const ApiKeyTitle = purecomp(({title}) => (
    m(btxApiKey.header,
        m(btxApiKey.title, title),
    )));

const ApiKeyCopyButton = purecomp(({value}) => (
    m(pointable(btxApiKey.copyButton), {
        onpointerup: () => {
            copyTextToClipboard(value, spa.$window);
            events.emit('user-copied-text', 'API Key');
        },
    }, 'Copy')));


const ApiKeyField = purecomp(({value, showKeyChars, title, copy, extraClass}) => (
    m('.ApiKeySetting__field', { class: extraClass },
        m(ApiKeyTitle, { title }),
        m('.ApiKeySetting__copyable-field',
            m(`input.${btxApiKey.input}`, {
                value,
                readonly: 'readonly',
                type: showKeyChars
                    ? 'text'
                    : 'password',
            }),
            copy ? m(ApiKeyCopyButton, {value}) : null,
        )
    )));

const ApiKeyVisibility = purecomp(({showKeyChars}) => (
    m(btxApiKey.visibility,
        m(pointable(Named.Icon), {
            name: showKeyChars
                ? 'no-eye'
                : 'eye',
            onpointerup: () => {
                events.emit('toggle-apikey-visibility');
            },
        }))));

const NewWebhookFields = purecomp(({newWebhook, webhookEvents}) => (
    m(btxWebhookForm.fields,
        m(btxWebhookForm.eventType,
            m('.FlushedLabelText', 'Event type'),
            m(Dropdown, {
                disabled: false,
                placeholder: 'Select an event',
                options: webhookEvents.map((e) => (e.label)),
                select(i, e) {
                    events.emit('set-new-webhook-event', e);
                },
            })),
        m(btxWebhookForm.url,
            m('.FlushedLabelText', 'Webhook URL'),
            m('input', {
                value: newWebhook.url,
                placeholder: 'Enter a webhook URL for this event',
                oninput(e) {
                    events.emit('set-new-webhook-url', e.target.value);
                },
            })))));

const NewWebhookButtons = purecomp(({newWebhook}) => (
    m(btxWebhookForm.buttons,
        m(Button, {
            flags: {
                'danger': true,
                'glass': true,
            },
            onpress: (e) => {
                e.stopPropagation();
                events.emit('close-new-webhook-form');
            },
        }, 'Cancel'),
        newWebhook.name && isUrl(newWebhook.url)
            ? m(Button, {
                flags: {
                    'primary': true,
                    'round': true,
                },
                onpress: (e) => {
                    e.stopPropagation();
                    events.emit('create-new-webhook');
                },
            }, 'Add Webhook')
            : m(Button, {
                flags: {
                    'disabled': true,
                    'primary': true,
                    'round': true,
                },
            }, 'Add Webhook'))));

const NewWebhookForm = purecomp(({newWebhook, webhookEvents}) =>
    m(btxWebhookForm.block,
        m(NewWebhookFields, {newWebhook, webhookEvents}),
        m(NewWebhookButtons, {
            newWebhook,
        })));

const WebhooksTable = purecomp(({fields, editHooks, editMode, webhookEvents}) =>
    m(btxWebhookTable.block,
        m(WebhookTableRowHeader),
        fields.postHooks.value.map((hook, i) => (editMode[i]
            ? m(WebhookTableRowUpdate, {hook, i, webhookEvents, editHooks})
            : m(WebhookTableRowRead, {hook, i, webhookEvents})))));

const ApiKeyForm = purecomp(({apiKey, showKeyChars, apiUsername}) => (
    m(btxApiKey.block,
        m(ApiKeyField, {
            extraClass: 'user',
            value: apiUsername,
            showKeyChars: true,
            title: 'Account name',
        }),
        m(ApiKeyField, {
            extraClass: 'key',
            value: apiKey,
            showKeyChars,
            title: 'API Key',
            copy: true,
        }),
        m(ApiKeyVisibility, {showKeyChars}))));

const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        if (!App.user.selectedRp) {
            return m(AdminLayout, {App, subtitle: 'API'});
        }
        const { fields } = App.user.selectedRp.settings.webhooks;
        const apiKey = App.user.selectedRp.settings.apiKey;
        const _state = App.user.selectedRp.settings.apiConfiguration;

        return m(AdminLayout, {App, subtitle: 'API'},
            m('h2', 'API Key'),
            m(ApiKeyForm, {apiKey, showKeyChars: _state.showKeyChars, apiUsername: App.user.selectedRp.name}),

            m('h2', 'Webhooks'),
            fields.postHooks.value.length
                ? m(WebhooksTable, {
                    fields,
                    editHooks: _state.editHooks,
                    editMode: _state.editMode,
                    webhookEvents: _state.webhookEvents,
                })
                : m('br'),

            _state.showNewWebhookForm
                ? m(NewWebhookForm, {
                    webhookEvents: _state.webhookEvents,
                    newWebhook: _state.newWebhook})
                : m(pointable('.WebhookPrompt'), {
                    onpointerup: () => {
                        events.emit('open-new-webhook-form');
                    },
                },
                fields.postHooks.value.length
                    ? 'Add a new webhook '
                    : 'No webhooks yet. Add one? ',

                m(Named.Icon, {name: 'plus'})));
    },
};

export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    pendingLayout: WithLeftNav,
    settingName: 'webhooks',
});
