import { harness, m, spa, tx, router } from '#/browser-framework';
import { pointable } from '#/browser-framework/comps';

import RequestsSvgUri from '#/rp-facing/images/icon-requests.svg';
import RequestsSelectedSvgUri from '#/rp-facing/images/icon-requests-selected.svg';
import AdminSvgUri from '#/rp-facing/images/icon-admin.svg';
import AdminSelectedSvgUri from '#/rp-facing/images/icon-admin-selected.svg';
import RocketSelectedSvgUri from '#/rp-facing/images/icon-rocket-selected.svg';
import DevelopersSelectedSvgUri from '#/rp-facing/images/icon-developers-selected.svg';
import DevelopersSvgUri from '#/rp-facing/images/icon-developers.svg';


// Pure-CSS techniques for accordians of variable height
// do not perfectly capture intended effect.
function updateVarHeightAccordian(vnode) {
    const {attrs, dom} = vnode;

    dom.setAttribute('style',
        (attrs.selected)
            ? `max-height: ${Array.from(dom.children).reduce((p, c) => p + c.clientHeight + 1, 0)}px;`
            : 'max-height: 0px;');
}

const Link = {
    view: ({
        attrs: {
            route,
            href,
            selected,
            'data-harness': dataharness,
        }, children }) => {
        const classes = tx('Link', {selected});

        return m(pointable(`li${classes}`), {
            onpointerdown() {
                if (!selected) {
                    if (route) {
                        router.go(route);
                    } else if (href) {
                        spa.$window.location.assign(href);
                    }
                }
            },
            'data-harness': dataharness,
        }, ...children);
    },
};

const SubLevelNav = {
    oncreate: updateVarHeightAccordian,
    onupdate: updateVarHeightAccordian,
    view({ children }) {
        return m('ul.SubLevelNav', children);
    },
};


const TopItem = {
    view({
        attrs: {
            'data-harness': dataharness,
            selected,
            disabled,
            defaultRoute,
            icon,
            label,
        },
        children,
    }) {
        return m(tx('.TopItem', {selected}),
            m(pointable(tx('.TopLevelLabel', {selected, disabled})), {
                onpointerdown() {
                    if (!selected && !disabled) {
                        router.go(defaultRoute);
                    }
                },
                'data-harness': dataharness,
            },
            m('i.TopLevelLabel__icon', m('img', {src: icon})),
            m('span.TopLevelLabel__text',
                m('span.TopLevelLabel__name', label),
                m('span.TopLevelLabel__more', (children.length > 0) ? '▼' : ''))),
            m(SubLevelNav, {selected}, children));
    },
};


const DevDocNavigation = {
    view({
        attrs: {
            rpName,
            pendingRoute,
        },
    }) {
        return m(TopItem, harness('top-level-nav-link-for-developers', {
            selected: pendingRoute.indexOf('/docs') > -1,
            icon: (pendingRoute.indexOf('/docs') > -1)
                ? DevelopersSelectedSvgUri
                : DevelopersSvgUri,
            defaultRoute: `/${rpName}/docs/verify-api-assure-sdk`,
            label: 'Docs (Beta)',
        }),
        m(Link, harness('sub-level-nav-link-verify-api-assure-sdk', {
            route: `/${rpName}/docs/verify-api-assure-sdk`,
            selected: pendingRoute.indexOf('/docs/verify-api-assure-sdk') > -1,
        }), 'VerifyAPI and AssureSDK'),
        m(Link, harness('sub-level-nav-link-verify-api-eid-submit-uis', {
            route: `/${rpName}/docs/verify-api-eid-submit-uis`,
            selected: pendingRoute.indexOf('/docs/verify-api-eid-submit-uis') > -1,
        }), 'VerifyAPI and Evident Submit UIs'),
        m(Link, harness('sub-level-nav-link-verify-api-submit-api', {
            route: `/${rpName}/docs/verify-api-submit-api`,
            selected: pendingRoute.indexOf('/docs/verify-api-submit-api') > -1,
        }), 'VerifyAPI and SubmitAPI'),
        m(Link, harness('sub-level-nav-link-webhooks', {
            route: `/${rpName}/docs/webhooks`,
            selected: pendingRoute.indexOf('/docs/webhooks') > -1,
        }), 'Webhooks'),
        m(Link, harness('sub-level-nav-link-webhooks', {
            href: deploy.WEB_PUBLIC_EID_DOCS_URL_PREFIX,
        }), 'API Reference'));
    },
};

const UnrestrictedNavigation = {
    view({
        attrs: {
            docsEnabled,
            rpName,
            pendingRoute,
            showInternal,
        },
    }) {
        /* eslint-disable indent */
        return m('.TopLevelNav',
            m(TopItem, harness('top-level-nav-link-to-requests', {
                selected: pendingRoute.indexOf('/requests') > -1,
                icon: (pendingRoute.indexOf('/requests') > -1)
                    ? RequestsSelectedSvgUri
                    : RequestsSvgUri,
                defaultRoute: `/${rpName}/requests`,
                label: 'Requests',
            }),
                m(Link, harness('sub-level-nav-link-to-dashboard', {
                    route: `/${rpName}/requests`,
                    selected: pendingRoute.endsWith('/requests'),
                }), 'Dashboard'),
                m(Link, harness('sub-level-nav-link-to-create-request', {
                    route: `/${rpName}/requests/create`,
                    selected: pendingRoute.indexOf('/requests/create') > -1,
                }), 'Create Request')),
            (docsEnabled) && m(DevDocNavigation, { rpName, pendingRoute }),
            m(TopItem, harness('top-level-nav-link-to-administration', {
                selected: pendingRoute.indexOf('/admin') > -1,
                icon: (pendingRoute.indexOf('/admin') > -1)
                    ? AdminSelectedSvgUri
                    : AdminSvgUri,
                defaultRoute: `/${rpName}/admin/configure-api`,
                label: 'Administration',
            }),
                m(Link, harness('sub-level-nav-link-to-api-settings', {
                    route: `/${rpName}/admin/configure-api`,
                    selected: pendingRoute.indexOf('/admin/configure-api') > -1,
                }), 'API'),
                m(Link, harness('sub-level-nav-link-to-branding-settings', {
                    route: `/${rpName}/admin/branding`,
                    selected: pendingRoute.indexOf('/admin/branding') > -1,
                }), 'Branding Settings'),
                m(Link, harness('sub-level-nav-link-to-email-settings', {
                    route: `/${rpName}/admin/email`,
                    selected: pendingRoute.indexOf('/admin/email') > -1,
                }), 'Email Settings'),
                false && m(Link, {
                    route: `/${rpName}/admin/mvr`,
                    selected: pendingRoute.indexOf('/admin/mvr') > -1,
                }, 'Motor Vehicle Records Policy'),
                false && m(Link, {
                    route: `/${rpName}/admin/crime`,
                    selected: pendingRoute.indexOf('/admin/crime') > -1,
                }, 'Criminal History Policy'),
                m(Link, {
                    route: `/${rpName}/admin/fcra`,
                    selected: pendingRoute.indexOf('/admin/fcra') > -1,
                }, 'FCRA Disclosure & Authorization'),
                m(Link, harness('sub-level-nav-link-to-confirmation-message', {
                    route: `/${rpName}/admin/confirmation-message`,
                    selected: pendingRoute.indexOf('/admin/confirmation-message') > -1,
                }), 'Confirmation Message'),
                false && m(Link, {
                    route: `/${rpName}/admin/adverse-action`,
                    selected: pendingRoute.indexOf('/admin/adverse-action') > -1,
                }, 'Adverse Action'),
                m(Link, harness('sub-level-nav-link-to-expiration-message', {
                    route: `/${rpName}/admin/link-expiration`,
                    selected: pendingRoute.indexOf('/admin/link-expiration') > -1,
                }), 'Link Expiration Message'),
                (false && showInternal) && m(Link, {
                    route: `/${rpName}/admin/internal`,
                    selected: pendingRoute.indexOf('/admin/internal') > -1,
                }, 'Evident Only')));
            /* eslint-enable indent */
    },
};

// Used when the RP has not finished onboarding.
// This restricts the RP to whatever the Account Setup Guide says to do.
// See left side of https://projects.invisionapp.com/share/9AK3CWKV6MR#/screens/297265264
const OnboardingNavigation = {
    view() {
        return m('.TopLevelNav',
            m(TopItem, {
                disabled: false,
                selected: true,
                defaultRoute: '/setup/overview',
                icon: RocketSelectedSvgUri,
                label: 'Account Setup Guide',
            }),
            m(TopItem, {
                disabled: true,
                selected: false,
                icon: RequestsSvgUri,
                label: 'Requests',
            }),
            m(TopItem, {
                disabled: true,
                selected: false,
                icon: AdminSvgUri,
                label: 'Admin Settings',
            }));
    },
};

const GuestNavigation = {
    view() {
        return m('.TopLevelNav');
    },
};

const PendingNavigation = {
    view() {
        return null;
    },
};

const getSelectedRp = (user, docsEnabled, pendingRoute) => {
    return user.selectedRp
        ? m(UnrestrictedNavigation, {
            rpName: user.selectedRp.name,
            pendingRoute,
            showInternal: user.isEvidentAffiliate(),
            docsEnabled,
        })
        : m(PendingNavigation);
};

export default {
    view({
        attrs: {
            docsEnabled,
            pendingRoute,
            user,
        },
    }) {
        return (user.isGuest())
            ? m(GuestNavigation, { pendingRoute })
            : (user.isDoneOnboarding())
                ? getSelectedRp(user, docsEnabled, pendingRoute)
                : m(OnboardingNavigation);
    },
};
