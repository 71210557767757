import debounce from 'debounce';
import { factory } from '#/universal-framework/objects';

/*
This module is responsible for monitoring user presence.
It's key event is raising an event if the user does not
interact with the GUI for a configurable amount of time.

Take note that you can use this in a way to respond to
idling users using independent or shared timing information.

Say for example you have 2 tabs open, A and B, each with this
script running. If the timing information is independent,
A will eventually flag the user as idle even if the user
is actively working on B. If the timing information is shared,
a user's action in B will flag the user as present in both
A and B.
*/

const SIGNS_OF_LIFE = ['mousemove', 'keypress', 'touchstart'];


export const presenceMonitor = (eventTarget, {
    onidle,
    msUntilIdle = 5 * 60 * 60 * 1000, // 5 min.
    debounceDelay = 100,
    intervalDelay = 800,
    storageImpl,
    storageKey = '__lat',
} = {}) => factory((iface) => ({
    _monitoring: false,
    onidle,
    msUntilIdle,
    debounceDelay,
    storageImpl,
    storageKey,

    getLastActivityTime: () =>
        parseInt(storageImpl.getItem(storageKey) || '0', 10),

    getIdleTimeDelta: () =>
        msUntilIdle - (Date.now() - iface.getLastActivityTime()),

    startMonitoring: () => {
        iface.stopMonitoring();
        iface._eventHandler = iface._produceEventHandler();

        // Records first timestamp
        iface._eventHandler();

        for (const event of SIGNS_OF_LIFE) {
            eventTarget.addEventListener(event, iface._eventHandler);
        }

        const _idleChecker = () => {
            // If the amount of time that passed from time of last known activity
            // actually did pass, then call onidle.
            //
            // We don't trust setTimeout() to indicate absense.
            // Think about browsers with multiple tabs/windows open.
            if (iface.getIdleTimeDelta() <= 0) {
                onidle();
            }
        };

        iface._idleInterval = setInterval(_idleChecker, intervalDelay);
        iface._monitoring = true;
    },

    isMonitoring: () =>
        iface._monitoring,

    stopMonitoring: () => {
        clearInterval(iface._idleInterval);

        for (const event of SIGNS_OF_LIFE) {
            eventTarget.removeEventListener(event, iface._eventHandler);
        }

        iface._monitoring = false;
    },

    _produceEventHandler: () => debounce(() => {
        storageImpl.setItem(storageKey, Date.now());
    }, debounceDelay, true),
}));
