import { m } from '#/browser-framework';


const BASE_PRICING_LINK = 'https://www.evidentid.com/contact-sales/';

export function computePricingLink(query) {
    return (query)
        ? `${BASE_PRICING_LINK}?${m.buildQueryString(query)}`
        : BASE_PRICING_LINK;
}
