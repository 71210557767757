import {m} from '#/browser-framework';

const TimeCategory = {
    view({attrs: {bgcolor, label, width}}) {
        return m('tr.TimeCategory',
            m('td.TimeCategory__label', m.trust(label)),
            m('td.TimeCategory__bar', {
                style: {
                    'width': `${width}px`,
                    'background-color': bgcolor,
                },
            }));
    },
};

export default {
    view({attrs}) {
        return m('table.CompletionTimes', m('tbody',
            attrs.completionTimes.map((cat) => m(TimeCategory, cat))));
    },
};
