import { m } from '#/browser-framework';
import { saveHistoryIfPossible } from '#/rp-facing/views/pages/FormFields/SaveHistory';
import { fieldBem } from '#/rp-facing/styles/FormsBem';

export const EmailTextField = {
    view({attrs: field, children}) {
        return m(fieldBem.block,
            m(`label.${fieldBem.label}`, { for: field.id }, children),
            m(`input.${fieldBem.input}`, {
                id: field.id,
                placeholder: '',
                value: field.value,
                oninput(e) {
                    saveHistoryIfPossible(field);
                    field.value = e.target.value;
                },
            })
        );
    },
};
