import { definition } from '#/universal-framework/errors';

export const NotRpAdmin = definition(null, {
    reason: 'rp-facing/not-admin',
});

export const RpNotAvailable = definition(null, {
    reason: 'rp-facing/rp-not-available',
});

export const AccessDeniedErrors = [
    RpNotAvailable,
    NotRpAdmin,
];
