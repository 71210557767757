
// Models a set of toggle controls, like checkboxes, combined with a master 'all' toggle that controls the state of the set.
// Use for 'select all' functionality alongside selectable items.
export function optionset({ initialState = {}, dirty = false } = {}) {
    const iface = {};
    const _entries = Object.entries(initialState).reduce((p, [k, v]) => {
        p[k] = Boolean(v);

        return p;
    }, {});

    iface.dirty = dirty;

    iface.isOn = (key) => Boolean(_entries[key]);
    iface.areAllOn = () => Object.keys(_entries).every(iface.isOn);
    iface.toggleOne = (key) => {
        iface.dirty = true;
        _entries[key] = !_entries[key];

        return _entries[key];
    };

    iface.toggleAll = () => Object.keys(_entries).reduce((state, key) => iface.setOne(key, state), !iface.areAllOn());
    iface.getSelected = () => Object.keys(_entries).filter((k) => _entries[k]);

    iface.setOne = (key, state) => {
        iface.dirty = true;
        _entries[key] = Boolean(state);

        return _entries[key];
    };

    return iface;
}

export function copyTextToClipboard(text, $window) {
    const el = document.createElement('textarea');
    const { document: $doc } = $window;

    el.value = text;
    $doc.body.appendChild(el);

    el.select();

    $doc.execCommand('copy');
    $doc.body.removeChild(el);
}
