import { userInput } from '#/browser-framework';


const { field, inputlock } = userInput;


export default function richTextSetting(rpname, submit) {
    let lock;
    const fire = () => submit(lock.dehydrate());

    return inputlock({
        id: `${rpname}-markup-settings`,
        fire,
        fields: {
            markup: field({
                id: 'markup-text',
            }),
        },
    }).then((_lock) => {
        lock = _lock;

        lock.dehydrate = () => {
            return {
                $objectType: 'BinaryData',
                mime_type: 'text/html',
                data: lock.dump().markup.trim(),
                metadata: {
                    character_encoding: 'utf-8',
                },
            };
        };

        lock.hydrate = (v = {}) => {
            lock.load({
                markup: v.data,
            });
        };

        return lock;
    });
}
