import { m, harness, router, spa } from '#/browser-framework';
import { Button, Overlay, Spinner, pointable } from '#/browser-framework/comps';
import { MobileSimplexDropbox } from '#/ido-lib/views/MobileSimplexDropbox';

import { MobileLayout } from '#/rp-facing/views/Layouts';

import { DelegateScanner } from './DelegateScanner';


const DelegationDuplexScan = {
    view({
        attrs: {
            viewModel: {
                onboarding: {
                    idScanFormModel,
                },
            },
        },
    }) {
        return m(DelegateScanner.block,
            m(pointable(DelegateScanner.switch), {
                onpointerup() {
                    router.go('/setup/delegate/passport');
                },
            }, 'Use a passport instead.'),
            m(DelegateScanner.form,
                m(MobileSimplexDropbox, harness('id-card-front', {
                    id: 'id-card-front',
                    accept: 'image/*',
                    dataUrl: idScanFormModel.front.dataUrl,
                    enablePreview: true,
                    onchange(files) {
                        idScanFormModel.stage('front', files);
                    },
                }), 'Front side'),
                m(MobileSimplexDropbox, harness('id-card-back', {
                    id: 'id-card-back',
                    accept: 'image/*',
                    enablePreview: true,
                    dataUrl: idScanFormModel.back.dataUrl,
                    onchange(files) {
                        idScanFormModel.stage('back', files);
                    },
                }), 'Back side')),
            m(DelegateScanner.controls,
                m(Button, harness('submit', {
                    flags: {
                        round: true,
                        primary: true,
                    },
                    disabled: !idScanFormModel.maySubmit(),
                    onpress() {
                        idScanFormModel.submit().then(() => {
                            router.go('/setup/delegate/done');
                        });
                    },
                }), 'Upload')),
            (idScanFormModel.submitting) && m(Overlay, m(Spinner)));
    },
};


export default () => {
    spa.setView(DelegationDuplexScan, {
        layout: MobileLayout,
    });
};
