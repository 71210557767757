import { bem, harness, tx, m, spa, router } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';

import { WithLeftNav } from '#/rp-facing/views/Layouts';

import StepIconBillingUri from '#/rp-facing/images/step-icon-billing.svg';
import StepIconIdUri from '#/rp-facing/images/step-icon-id.svg';
import StepIconBusinessUri from '#/rp-facing/images/step-icon-biz.svg';
import StepIconTermsUri from '#/rp-facing/images/step-icon-terms.svg';


const Requirement = {
    btx: bem`
        Requirement
            check
            icon
            text
            heading
            description
    `,
    view({
        attrs: {
            'data-harness': dh,
            heading,
            done,
            description,
            icon,
            route,
        },
    }) {
        const { btx } = Requirement;

        return m(pointable(tx(btx.block, {done})), {
            onpointerup() {
                if (!done) {
                    router.go(route);
                }
            },
        },
        m(btx.check,
            m(Named.Icon, {
                name: (done)
                    ? 'check-circle-filled'
                    : 'circle-outlined',
            })),
        m(btx.icon,
            m('img', { src: icon })),
        m(btx.text, harness(dh),
            m(btx.heading, m.trust(heading)),
            m(btx.description, description)));
    },
};


const RequirementsOverview = {
    btx: bem`
        RequirementsOverview
            instructions
            steps
            `,
    view({
        attrs: {
            viewModel,
        },
    }) {
        const { btx } = RequirementsOverview;
        const { onboarding } = viewModel.user;

        return m(btx.block,
            m(btx.instructions,
                m('h2',
                    'Let\'s finish getting your account set up'),
                m('p',
                    'You\'re just a few short steps away from verifying the data ' +
                    'you need, with less risk and friction. Your progress will be ' +
                    'saved if you need to come back later.')),
            m(btx.steps,
                m(Requirement, harness('terms-step', {
                    icon: StepIconTermsUri,
                    done: onboarding.areTermsSigned(),
                    route: '/setup/terms',
                    heading: 'Accept the terms &amp; conditions',
                    description: 'Please read and acknowledge that you accept the terms and conditions of use.',
                })),
                m(Requirement, harness('id-step', {
                    icon: StepIconIdUri,
                    done: onboarding.isUserVerified(),
                    route: '/setup/verify-id',
                    heading: 'Confirm your identity',
                    description: (
                        'In order to protect the personal data of the individuals your company interacts with, ' +
                        'all team members with access to this account will need to verify their identities.'
                    ),
                })),
                m(Requirement, harness('biz-step', {
                    icon: StepIconBusinessUri,
                    done: onboarding.isBusinessVerified(),
                    route: '/setup/verify-business',
                    heading: 'Verify your business',
                    description: (
                        'Due to the ability to access regulated data through the Evident platform, ' +
                        'all new accounts must be verified to be associated with a business.'
                    ),
                })),
                m(Requirement, harness('pay-step', {
                    icon: StepIconBillingUri,
                    done: onboarding.hasBillingInfo(),
                    route: '/setup/billing',
                    heading: 'Set up billing information',
                    description: (
                        'While we do require payment information on file, your account will not be charged ' +
                        'until you start running verifications'
                    ),
                }))));
    },
};

export default () => {
    if (spa.viewModel.user.onboarding.shouldShowPlanSelection()) {
        router.go('/setup/plan-selection');
    } else {
        spa.setView(RequirementsOverview, {
            layout: WithLeftNav,
        });
    }
};
