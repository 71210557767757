import json from '#/universal-framework/json';
import { m, router, spa } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';
import { clientSideChannel } from '#/browser-framework/broadcast';


let timer;

function grammaticallyCorrect(secondsLeft) {
    return (secondsLeft === 1)
        ? `${secondsLeft} second`
        : `${secondsLeft} seconds`;
}

function logOut(app) {
    spa.$window.firebase.auth().signOut().catch(() => {}).then(() => {
        app.hideDialog();
        router.go('/auth');
    });
}

export const SEC_BEFORE_EXPIRY = json.integer(deploy.WEB_PUBLIC_IDLE_SECONDS_BEFORE_EXPIRY, 60);

export default {
    secondsLeft: SEC_BEFORE_EXPIRY,
    channel: clientSideChannel(spa.$window, 'rpweb-idle-dialog'),
    oncreate({ state, attrs }) {
        state.secondsLeft = SEC_BEFORE_EXPIRY;
        state.channel.onmessage = () => attrs.App.hideDialog();

        if (timer) {
            clearInterval(timer);
        }

        timer = setInterval(() => {
            --state.secondsLeft;
            spa.redraw();

            if (state.secondsLeft <= 0) {
                clearInterval(timer);

                logOut(attrs.App);
            }
        }, 1000);
    },
    onremove() {
        clearInterval(timer);
    },
    view({state, attrs: {App}}) {
        return m('.ExpiryDialog',
            m('h3.ExpiryDialog__heading',
                `Due to inactivity, your account will be
                signed out in ${grammaticallyCorrect(state.secondsLeft)}...`),
            m('hr'),
            m('p.ExpiryDialog__body',
                `To protect sensitive data of your users, you will be automatically signed out
                after 60 minutes of inactivity. Do you want to stay signed in?`),
            m('.ExpiryDialog__controls',
                m('.ExpiryDialog__action',
                    m(Button, {
                        flags: {
                            secondary: true,
                        },
                        onpress() {
                            logOut(App);
                        },
                    }, 'Sign out')),
                m('.ExpiryDialog__action',
                    m(Button, {
                        flags: {
                            primary: true,
                        },
                        onpress() {
                            state.channel.postMessage(null);
                            App.hideDialog();
                        },
                    }, 'Keep me signed in'))));
    },
};
