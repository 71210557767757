import { userInput, harness, m, router, spa } from '#/browser-framework';
import { Button, Spinner } from '#/browser-framework/comps';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';

import { GoogleButton, FacebookButton } from './Sso';

const { enterToSubmit, textFieldBehavior } = userInput;


const LoginForm = {
    view({attrs: rpfacing}) {
        const { user: { credentials } } = rpfacing;
        const { email, password } = credentials.fields;
        const { error } = credentials;
        const ets = enterToSubmit(credentials);

        return m('.LoginForm',
            m('.LoginForm__extern',
                m('.LoginForm__provider', m(GoogleButton, {rpfacing})),
                m('.LoginForm__provider', m(FacebookButton, { rpfacing }))),
            m('.LoginForm__divider',
                m('.LoginForm__dividerLine'),
                m('.LoginForm__dividerEmblem', 'or'),
                m('.LoginForm__dividerLine')),
            error && m('.LoginForm__error', harness('rp-facing-login-popup-error'), error.message),
            m('.LoginForm__email',
                m(`label[for=${email.id}]`, 'Email:'),
                m('input', harness('rp-facing-login-email-address',
                    ets(textFieldBehavior(email, {type: 'email'})))),
                email.errorText && m('p.fg-danger', email.errorText)),
            m('.LoginForm__password',
                m(`label[for=${password.id}]`, 'Password:'),
                m('input', harness('rp-facing-login-password',
                    ets(textFieldBehavior(password, {type: 'password'})))),
                password.errorText && m('p.fg-danger', password.errorText)),
            m('.LoginForm__controls',
                m('.LoginForm__action',
                    m(Button, harness('rp-facing-native-forgot-password-button', {
                        flags: {
                            secondary: true,
                        },
                        onpress: () => router.go('/forgot-password'),
                    }), 'Forgot Password?')),
                m('.LoginForm__action',
                    m(Button, harness('rp-facing-native-login-button', {
                        flags: {
                            primary: true,
                            round: true,
                        },
                        disabled: !credentials.maySubmit,
                        onpress: () => credentials.submit(),
                    }), 'Log in'))));
    },
};

const LoginPage = {
    view({
        attrs: {
            viewModel: rpfacing,
        },
    }) {
        return (rpfacing.user.isGuest())
            ? m('.Auth',
                m('.Auth__dialog',
                    m('h2.Auth__heading', 'Sign in to your Evident account'),
                    m(LoginForm, rpfacing)),
                m('p.Auth__note',
                    'Don\'t have an account? ',
                    m('a[href=#!/register]',
                        harness('rp-facing-create-account-link', {}),
                        'Create one.')))
            : m(Spinner);
    },
};

export default () => {
    spa.viewModel.user.loadFormModels().then(() => {
        spa.setView(LoginPage, {
            layout: RestrictedLayout,
        });
    });
};
