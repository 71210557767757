import { deepKeyExists } from '#/universal-framework/objects';
import { lets } from '#/universal-framework/functions';
import { ellipsis } from '#/universal-framework/strings';
import { m, bem, events, spa, router } from '#/browser-framework';
import { Dropdown, Named, Overlay, Spinner } from '#/browser-framework/comps';
import { copyTextToClipboard } from '#/browser-framework/behaviors';
import { purecomp } from '#/browser-framework/vcomps';

import ComboButton from '#/rp-facing/views/ComboButton';
import { WithLeftNav } from '#/rp-facing/views/Layouts';


export const SupportEmail = purecomp(() => m('a', {
    href: 'mailto:support@evidentid.com',
}, 'support@evidentid.com'));


/*
A block of preformatted text that targets itself for syntax highlighting.
`lang` strings come from http://highlightjs.readthedocs.io/en/latest/css-classes-reference.html#language-names-and-aliases
*/

const _highlightCode = ({ dom }) =>
    import('highlightjs').then((hljs) =>
        hljs.highlightBlock(dom.querySelector('code')));

export const CodeBlock = {
    btx: bem`CodeBlock title header source copy`,
    oncreate: _highlightCode,
    onupdate: (vnode) => {
        vnode.dom.querySelector('code').innerText = vnode.attrs.sourceCodeText;

        _highlightCode(vnode);
    },
    view({
        attrs: {
            title: titleText = 'Example code',
            lang = 'nohighlight',
            sourceCodeText,
        },
    }) {
        const { block, header, copy, source, title } = CodeBlock.btx;

        return m(block,
            m(`header${header}`,
                m(title, titleText),
                m(copy, {
                    onclick({ target }) {
                        copyTextToClipboard(target
                            .closest(CodeBlock.btx.block)
                            .querySelector('code')
                            .textContent, spa.$window);

                        events.emit('user-copied-text');
                    },
                },
                m(Named.Icon, { name: 'copy' }), 'Copy')),
            m(source, m('pre', m('code', { 'class': lang }, sourceCodeText))));
    },
};

export const XCodeSample = purecomp(
    ({ lang = 'nohighlight', source: sourceCodeText }) =>
        m(CodeBlock, { lang, sourceCodeText }));

// A list of definitions. Not using dd/dt due to difficulties with mock compliance.
export const TermDefinitions = {
    btx: bem`TermDefinitions definition pair term`,
    codeLabels: (vn) => m('code', vn),
    view({ attrs: { pairs, wrapTerm = (vn) => vn } }) {
        const { block, term, pair, definition } = TermDefinitions.btx;

        return m(`ul${block}`,
            pairs.map(([t, d], key) =>
                m('li', { key },
                    m(pair,
                        m(`dfn${term}`, wrapTerm(t)),
                        m(`span${definition}`, d)))));
    },
};

export const Walkthrough =
    bem`Walkthrough
        body
        header
        section`;


export const CaptionedImage =
    lets([bem`CaptionedImage image caption`],
        ({ block, image, caption }) =>
            purecomp(({ src }, children) =>
                m(`figure${block}`,
                    m(`figcaption${caption}`, children),
                    m(image, m('img', { src })))));

export const Anchored = lets([bem`Anchored text link`],
    ({ block, text, link }) =>
        purecomp(({id, href}, children) =>
            m(block, { id },
                m(text, children),
                m(link, {
                    onclick() {
                        const { origin, pathname } = spa.$window.location;

                        copyTextToClipboard(
                            `${origin}${pathname}${href}`,
                            spa.$window);

                        events.emit('user-copied-text', 'link');
                    },
                }, m(Named.Icon, { name: 'link' })))));


/*
e.g.


bindNavigation('/docs', [
    ['Introduction', 'intro'],
    ['How to make pickled garbanzo', 'wtf'],
]) returns ({
    TableOfContents: <Component that renders TOC>,
    intro: <Component for Introduction section heading>,
    wtf: <Component for How to... section heading>,
})
*/
export const bindNavigation = (routeBase, sections) => {
    const idToHref = (rpName, id) =>
        router.makeRoute(...[rpName].concat(routeBase.split('/')).concat(id));

    const TableOfContents =
        purecomp(({ rpName }) =>
            m('.TableOfContents',
                m('h4', 'Skip to:'),
                ...sections.map(([label, id], key) =>
                    m('a.TableOfContents__link', {
                        key,
                        href: idToHref(rpName, id),
                    }, label))));

    const makeHeading = (label, id) =>
        purecomp(() =>
            m(Anchored, {
                id,
                href: idToHref(id),
            }, m('h2', label)));

    return sections.reduce(
        (iface, [label, id]) =>
            Object.assign(iface, { [id]: makeHeading(label, id) }),
        { TableOfContents });
};

const TerminalMessage = lets([bem`TerminalMessage heading body`],
    ({ block, body, heading }) =>
        purecomp((_, children) =>
            m(Walkthrough.block,
                m(block,
                    m(`header${heading}`, children[0]),
                    m(`section${body}`, children[1])))));

const SelectionInstructions = purecomp(() =>
    m(TerminalMessage,
        m('h3', 'Generate developer docs (beta)'),
        m('p',
            'Documentation is tailored to your account ',
            'with up-to-date information. Please use the ',
            'above controls to load documentation for your ',
            'request templates.')));

const LoadFailure =
    purecomp(() =>
        m(TerminalMessage,
            m('h2', 'The docs you selected are not loading'),
            m('p',
                'If the problem persists, please ',
                'contact ', m(SupportEmail), '.')));

const NoTemplatesOnAccount =
    purecomp(() =>
        m(TerminalMessage,
            m('h2', 'There are no templates on your account'),
            m('p',
                'Some documentation is based on your account’s request templates. ',
                'For help creating templates, please contact ', m(SupportEmail), '.')));

const DynamicWalkthroughLayoutBtx =
    bem`DynamicWalkthroughLayout
        control
        controls
        controlLabel
        environment
        templates
        walkthrough`;

const DocsSelectionToolbar = lets([DynamicWalkthroughLayoutBtx], ({
    controls,
    control,
    controlLabel,
    environment,
    templates,
}) =>
    purecomp(({ docs }) =>
        m(controls,
            m(control,
                m(controlLabel, 'Template:'),
                m(templates,
                    m(Dropdown, docs.templateSelection))),
            m(control,
                m(controlLabel, 'Environment:'),
                m(environment,
                    m(ComboButton, docs.environment))),
            m(control,
                (docs.templateSelection.selection && docs.templateRefUrl) &&
                    m('a',
                        { href: docs.templateRefUrl, target: '_blank' },
                        m(Named.Icon, {name: 'external-link'}),
                        ' Reference for ',
                        docs.environment.getSelectedLabel(),
                        ' ',
                        ellipsis(docs.templateSelection.selection.name, 15))))));


export const DynamicWalkthroughLayout =
    lets([DynamicWalkthroughLayoutBtx], ({
        block,
        controls,
        walkthrough,
    }) =>
        purecomp((attrs, children) =>
            lets([attrs], ({ viewModel: { user: { selectedRp: { docs } } } }) =>
                m(WithLeftNav, attrs,
                    (docs.error)
                        ? m(block,
                            m(DocsSelectionToolbar, { docs }),
                            m(walkthrough, m(LoadFailure)))
                        : (docs.hasTemplates())
                            ? m(block,
                                m(DocsSelectionToolbar, { docs }),
                                m(walkthrough, (docs.templateSelection.selection)
                                    ? children
                                    : m(SelectionInstructions)))
                            : m(block,
                                m(controls),
                                m(walkthrough, m(NoTemplatesOnAccount))),
                    (docs.inflight) && m(Overlay, { full: false }, m(Spinner))))));


export const StaticWalkthroughLayout =
    lets([bem`StaticWalkthroughLayout`], ({ block }) =>
        purecomp((attrs, children) =>
            m(WithLeftNav, attrs,
                m(block, ...children))));

export const WithRp = (comp) => purecomp((attrs, children) =>
    (deepKeyExists('viewModel.user.selectedRp.docs.content', attrs)
        ? m(comp, attrs.viewModel.user.selectedRp, children)
        : null));
