import {m} from '#/browser-framework';

/*
                          <!> WARNING <!>

The metrics in this module have been derived mathematically by hand.
Do not modify the constants below without understanding SVG and trig.
                                                                      */



// Trace a circle through the mask of the chart.
function tracearc(vnode) {
    let firstrun = true;
    const progress = vnode.attrs.shareRate / 100;
    const arc = vnode.dom.querySelector('#arc');
    const radius = 195;

    const START_ANGLE = 150;
    const END_ANGLE = START_ANGLE + progress * (395 - START_ANGLE);

    // Set the color of the arc to match that of the number.
    const number = vnode.dom.querySelector('.ShareRate__number');
    arc.setAttribute('stroke', getComputedStyle(number).color);

    // Smaller values create a smoother circle at expense of more
    // iterations.
    const DEGREE_INCREMENT = 2;
    let d = '';

    for (let angle = START_ANGLE; angle < END_ANGLE; angle += DEGREE_INCREMENT) {
        const radians = (angle / 180) * Math.PI;

        // <200, 200> is the origin around which we rotate.
        // This is the center of the svg document below.
        const x = 200 + Math.cos(radians) * radius;
        const y = 200 + Math.sin(radians) * radius;

        // SVG talk: 'M X Y' means 'move to X Y'.
        // 'L X Y' means 'draw line to X Y'.
        // We move initially, then draw lines afterwards.
        // This basically draws a circle as a series of lines.
        d = `${d} ${firstrun ? 'M' : 'L'} ${x} ${y}`;

        firstrun = false;
    }

    arc.setAttribute('d', d);
}

/*
This is an elliptical graphic that visualizes completion rate.
*/
const graphic =
m('svg.ShareRate__graphic', {width: 200, height: 120, viewBox: '75 0 250 258'},
    m('defs',
        m('mask#myMask', {
            maskUnits: 'userSpaceOnUse',
            x: '0',
            y: '0',
            width: '400',
            height: '400',
        },
        m('circle', {cx: '200', cy: '200', r: '200', fill: 'white'}),
        m('circle', {cx: '200', cy: '200', r: '190', fill: 'black'}),
        m('circle', {cx: '200', cy: '200', r: '190', fill: 'black'}),
        m('rect', {x: '0', y: '250', width: '400', height: '170', fill: 'black'}),
        m('circle', {cx: '11', cy: '249', r: '5', fill: 'white'}),
        m('circle', {cx: '389', cy: '249', r: '5', fill: 'white'}))),
    m('circle', {
        cx: '200',
        cy: '200',
        r: '200',
        class: 'arc',
        fill: '#E5E5E5',
        mask: 'url(#myMask)',
    }),
    m('path', {
        id: 'arc',
        d: '',
        stroke: 'blue',
        'stroke-width': '13',
        fill: 'none',
        mask: 'url(#myMask)',
    }));


export default {
    oncreate: tracearc,
    onupdate: tracearc,
    view({attrs: metrics}) {
        return m('.ShareRate',
            graphic,
            m('.ShareRate__summary',
                m('.ShareRate__number',
                    `${metrics.shareRate}%`),
                m('.ShareRate__label',
                    'Share rate')));
    },
};
