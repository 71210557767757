import { dateObjectToJsDate } from '#/universal-framework/dates';
import { lets, contract, types, type } from '#/universal-framework/functions';
import { project } from '#/universal-framework/arrays';

const OBJECT_ARRAY = type(({ object, arrayOf }) => arrayOf(object));

export const extractDispositionDates = contract(
    (offenses = []) =>
        project(offenses, 'disposition_date'),
    () => ({
        // TODO: Strengthen.
        domain: [OBJECT_ARRAY],
        range: OBJECT_ARRAY,
    }));

export const pickLatestDispositionDate = contract(
    (offenses = []) =>
        lets([extractDispositionDates(offenses)], (dates) =>
            ((dates.length === 0)
                ? null
                : (dates.length === 1)
                    ? dates[0]
                    : dates.reduce((latest, other) =>
                        lets([dateObjectToJsDate(latest), dateObjectToJsDate(other)],
                            (latestDate, possiblyLater) =>
                                ((possiblyLater > latestDate)
                                    ? other
                                    : latest))))),
    () => ({
        // TODO: Strengthen.
        domain: [OBJECT_ARRAY],
        range: types.object,
    }));
