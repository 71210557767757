import { bem, m, spa } from '#/browser-framework';
import { SuccessScreen } from '#/ido-lib/views/StatusScreens';

import { MobileLayout } from '#/rp-facing/views/Layouts';


const DelegationDone = {
    btx: bem`
        DelegationDone
            heading
            body`,
    view() {
        const { btx } = DelegationDone;

        return m(SuccessScreen,
            m(`h1${btx.heading}`,
                'Thanks, we\'ve got your upload!'),
            m(`p${btx.body}`,
                'You can close this window at any time, and return to the device ' +
                'on which you were previously working.'));
    },
};


export default () => {
    spa.setView(DelegationDone, {
        layout: MobileLayout,
    });
};
