import { m, userInput, colors, harness, spa } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';

import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';
import { WithLeftNav, AdminLayout } from '#/rp-facing/views/Layouts';
import { ColorControl } from '#/rp-facing/views/ColorControl';
import InlineFileInput from '#/rp-facing/views/InlineFileInput';
import { fieldBem, formBem } from '#/rp-facing/styles/FormsBem';


const { textFieldBehavior } = userInput;
const { toHex } = colors;


function fileUploadBehavior(id, fieldinst, beforeChange = () => {}) {
    const { defaultUrl, dataUrl, initialLabel, label } = fieldinst.value;


    return {
        id,
        label,
        defaultUrl,
        dataUrl,
        onchange(file) {
            beforeChange();
            fieldinst.showError = true;

            fieldinst.value = {
                file,
                initialLabel,
                dataUrl: null,
                defaultUrl,
                label: (file)
                    ? file.name
                    : (defaultUrl)
                        ? initialLabel
                        : 'Upload file',
            };
        },
    };
}

const BrandingPreview = {
    view({ attrs: { primaryColor, wordmark, dbaName } }) {
        const { defaultUrl, dataUrl } = wordmark.value;

        const src = dataUrl || defaultUrl;
        const name = (src)
            ? ''
            : (dbaName || 'YOUR DISPLAY NAME');

        const clone = colors.inputStringToColor(primaryColor.value.input);

        const borderColor = (clone.model)
            ? toHex(primaryColor.value).input
            : (primaryColor.value.lastValid)
                ? toHex(primaryColor.value.lastValid).input
                : '#000';

        return m('.BrandingPreview', { style: `border-bottom: 4px solid ${borderColor}` },
            m('img.BrandingPreview__wordmark', {
                src,
            }),
            m('p.BrandingPreview__text',
                `${name} is requesting information from you.`));
    },
};


const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        if (!App.user.selectedRp) {
            return m(Spinner);
        }

        const { branding } = App.user.selectedRp.settings;
        const { dbaName, wordmark, primaryColor } = branding.fields;
        const saveHistory = () => App.user.selectedRp.settings.saveHistory();

        return m(AdminLayout, { App, subtitle: 'Branding Settings' },
            m('.BrandingSettings',
                m('.BrandingSettings__visualAid',
                    m(BrandingPreview, {
                        wordmark,
                        primaryColor,
                        dbaName,
                    })),
                m('.BrandingSettings__form',
                    m(formBem.block,
                        m(formBem.fieldset,
                            m(formBem.field,
                                m(`label.${fieldBem.label}`, 'Company Name:'),
                                m(`input.${fieldBem.input}`, textFieldBehavior(dbaName, harness('config-branding-rp-name-input', {}), saveHistory)),
                                dbaName.errorText && m('p.fg-danger', dbaName.errorText),
                            ),
                        ),
                        m(formBem.fieldset,
                            m(formBem.field,
                                m(`label.${fieldBem.label}`, 'Wordmark in PNG or JPG format (1 MB max.):'),
                                m(InlineFileInput,
                                    harness('config-branding-rp-wordmark-input', fileUploadBehavior('branding-wordmark', wordmark, saveHistory))),
                                wordmark.errorText && m('p.fg-danger', wordmark.errorText),
                            ),
                        ),
                        m(formBem.fieldset,
                            m(formBem.field,
                                m(`label.${fieldBem.label}`, 'Primary Branding Color, Hexadecimal (ex: #ff5a60) or RGB (ex: 255,90,96)'),
                                m(ColorControl, harness('config-branding-primary-color-input', {
                                    id: 'branding-color',
                                    color: primaryColor.value,
                                    beforechange: () => saveHistory(),
                                    onchange(color) {
                                        primaryColor.value = color;
                                    },
                                }))
                            ),
                        ),
                    ))));
    },
};


export default withSelectedSetting(() => {
    spa.setView(Page, {
        layout: WithLeftNav,
    });
}, {
    settingName: 'branding',
});
