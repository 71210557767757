import { logger, m, strings, harness } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';
import * as attrViews from '#/evident-attributes/views';

import AttributeValueDialog from '#/rp-facing/views/AttributeValueDialog';
import JsonDump from '#/browser-framework/comps/JsonDump';

// This horror is needed to make sure that a bug
// that prints the wrong answer does not reproduce.
// Side-effect of our terrible data model.
const violationsFound = (value) =>
    ((Array.isArray(value))
        ? value.length > 0
        : (typeof value === 'object')
            ? (value === null)
                ? false
                : Object.keys(value).length > 0
            : Boolean(value));


const OpenDialogControl = {
    view({
        attrs: {
            app,
            attribute,
        },
        children,
    }) {
        return m(pointable('.OpenDialogControl'), {
            onpointerup() {
                app.showDialog(AttributeValueDialog, attribute);
            },
        }, children);
    },
};

const inDialog = (attribute, app) => m(OpenDialogControl, { app, attribute },
    m(Named.Icon, { name: 'image' }),
    m('strong', ' Click to view'));

const objectTypeMappings = {
    BinaryData: inDialog,
    CapturedDocument: inDialog,
};

export const Candidates = [
    {
        test: ({isError}) => isError,
        match: () => 'Invalid',
    },
    {
        test: ({status}) => status === 'rejected',
        match: () => 'Rejected',
    },
    {
        // Caution: This will render values that don't look like values, e.g. value === ''.
        // `null` is assumed to be a canonical way for VerifyAPI to say "This has no value."
        test: ({ status, value }) => status === 'pending' && value === null,
        match: () => 'Pending',
    },
    {
        test: ({ path }) => path === 'background.criminal.records_detail',
        match: (attribute, app) => {
            if (!violationsFound(attribute.value)) {
                return 'No Records Found';
            }

            // TODO: This is another dirty hack to make this work with a data-type centric renderer...
            // This can cause subtle bugs because this is a state change during an active
            // render pass, and I'm not going to write made-up data types for a curation pipeline
            // just for the sake of this one attribute type. Get buy-in to stop this madness.
            attribute.value.$objectType = 'CriminalReport';
            attribute.label = `${attribute.value.length} Cases`;

            return m(OpenDialogControl, { app, attribute },
                m('strong.fg-brandprimary', `${attribute.value.length} Cases Found`));
        },
    },
    {
        test({ value }) {
            // Catch all cases where we can't deconstruct value.
            try {
                const { $objectType } = value;

                return Boolean(objectTypeMappings[$objectType] || attrViews[$objectType]);
            } catch (e) {
                return false;
            }
        },
        match(attr, app) {
            const { value: { $objectType } } = attr;

            return (objectTypeMappings[$objectType])
                ? objectTypeMappings[$objectType](attr, app)
                : m(attrViews[$objectType], attr.value);
        },
    },
    {
        test: ({ value }) => {
            return (
                Array.isArray(value) &&
                value.every(({ $objectType }) => $objectType === 'Degree'));
        },
        match({ value }) {
            return m(attrViews.DegreeList, {
                degrees: value,
            });
        },
    },
    {
        test: ({status, value}) => (
            status === 'unavailable' &&
            (value === undefined || value === null)
        ),
        match: () => 'Record not found',
    },
    {
        test: ({status, value}) => (
            (['error-IdentityResolution', 'shared'].includes(status)) &&
            (value === undefined || value === null)
        ),
        match: () => 'Invalid',
    },
    {
        test: ({ status, value }) => status === 'terminated' && (value === undefined || value === null),
        match: () => 'Unsubscribed',
    },
    {
        test: ({ status, value }) => status === 'subscribed' && (value === undefined || value === null),
        match: () => 'Subscribed',
    },
    {
        test: ({value}) => value === undefined || value === null,
        match: () => 'Pending',
    },
    {
        test: ({path}) => path === 'core.dateofbirth' || /^certifications\.first_aid_cpr\.[\S]+_expiration$/.test(path),
        match: ({value: {month, day, year}}) => `${month}/${day}/${year}`,
    },
    {
        test: ({path}) => /^driverslicense\.[\S]+\.status$/.test(path),
        match: (({value}) => {
            return (typeof value === 'string')
                ? strings.capitalizeWord(value)
                : (value) ? 'Valid' : 'Invalid';
        }),
    },
    {
        test: ({ path }) => path === 'background.driving.records_detail',
        match: JsonDump,
    },
    {
        test: ({path}) => path === 'background.business.profile.googlelocalsvcs',
        match: ({value}) => ((value) ? 'Fail' : 'Pass'),
    },
    {
        test: ({path}) => path.startsWith('background.civil.profile.googlelocalsvcs'),
        match: ({value}) => ((value) ? 'Fail' : 'Pass'),
    },
    {
        test: ({path}) => path.startsWith('background.criminal.profile.googlelocalsvcs'),
        match: ({value}) => ((value) ? 'Fail' : 'Pass'),
    },
    {
        test: ({path}) => path.startsWith('background.driving.license_class') ||
            path.startsWith('background.driving.license_endorsement'),
        match: ({value}) => ((value) ? 'Valid' : 'Invalid'),
    },
    {
        test: ({path}) => /^background\.(criminal|driving)\./.test(path),
        match: ({value}) => ((violationsFound(value)) ? 'Policy Violations Found' : 'Clear'),
    },
    {
        test: ({path}) => path === 'externalidentity.capitalone.authenticated',
        match: ({value}) => ((value) ? 'Authenticated' : 'Unauthenticated'),
    },
    {
        test: ({path}) => path === 'kba.driving.va_verified',
        match: ({value}) => ((value) ? 'Verified' : 'Unverified'),
    },
    {
        test: ({value, path}) => {
            return (
                typeof value === 'string' && (
                    path === 'idverify1.driverslicense.verificationstatus' ||
                    path === 'idverify1.driverslicense.international_verificationstatus'
                )
            );
        },
        match: ({value}) => ((value === 'pass')
            ? 'Verified'
            : (value === 'fail')
                ? 'Failed to Verify'
                : strings.capitalizeWord(value)),
    },
    {
        test: ({ path }) => {
            return path.startsWith('non_fcra.reputation.criminal.profile.');
        },
        match: ({ value }) => ((value) ? 'Policy Violations Found' : 'Clear'),
    },
    {
        test: ({ path }) => {
            return path === 'background.watchlist.full_search.match_summary';
        },
        match: ({ value }) => ((value) ? 'Hits Found' : 'Clear'),
    },
    {
        test: ({ value }) => typeof value === 'boolean',
        match: ({ value }) => ((value) ? 'Valid' : 'Invalid'),
    },
    {
        test: () => true,
        match: JsonDump,
    },
];


export default {
    view({
        attrs: {
            app,
            attr: subjectiveAttr,
        },
    }) {
        // A test is guarenteed to pass.
        try {
            const candidate = Candidates.find(({test}) => {
                return test(subjectiveAttr, app);
            });

            return m('span.AttributeValue',
                harness('request-detail-requested-attribute-status'),
                candidate.match(subjectiveAttr, app));
        } catch (e) {
            logger.error(e);
            return null;
        }
    },
};
