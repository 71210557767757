// TODO: Leverage code-splitting.

import AuthPage from '#/rp-facing/views/pages/Auth/Auth';
import BusinessVerification from '#/rp-facing/views/pages/Onboarding/BusinessVerification';
import ConfigureAdverseAction from '#/rp-facing/views/pages/ConfigureAdverseAction';
import ConfigureApi from '#/rp-facing/views/pages/ConfigureApi';
import ConfigureBranding from '#/rp-facing/views/pages/ConfigureBranding';
import ConfigureConfirmMsg from '#/rp-facing/views/pages/ConfigureConfirmMsg';
import ConfigureFcra from '#/rp-facing/views/pages/ConfigureFcra';
import ConfigureInternalSettings from '#/rp-facing/views/pages/ConfigureInternalSettings';
import ConfigureLinkExpiration from '#/rp-facing/views/pages/ConfigureLinkExpiration';
import ConfigureMvr from '#/rp-facing/views/pages/ConfigureMvr';
import CreateRequest from '#/rp-facing/views/pages/CreateRequest/CreateRequest';
import DelegationInstructions from '#/rp-facing/views/pages/Onboarding/DelegationInstructions';
import DelegationCountrySelection from '#/rp-facing/views/pages/Onboarding/DelegationCountrySelection';
import DelegationDone from '#/rp-facing/views/pages/Onboarding/DelegationDone';
import DelegationDuplexScan from '#/rp-facing/views/pages/Onboarding/DelegationDuplexScan';
import DelegationPassportScan from '#/rp-facing/views/pages/Onboarding/DelegationPassportScan';
import { ErrorView } from '#/rp-facing/views/pages/ErrorPages/ErrorPage';
import IsolatedTermsAndConditions from '#/rp-facing/views/pages/Onboarding/IsolatedTermsAndConditions';
import OnboardingIdScan from '#/rp-facing/views/pages/Onboarding/OnboardingIdScan';
import PasswordReset from '#/rp-facing/views/pages/PasswordReset';
import PlanSelection from '#/rp-facing/views/pages/Onboarding/PlanSelection';
import PhoneNumberPrompt from '#/rp-facing/views/pages/Onboarding/PhoneNumberPrompt';
import PaymentStep from '#/rp-facing/views/pages/Onboarding/PaymentStep';
import OnboardingTermsAndConditions from '#/rp-facing/views/pages/Onboarding/OnboardingTermsAndConditions';
import Registration from '#/rp-facing/views/pages/Auth/Registration';
import RequestDashboard from '#/rp-facing/views/pages/RequestDashboard/RequestDashboard';
import RequirementsOverview from '#/rp-facing/views/pages/Onboarding/RequirementsOverview';
import RequestDetailPage from '#/rp-facing/views/pages/RequestDetail/RequestDetail';
import ScheduleConsultingCall from '#/rp-facing/views/pages/Onboarding/ScheduleConsultingCall';
import ScheduleOnboardingCall from '#/rp-facing/views/pages/Onboarding/ScheduleOnboardingCall';
import CustomPackageContactDetails from '#/rp-facing/views/pages/Onboarding/CustomPackageContactDetails';
import VerifyEmail from '#/rp-facing/views/pages/VerifyEmail';
import WalkthroughVapiAs from '#/rp-facing/views/pages/DeveloperDocs/VapiAs';
import WalkthroughVapiEidUi from '#/rp-facing/views/pages/DeveloperDocs/VapiEidUi';
import WalkthroughVapiSapi from '#/rp-facing/views/pages/DeveloperDocs/VapiSapi';
import WalkthroughWebhooks from '#/rp-facing/views/pages/DeveloperDocs/Webhooks';
import { EmailSettingsPage } from '#/rp-facing/views/pages/ConfigureEmails/EmailSettingsPage';

import {
    docsEnabled,
    loggedOutOnly,
    verifiedEmailOnly,
    unverifiedEmailOnly,
    onboardedOnly,
    notOnboardedOnly,
    withFreshUserIdToken,
    scrollToAnchor,
} from './routeHandlerDecorators';


// All routing table entries are decorated using this.
const entry = (title, route, handler) => ({
    title,
    route,
    handler,
});


export const ROUTE_TABLE = [
    entry(
        'Log in',
        '/',
        loggedOutOnly(AuthPage),
        { gaCanonicalPath: '/auth' }),
    entry(
        'Log in',
        '/auth',
        loggedOutOnly(AuthPage)),
    entry(
        'Register Account',
        '/register',
        loggedOutOnly(Registration)),
    entry(
        'Reset Password',
        '/forgot-password',
        loggedOutOnly(PasswordReset)),
    entry(
        'Error',
        '/error',
        ErrorView),
    entry(
        'Error',
        '/error/:reason',
        ErrorView,
        { gaCanonicalPath: '/error' }),
    entry(
        'Select your package',
        '/setup/plan-selection',
        notOnboardedOnly(PlanSelection)),
    entry(
        'Verify your business',
        '/setup/verify-business',
        notOnboardedOnly(BusinessVerification)),
    entry(
        'Verify your ID',
        '/setup/verify-id',
        notOnboardedOnly(OnboardingIdScan)),
    entry(
        'Please provide phone number',
        '/setup/contact',
        notOnboardedOnly(withFreshUserIdToken(PhoneNumberPrompt))),
    entry(
        'Please provide payment information',
        '/setup/billing',
        notOnboardedOnly(PaymentStep)),
    entry(
        'Scan your ID on your phone',
        '/setup/delegation',
        notOnboardedOnly(DelegationInstructions)),
    entry(
        'Onboarding',
        '/setup/overview',
        notOnboardedOnly(RequirementsOverview)),
    entry(
        'Schedule an onboarding call',
        '/setup/schedule-onboarding-call',
        notOnboardedOnly(ScheduleOnboardingCall)),
    entry(
        'Schedule a consulting call',
        '/setup/schedule-consulting-call',
        notOnboardedOnly(ScheduleConsultingCall)),
    entry(
        'Build Your Own Package',
        '/setup/custom-package-contact',
        notOnboardedOnly(CustomPackageContactDetails.routeTableHandler)),
    entry(
        'Scan your ID document',
        '/setup/delegate/start/:rprid',
        DelegationCountrySelection),
    entry(
        'Scan your ID document',
        '/setup/delegate/duplex',
        DelegationDuplexScan),
    entry(
        'Scan your ID document',
        '/setup/delegate/passport',
        DelegationPassportScan),
    entry(
        'Scan your ID document',
        '/setup/delegate/done',
        DelegationDone),
    entry(
        'Terms',
        '/setup/terms',
        notOnboardedOnly(OnboardingTermsAndConditions)),
    entry(
        'Verify your email',
        '/verify-email',
        unverifiedEmailOnly(VerifyEmail)),
    entry(
        'Verify your email',
        '/verify-email/:actioncode',
        unverifiedEmailOnly(VerifyEmail)),
    entry(
        'Terms',
        '/:rpname/terms',
        verifiedEmailOnly(IsolatedTermsAndConditions)),
    entry(
        'Dashboard',
        '/:rpname/requests',
        onboardedOnly(RequestDashboard)),
    entry(
        'Create Request',
        '/:rpname/requests/create',
        onboardedOnly(CreateRequest)),
    entry(
        'Request',
        '/requestdetail/:rpname/:id',
        onboardedOnly(RequestDetailPage)),
    entry(
        'Request',
        '/requests/view/:rpname/:id',
        onboardedOnly(RequestDetailPage)),
    entry(
        'Request',
        '/:rpname/requests/view/:id',
        onboardedOnly(RequestDetailPage)),
    entry(
        'API Settings',
        '/:rpname/admin/configure-api',
        onboardedOnly(ConfigureApi)),
    entry(
        'Branding Settings',
        '/:rpname/admin/branding',
        onboardedOnly(ConfigureBranding)),
    entry(
        'Email Settings',
        '/:rpname/admin/email',
        onboardedOnly(EmailSettingsPage)),
    entry(
        'MVR Policy Settings',
        '/:rpname/admin/mvr',
        onboardedOnly(ConfigureMvr)),
    entry(
        'FCRA Settings',
        '/:rpname/admin/fcra',
        onboardedOnly(ConfigureFcra)),
    entry(
        'Confirmation Message Settings',
        '/:rpname/admin/confirmation-message',
        onboardedOnly(ConfigureConfirmMsg)),
    entry(
        'Adverse Action Settings',
        '/:rpname/admin/adverse-action',
        onboardedOnly(ConfigureAdverseAction)),
    entry(
        'Link Expiration Settings',
        '/:rpname/admin/link-expiration',
        onboardedOnly(ConfigureLinkExpiration)),
    entry(
        'Internal Settings',
        '/:rpname/admin/internal',
        onboardedOnly(ConfigureInternalSettings)),
    entry(
        'VerifyAPI and AssureSDK',
        '/:rpname/docs/verify-api-assure-sdk',
        docsEnabled(onboardedOnly(WalkthroughVapiAs))),
    entry(
        'VerifyAPI and Evident Submit UIs',
        '/:rpname/docs/verify-api-eid-submit-uis',
        docsEnabled(onboardedOnly(WalkthroughVapiEidUi))),
    entry(
        'VerifyAPI and SubmitAPI',
        '/:rpname/docs/verify-api-submit-api',
        docsEnabled(onboardedOnly(WalkthroughVapiSapi))),
    entry(
        'Webhooks',
        '/:rpname/docs/webhooks',
        docsEnabled(onboardedOnly(WalkthroughWebhooks))),
    entry(
        'VerifyAPI and AssureSDK',
        '/:rpname/docs/verify-api-assure-sdk/:anchor',
        docsEnabled(scrollToAnchor(onboardedOnly(WalkthroughVapiAs)))),
    entry(
        'VerifyAPI and Evident Submit UIs',
        '/:rpname/docs/verify-api-eid-submit-uis/:anchor',
        docsEnabled(scrollToAnchor(onboardedOnly(WalkthroughVapiEidUi)))),
    entry(
        'VerifyAPI and SubmitAPI',
        '/:rpname/docs/verify-api-submit-api/:anchor',
        docsEnabled(scrollToAnchor(onboardedOnly(WalkthroughVapiSapi)))),
];
