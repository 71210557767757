import { m, colors, bem } from '#/browser-framework';
import { fieldBem } from '#/rp-facing/styles/FormsBem';

export function getPreviewColor(color) {
    const usableForPreview = (color.model)
        ? color
        : (color.lastValid)
            ? color.lastValid
            : {r: 255, g: 255, b: 255};
    const {r, g, b} = usableForPreview;
    return usableForPreview.model === 'hex'
        ? usableForPreview.input
        : `rgb(${r}, ${g}, ${b})`;
}

const colorFieldBem = bem`
    ColorControl
        preview
        unknown
`;

export const ColorControl = {
    oninit({attrs}) {
        if (attrs.color && attrs.color.model !== undefined) {
            attrs.color.lastValid = attrs.color;
        }
    },
    view({attrs: {id, color, beforechange, onchange}}) {
        const {model, input} = color;

        function oninput(e) {
            beforechange();
            const inputColor = colors.inputStringToColor(e.target.value);

            if (inputColor.model) {
                Object.assign(color, inputColor, {
                    lastValid: inputColor,
                });
            } else {
                color.input = inputColor.input;
            }
            onchange(color);
        }

        return m(colorFieldBem.block,
            m(`input.${fieldBem.input}`, {
                id,
                value: input,
                oninput,
            }),
            m(`label.${colorFieldBem.preview}`, {
                style: {
                    background: getPreviewColor(color),
                },
            }, m(`input.${fieldBem['color-input']}`, {
                type: 'color',
                value: input,
                oninput,
            }, (model === undefined && color.lastValid === undefined)
                ? m(`label.${colorFieldBem.unknown}`, '?')
                : null)));
    },
};
