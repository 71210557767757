import { harness, logger, m, router, spa } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';

import { facebookSignIn, googleSignIn } from '#/browser-framework/firebase';

function handlePopUpError(e) {
    // It's okay if the user closed the pop-up.
    const whitelist = [
        'auth/cancelled-popup-request',
        'auth/popup-closed-by-user',
    ];

    if (!whitelist.includes(e.code)) {
        spa.viewModel.err = e;
        logger.error(e);

        if (e.code === 'auth/user-disabled') {
            spa.viewModel.user.credentials.error = new Error('That account is disabled.');
        } else if (e.code === 'auth/account-exists-with-different-credential') {
            spa.viewModel.user.credentials.error = new Error(`We have that account, but under
            a different provider. Use the method that you originally used to sign-in.`);
        } else {
            router.go('/error');
        }
    }

    spa.redraw();
}


export const GoogleButton = {
    view() {
        return m(pointable('button.OAuthButton'), harness('google-auth-button', {
            onclick: (e) => {
                // Keeps button from redirecting user to /?
                e.preventDefault();
            },
            onpointerup: spa.redrawAfter((e) => {
                e.preventDefault();

                googleSignIn(spa.$window).catch((err) =>
                    handlePopUpError(err));
            }),
        }),
        m('span.OAuthButton__icon', m(Named.Vector, { name: 'google-logo' })),
        m('span.OAuthButton__text', 'Sign in with Google'));
    },
};


export const FacebookButton = {
    view() {
        return m(pointable('button.OAuthButton.Facebook'), harness('facebook-auth-button', {
            onclick: (e) => {
                // Keeps button from redirecting user to /?
                e.preventDefault();
            },
            onpointerup: spa.redrawAfter((e) => {
                e.preventDefault();

                facebookSignIn(spa.$window).catch((err) =>
                    handlePopUpError(err));
            }),
        }),
        m('span.OAuthButton__icon', m(Named.Icon, { name: 'facebook-logo' })),
        m('span.OAuthButton__text', 'Sign in with Facebook'));
    },
};
