import { m } from '#/browser-framework';
import ErrorPrivacy from '#/rp-facing/images/error_privacy.svg';

export const AccessDenied = {
    view() {
        return m('.ErrorPage',
            m('img.ErrorPage__icon', {
                src: ErrorPrivacy,
            }),
            m('.ErrorPage__summary',
                m('h2.PageNotFound__summary', 'You don\'t have permission to view this Evident account'),
                m('p',
                    'We take privacy pretty seriously. In order to access this account, you will need',
                    m('br'),
                    'permission from the account administrator'),
            ),
        );
    },
};
