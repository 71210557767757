import { once } from '#/universal-framework/async';
import json from '#/universal-framework/json';
import { logger, router, spa, validation, userInput } from '#/browser-framework';
import { rpAdminController } from '#/rp-facing/core/rpAdminController';
import { onboardingController } from '#/rp-facing/core/onboarding';
import { rpWebClient } from './rpWebClient';
import { woopraTrackLogin, setWoopraEmail } from '#/browser-framework/woopraLib';


const { field, inputlock } = userInput;
const { isEmail, NotAnEmail, RequiredValue } = validation;


const emailOnly = (v) => {
    if (!isEmail(v)) {
        throw NotAnEmail({
            message: 'Invalid email address',
        });
    }
    return Promise.resolve();
};

const checkPassword = (len) => (v) => {
    if (v.length === 0) {
        throw RequiredValue({
            message: 'Password is required',
        });
    }
    if (v.length < len) {
        throw new Error(`Password must be at least ${len} characters long.`);
    }
};


function credentialsFormModel(userInstance) {
    return inputlock({
        fields: {
            email: field({
                id: 'rp-facing-login-email-address',
                initialValue: '',
                validate: emailOnly,
            }),
            password: field({
                id: 'rp-facing-login-password',
                initialValue: '',

                // This is weaker because users have ways to make
                // Firebase set a password that contradicts the
                // policy we claim to have for registration.
                validate(v) {
                    if (v.length === 0) {
                        throw RequiredValue({ message: 'Password is required' });
                    }
                },
            }),
        },
        async fire(lock) {
            try {
                userInstance.firebaseProfile = undefined;

                await spa
                    .$window
                    .firebase
                    .auth()
                    .signInWithEmailAndPassword(
                        lock.fields.email.value,
                        lock.fields.password.value);

                // Do not keep creds hanging around in memory.
                lock.fields.email.value =
                lock.fields.password.value = '';
            } catch (e) {
                console.log(e);
                userInstance.firebaseProfile = null;

                switch (e.code) {
                case 'auth/invalid-email':
                case 'auth/wrong-password':
                    lock.error = new Error('Either the email or the password appears incorrect.');
                    break;

                case 'auth/user-disabled':
                    lock.error = new Error('This account has been disabled.');
                    break;

                case 'auth/user-not-found':
                    lock.error = new Error(`We could not find an active account for this email
                    in our records. Please create this account.`);
                    break;

                case 'auth/weak-password':
                    lock.error = new Error('Password should be at least 12 characters long.');
                    break;

                case 'auth/email-already-in-use':
                    lock.error = new Error('It looks like that email is already in our system.');
                    break;

                default:
                    router.go('/error');
                    break;
                }
            }
        },
    });
}

function registrationFormModel(userInstance) {
    return inputlock({
        fields: {
            email: field({
                id: 'email-field',
                initialValue: '',
                validate: emailOnly,
            }),
            password: field({
                id: 'password-field',
                initialValue: '',
                validate: checkPassword(12),
            }),
            repeat: field({
                id: 'password-field-repeat',
                initialValue: '',
                validate(v, lock) {
                    if (!lock.fields.password.value || lock.fields.password.value !== v) {
                        throw new Error('Passwords must match');
                    }
                },
            }),
        },
        async fire(lock) {
            try {
                await spa
                    .$window
                    .firebase
                    .auth()
                    .createUserWithEmailAndPassword(
                        lock.fields.email.value,
                        lock.fields.password.value);

                lock.fields.email.value =
                    lock.fields.password.value =
                    lock.fields.repeat.value = '';

                router.go('/verify-email');
            } catch (e) {
                console.log(e);
                userInstance.firebaseProfile = null;

                switch (e.code) {
                case 'auth/invalid-email':
                    lock.error = new Error('Either the email or the password appears incorrect.');
                    break;

                case 'auth/weak-password':
                    lock.error = new Error('Password should be at least 12 characters long.');
                    break;

                case 'auth/email-already-in-use':
                    lock.error = new Error('It looks like that email is already in our system.');
                    break;

                default:
                    router.go('/error');
                    break;
                }
            }
        },
    });
}

function passwordResetFormModel() {
    return inputlock({
        id: 'reset-request',
        fields: {
            email: field({
                id: 'rp-facing-login-email-address',
                initialValue: '',
                validate: emailOnly,
            }),
        },
        async fire(lock) {
            try {
                await spa
                    .$window
                    .firebase
                    .auth()
                    .sendPasswordResetEmail(lock.fields.email.value, {
                        url: Object.assign(new URL(spa.$window.location.href), {
                            hash: '#!/auth',
                        }).toString(),
                    });

                lock.feedback = 'Thanks! Check your email for the link.';
                lock.hold('permanent');
            } catch (e) {
                switch (e.code) {
                case 'auth/user-not-found':
                    lock.error = new Error('That email does not appear to be in our system.');
                    break;
                case 'auth/invalid-email':
                    lock.error = new Error('Invalid email.');
                    break;
                default:
                    lock.error = e;
                    break;
                }
            }
        },
    });
}


export default function user(firebaseProfile, jwt, tokenResult) {
    logger.bindUserContext((firebaseProfile)
        ? firebaseProfile.email
        : null);


    const iface = {
        firebaseProfile,

        get ascertained() {
            return iface.firebaseProfile !== undefined;
        },

        trackLogin: () => {
            if (iface.firebaseProfile.providerData) {
                const providerId = iface.firebaseProfile.providerData[0].providerId;
                woopraTrackLogin(providerId, iface.email);
            }
        },

        isGuest: () =>
            iface.ascertained && iface.firebaseProfile === null,

        isDoneOnboarding: () =>
            iface.onboarding && iface.onboarding.isDoneOnboarding(),

        isEvidentAffiliate: () =>
            (iface.firebaseProfile &&
            (iface.firebaseProfile.email.endsWith('@evidentid.com') ||
                iface.firebaseProfile.email === deploy.WEB_PUBLIC_TEST_GOOGLE_USERNAME)),

        hasVerifiedEmail: () =>
            (tokenResult && tokenResult.claims.email_verified),

        get selectedRp() {
            return (iface.dominion && iface.dominion.rp && iface.dominion.rp.loaded)
                ? iface.dominion.rp
                : null;
        },

        // @todo - investigate / write a test to document logged and verified user behavior
        isLoggedIn: () => !iface.isGuest() && iface.hasVerifiedEmail(),

        loadFormModels: once(() => Promise.all([
            credentialsFormModel(iface),
            registrationFormModel(iface),
            passwordResetFormModel(iface),
        ]).then(([
            credentials,
            registration,
            resetRequest,
        ]) => Object.assign(iface, {
            credentials,
            registration,
            resetRequest,
        }))),
    };

    iface.maySelectAuthDomain = () => (
        iface.isEvidentAffiliate() &&
        iface.dominion.rp &&
        iface.dominion.rp.name === 'evidentid'
    );

    iface.mayResetIdo = () => (
        iface.isEvidentAffiliate() &&
        json.boolean(deploy.WEB_PUBLIC_RPWEB_ENABLE_IDO_RESET, false)
    );

    iface.clear = () => {
        if (iface.onboarding) {
            iface.onboarding.clear();
        }
    };

    if (jwt) {
        iface.client = rpWebClient(jwt);
        iface.dominion = rpAdminController(iface.client);
        iface.onboarding = onboardingController(iface);
    }

    iface.email = (firebaseProfile) ? firebaseProfile.email : '';
    iface.displayName = (firebaseProfile) ? (firebaseProfile.displayName || '') : '';
    iface.photoURL = (firebaseProfile) ? (firebaseProfile.photoURL || '') : '';

    // set the woopra email to the user's email here
    setWoopraEmail(iface.email);
    const names = iface.displayName.split(/\s+/g);

    iface.firstName = names[0];
    iface.lastName = names[names.length - 1];

    return iface;
}
