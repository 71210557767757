import { m, spa } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { RpView } from '#/rp-facing/views/RpView';
import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import RequestTable from './table/RequestTable';
import SentVsCompleted from './charts/SentVsCompleted';
import ShareRate from './charts/ShareRate';
import CompletionTimes from './charts/CompletionTimes';
import AnalyticsChart from './charts/AnalyticsChart';


function didWeHitTheBottom(vnode) {
    const gridcol = document.querySelector('.WithLeftNav__content');

    if (!vnode.dom || !gridcol || vnode.attrs.viewModel.user.selectedRp.queriedRequests.noMoreToLoad) {
        return false;
    }

    const scrolled = window.scrollY + window.innerHeight;
    const hitBottom = Math.abs(scrolled - document.body.scrollHeight) < 200;

    return hitBottom;
}


const Charts = {
    view: ({attrs: {queriedRequests: q}}) => {
        return m('.RequestDashboard__charts',
            m(AnalyticsChart, {
                label: 'Sent Vs. Completed',
                metrics: q.analytics,
                chart: SentVsCompleted,
                startDate: q.startDate,
                endDate: q.endDate,
            }),
            m(AnalyticsChart, {
                label: 'Share Rate',
                metrics: q.analytics,
                chart: ShareRate,
                startDate: q.startDate,
                endDate: q.endDate,
            }),
            m(AnalyticsChart, {
                label: 'Completion Times',
                metrics: q.analytics,
                chart: CompletionTimes,
                startDate: q.startDate,
                endDate: q.endDate,
            }));
    },
};

let filterCloseListener;
let scrollListenerInterval;

function clearListeners() {
    if (scrollListenerInterval) {
        clearInterval(scrollListenerInterval);
        scrollListenerInterval = null;
    }

    if (filterCloseListener) {
        document.removeEventListener('pointerup', filterCloseListener);
        filterCloseListener = null;
    }
}

function addListeners(queriedRequests, vnode) {
    scrollListenerInterval = setInterval(() => {
        if (didWeHitTheBottom(vnode) && queriedRequests.requests.length > 0 && !queriedRequests.waitingForMore) {
            queriedRequests.loadMore();
        }
    }, 300);

    filterCloseListener = () => {
        if (queriedRequests) {
            queriedRequests.closeAllFilterMenus();
        }
    };
    document.addEventListener('pointerup', filterCloseListener);
}

const RequestDashboard = RpView({
    scrollListener: null,
    filterCloseListener: null,
    onupdate: (vnode) => {
        const app = vnode.attrs.viewModel;
        const { queriedRequests } = app.user.selectedRp;
        clearListeners();
        addListeners(queriedRequests, vnode);
    },
    onremove() {
        clearListeners();
    },
    view({
        attrs: {
            vars: {
                rp,
            },
        },
    }) {
        if (!rp || rp.queriedRequests.waitingForInitial) {
            return m(Spinner);
        }

        const { queriedRequests } = rp;

        return m('.RequestDashboard',
            m(Charts, {queriedRequests}),
            m(RequestTable, { rp }),
            (queriedRequests.noMoreToLoad)
                ? null
                : m('.RequestDashboard__waitForMore', m(Spinner)));
    },
});

function loadDashboardData(rp) {
    const vowFilters = rp.api({ url: '/requestFilters' });
    const vowRequestData = (rp.queriedRequests.hasRequests())
        ? Promise.resolve()
        : rp.queriedRequests.hardRefresh();

    return Promise
        .all([vowFilters, vowRequestData])
        .then(([filters]) => {
            for (const k of Object.keys(filters)) {
                rp.queriedRequests.loadFilterMenu(k, filters[k]);
            }
        });
}


export default ensureRpSelection(([rp]) => {
    return loadDashboardData(rp).then(() => {
        spa.setView(RequestDashboard, {
            layout: WithLeftNav,
            vars: {
                rp,
            },
        });
    });
}, {
    pendingLayout: WithLeftNav,
});
