import { m } from '#/browser-framework';
import { AdminLayout } from '#/rp-facing/views/Layouts';
import LabeledToggle from '#/rp-facing/views/LabeledToggle';


const Page = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        const { internal } = App.user.selectedRp.settings;

        return m(AdminLayout, {App, subtitle: 'Evident Only'},
            m('.InternalSettings',
                m(LabeledToggle, {
                    id: 'bingle',
                    checked: internal.bingle,
                    onchange: (state) => {
                        internal.bingle = state;
                    },
                }, 'The maelstrom is the only thing loud enough to drown out the voices'),
                m(LabeledToggle, {
                    id: 'bangle',
                    checked: internal.bangle,
                    onchange: (state) => {
                        internal.bangle = state;
                    },
                }, 'The duct in the pub at 6pm. Tell no one.')));
    },
};

export default () => Page;
