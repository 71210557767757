import { bem, m, spa, router } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { CheckmarkList } from '#/rp-facing/views/CheckmarkList';

import { woopraTrackSetupBilling, woopraTrackCompleteStep } from '#/browser-framework/woopraLib';



const fieldbtx = bem`
    CheckoutFieldSet
        field
        label
`;


export const CheckoutForm = {
    oncreate({
        attrs: {
            pos,
        },
    }) {
        pos.mountElement('cardNumber');
        pos.mountElement('cardExpiry');
        pos.mountElement('cardCvc');
        pos.mountElement('postalCode');
    },
    onremove({
        attrs: {
            pos,
        },
    }) {
        pos.destroyAll();
    },
    view({
        attrs: {
            pos,
        },
    }) {
        return m('form#pay.CheckoutForm',
            m('h2.CheckoutForm__heading',
                'Billing information'),
            pos.formError && m('.alertNotice', pos.formError),
            m('.CheckoutForm__fields',
                m(fieldbtx.field,
                    m(fieldbtx.label, 'Cardholder name'),
                    m('input', {
                        id: 'cardHolderName',
                        placeholder: 'Ann E. Body',
                        oninput(e) {
                            pos.cardHolderName = e.target.value;
                        },
                    }),
                    m('#cardHolderName-error', m.trust('&nbsp;'))),
                m(fieldbtx.field,
                    m(fieldbtx.label, 'Card number'),
                    m('#cardNumber'),
                    m('#cardNumber-error', m.trust('&nbsp;'))),
                m(fieldbtx.field,
                    m(fieldbtx.label, 'ZIP'),
                    m('#postalCode'),
                    m('#postalCode-error', m.trust('&nbsp;'))),
                m(fieldbtx.field,
                    m(fieldbtx.label, 'Expiration'),
                    m('#cardExpiry'),
                    m('#cardExpiry-error', m.trust('&nbsp;'))),
                m(fieldbtx.field,
                    m(fieldbtx.label, 'CVC'),
                    m('#cardCvc'),
                    m('#cardCvc-error', m.trust('&nbsp;')))));
    },
};


const BenefitsOverview = {
    btx: bem`BenefitsOverview
        heading
        subHeading
        item
        check
        text`,
    view() {
        const { btx } = BenefitsOverview;

        return m(btx.block,
            m(`h2${btx.heading}`, 'Pay as you grow'),
            m(btx.subHeading, 'What you get:'),
            m(CheckmarkList,
                'Run verifications when you need to, with no monthly commitments or obligations.',
                'Full access to the entire Evident verification library.',
                'Account funds roll over month to month.'),
            m(btx.subHeading, 'How it works:'),
            m(CheckmarkList,
                'When your first information verification is run, your account will be charged $250.',
                'These funds can be used for any information verification at any time, and do not expire.',
                'Your account is only re-billed when the available balance falls below $25.'));
    },
};


const PaymentStep = {
    btx: bem`
        PaymentStep
            heading
            subHeading
            form
            left
            right
            controls`,
    view({
        attrs: {
            viewModel: {
                user: {
                    onboarding: {
                        paymentFormModel,
                    },
                },
            },
            viewModel: {
                user: {
                    onboarding,
                },
            },
        },
    }) {
        const { btx } = PaymentStep;

        return m(btx.block,
            m(`h2${btx.heading}`,
                'Set up billing information'),
            m(btx.subHeading,
                'You won\'t be billed until you\'re ready to start verifying personal information.'),
            m(btx.form,
                m(btx.left,
                    m(BenefitsOverview)),
                m(btx.right,
                    (paymentFormModel.pos) && m(CheckoutForm, {
                        pos: paymentFormModel.pos,
                    }))),
            m('.OnboardingStepControls',
                m(Button, {
                    flags: {
                        secondary: true,
                    },
                    onpress() {
                        woopraTrackSetupBilling(paymentFormModel.pos.cardHolderName, true);
                        router.go('/setup/overview');
                    },
                }, 'Do this later'),
                m(Button, {
                    disabled: !paymentFormModel.maySubmit(),
                    flags: {
                        primary: true,
                        round: true,
                    },
                    onpress() {
                        woopraTrackSetupBilling(paymentFormModel.pos.cardHolderName, false);
                        woopraTrackCompleteStep(Boolean(onboarding.isUserVerified()),
                            true,
                            Boolean(onboarding.isBusinessVerified()),
                            Boolean(onboarding.areTermsSigned()));
                        paymentFormModel
                            .submit()
                            .then(() => router.go('/setup/overview'))
                            .catch((e) => spa.showError(e));
                    },
                }, 'Continue')));
    },
};

export default () => {
    spa.setView(PaymentStep, {
        layout: WithLeftNav,
    });
};
