import { EID_DATE_TYPE, EID_TIMESTAMP_TYPE } from './typeSystem';
import { contract, types } from './functions';

export const LONG_DATETIME = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
};

export const CLEAR_DATE = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
};


export const utcTimestampToLocaleString = contract(
    (timestamp, locale = 'en-US', dateFormatOptions = LONG_DATETIME) =>
        new Date(timestamp).toLocaleString(locale, dateFormatOptions),
    () => ({
        domain: [types.nonnegative, types.string.optional, types.object.optional],
        range: types.string,
        displayName: 'utcTimestampToLocaleString',
    }));

export const timestampObjectToLocaleString = contract(
    (timestampObject, fallback = '?') =>
        ((timestampObject && Number.isInteger(timestampObject.timestamp))
            ? utcTimestampToLocaleString(timestampObject.timestamp * 1000)
            : fallback),
    () => ({
        domain: [EID_TIMESTAMP_TYPE, types.string.optional],
        range: types.string,
        displayName: 'timestampObjectToLocaleString',
    }));

export const dateObjectToJsDate = contract(
    ({ year, month, day }) =>
        new Date(year, month - 1, day),
    () => ({
        domain: [EID_DATE_TYPE],
        range: types.instanceOf(Date),
        displayName: 'dateObjectToJsDate',
    }));

export const jsDateToEidDateObject = contract(
    (d) => ({
        $objectType: 'date',
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
    }),
    () => ({
        domain: [types.instanceOf(Date)],
        range: EID_DATE_TYPE,
        displayName: 'jsDateToEidDateObject',
    }));

export const dateObjectToLocaleString = contract(
    (dateObject, locale = 'en-US', dateFormatOptions = CLEAR_DATE, fallback = '?') =>
        ((dateObject)
            ? dateObjectToJsDate(dateObject).toLocaleString(locale, dateFormatOptions)
            : fallback),
    () => ({
        domain: [
            types.hopefully(EID_DATE_TYPE),
            types.string.optional,
            types.object.optional,
            types.string.optional,
        ],
        range: types.string,
        displayName: 'dateObjectToLocaleString',
    }));
