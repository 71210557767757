import { type, lets } from './functions';


export const _PRIMITIVES = [
    { dataType: 'StringValue' },
    { dataType: 'DateValue' },
    { dataType: 'MultilineString' },
];

// Returns closure that memoizes values referenced by keys.
export const _memoize = (fn) =>
    lets([new Map()], (_memo) =>
        ((k) => ((_memo.has(k))
            ? _memo.get(k)
            : _memo.set(k, fn(k)).get(k))));

export function evidentType(allTypesByName, containerOrMember, container) {
    const iface = {};

    iface.definition = allTypesByName[containerOrMember.dataType];
    iface.container = container;

    if (container) {
        iface.memberName = containerOrMember.memberName;
    }

    iface.isOptional = _memoize(() => ((typeof containerOrMember.optional === 'boolean')
        ? containerOrMember.optional
        : (iface.container)
            ? iface.container.isOptional()
            : false));

    iface.getMembers = _memoize(() => {
        const local = iface.definition.members || [];
        const withParent = (iface.definition.baseType)
            ? allTypesByName[iface.definition.baseType].members.concat(local)
            : local;

        return withParent.map((m) => evidentType(allTypesByName, m, iface));
    });

    iface.hasMembers = _memoize((t) =>
        iface.getMembers(t).length > 0);

    for (const m of iface.getMembers()) {
        iface[m.memberName] = m;
    }

    return iface;
}

export function evidentTypeSystem(types) {
    const iface = {};

    const _byName = types.reduce((p, c) => {
        p[c.dataType] = c;

        return p;
    }, {});

    for (const t of types) {
        iface[t.dataType] = evidentType(_byName, t);
    }

    return iface;
}

// Type declarations
export const declareEidObjType = (name, spec) =>
    type(({ shape }) =>
        shape(Object.assign({ $objectType: (v) => v === name }, spec)));

export const EID_DATE_TYPE = type(({ natural }) =>
    declareEidObjType('date', {
        month: natural,
        day: natural,
        year: natural,
    }));

export const EID_ADDRESS_TYPE = type(({ string }) =>
    declareEidObjType('Address', {
        street_number: string,
        street_name: string,
        city: string,
        state: string,
        postal_code: string,
        county: string,
        country: string,
        street_unit: string.optional,
        postal_code_suffix: string.optional,
        neighborhood: string.optional,
    }));

export const EID_NAME_TYPE = type(({ string }) =>
    declareEidObjType('Name', {
        prefix: string,
        suffix: string,
        first: string,
        middle: string,
        last: string,
    }));

export const EID_TIMESTAMP_TYPE = type(({ natural }) =>
    declareEidObjType('utctime', {
        timestamp: natural,
    }));

export const EID_CRIME_RECORD_TYPE = type(({
    isNull,
    object,
    or,
    string,
}) =>
    lets([
        or(isNull, string),
        or(isNull, EID_DATE_TYPE),
    ], (nullableString, nullableDate) =>
        declareEidObjType('CrimeRecord', {
            arrest_date: EID_DATE_TYPE,
            arresting_agency: nullableString,
            case_number: nullableString,
            case_type: string,
            category: nullableString,
            charges_filed_date: EID_DATE_TYPE,
            classification: nullableString,
            conviction_date: nullableDate,
            conviction_place: nullableString,
            counts: nullableString,
            county: nullableString,
            county_override: nullableString,
            court: nullableString,
            court_costs: nullableString,
            crime_county: nullableString,
            crime_type: nullableString,
            degree_of_offense: nullableString,
            disposition: nullableString,
            disposition_date: nullableString,
            extra_info: or(isNull, object),
            fines: nullableString,
            grade_of_offense: nullableString,
            ncic_code: nullableString,
            offense_code: nullableString,
            offense_date: nullableDate,
            offense_description1: nullableString,
            offense_description2: nullableString,
            plea: nullableString,
            predator: nullableString,
            probation: nullableString,
            registration_date: nullableDate,
            registration_end_date: nullableDate,
            sentence: nullableString,
            source_state: nullableString,
            status: nullableString,
            status_date: nullableDate,
            statute: nullableString,
            victim: nullableString,
            victim_age: nullableString,
            victim_gender: nullableString,
            victim_is_minor: nullableString,
            violation: nullableString,
            violation_date: nullableDate,
            warrant: nullableString,
            warrant_date: nullableDate,
        })));

export const EID_JURISDICTION_TYPE = type(({ string, hopefully }) =>
    declareEidObjType('Jurisdiction', {
        county: string,
        state: string,
        country: string,
        fips_number: hopefully(string), // We don't know if this is the 5- or 3- digit variant.
    }));

export const EID_MANUAL_JURISDICTION_RESULT_TYPE = type(({ arrayOf }) =>
    declareEidObjType('ManualJurisdictionResult', {
        criminal_records_jurisdictions: arrayOf(EID_JURISDICTION_TYPE),
        address_records_jurisdictions: arrayOf(EID_JURISDICTION_TYPE),
    }));

export const EID_NORMALIZED_OFFENDER_DETAIL_TYPE =
    declareEidObjType('NormalizedOffenderDetail', {});

export const EID_NORMALIZED_CRIMINAL_RECORD_TYPE = type(({ hopefully }) =>
    declareEidObjType('NormalizedCriminalRecord', {
        crime_details: EID_CRIME_RECORD_TYPE,
        offender_details: EID_NORMALIZED_OFFENDER_DETAIL_TYPE,
        county_or_jurisdiction: hopefully(EID_JURISDICTION_TYPE),
    }));

export const EID_NORMALIZED_ADDRESS_DETAIL_TYPE = type(() =>
    declareEidObjType('NormalizedAddressDetail', {}));

export const EID_NORMALIZED_ADDRESS_RECORD_TYPE = type(({ hopefully }) =>
    declareEidObjType('NormalizedAddressRecord', {
        address_details: EID_ADDRESS_TYPE,
        county_or_jurisdiction: hopefully(EID_JURISDICTION_TYPE),
    }));

export const EID_MANUAL_JURISDICTION_BACKGROUND_CHECK_TYPE = type(({ arrayOf }) =>
    declareEidObjType('ManualJurisdictionBackgroundCheckData', {
        criminal_records: arrayOf(EID_NORMALIZED_CRIMINAL_RECORD_TYPE),
        address_records: arrayOf(EID_NORMALIZED_ADDRESS_RECORD_TYPE),
    }));
