import { m } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';

import FilterMenu from './FilterMenu';


const ColumnHeader = {
    view({children}) {
        return m('th.ColumnHeader',
            m('span.ColumnHeader__label', children));
    },
};


const DateColumnHeader = {
    view({attrs: vm}) {
        return m(pointable({
            view() {
                return m(ColumnHeader,
                    'Date',
                    m(Named.Icon, {
                        name: (vm.ascending) ? 'arrow-tailed-down' : 'arrow-tailed-up',
                    }));
            },
        }), {
            onpointerup: vm.switchSortDirection,
        });
    },
};

const IdoColumnHeader = {
    view() {
        return m(ColumnHeader, 'Sent to');
    },
};

const RequestTypeColumnHeader = {
    view({attrs: vm}) {
        return m(ColumnHeader,
            m(FilterMenu, {
                queriedRequests: vm,
                filterId: 'summary',
            }, 'Type'));
    },
};


const SenderColumnHeader = {
    view({attrs: vm}) {
        return m(ColumnHeader, m(FilterMenu, {
            queriedRequests: vm,
            filterId: 'sentBy',
        }, 'Sent by'));
    },
};


const StatusColumnHeader = {
    view({attrs: vm}) {
        return m(ColumnHeader, m(FilterMenu, {
            queriedRequests: vm,
            filterId: 'status',
        }, 'Status'));
    },
};

export default {
    view({attrs: vm}) {
        return m('tr',
            m(DateColumnHeader, vm),
            m(IdoColumnHeader, vm),
            m(RequestTypeColumnHeader, vm),
            m(SenderColumnHeader, vm),
            m(StatusColumnHeader, vm));
    },
};
