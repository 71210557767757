import { m, spa } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { WithLeftNav, ScheduleCallMeronomy as btx } from '#/rp-facing/views/Layouts';
import { CheckmarkList } from '#/rp-facing/views/CheckmarkList';

import PartyPopperSvgUri from '#/rp-facing/images/party-popper.svg';


const ScheduleOnboardingCall = {
    view() {
        return m(btx.block,
            m(`h2${btx.heading}`,
                'You\'re all done!'),
            m(btx.subHeading,
                'Once we\'ve verified your identity and your business, ' +
                'we\'ll be in touch, and you\'ll be ready to run some verifications!'),
            m(btx.form,
                m(`img${btx.graphic}`, { src: PartyPopperSvgUri }),
                m(btx.instructions,
                    m(`h2${btx.nextStepHeading}`, 'Next step: schedule your onboarding call'),
                    m('p',
                        'Somebody from the Evident team should be reaching out to you shortly, ' +
                        'but we know your time is valuable! If you prefer, you can schedule a ' +
                        'time that works best for you. During your onboarding call, we will:'),
                    m(CheckmarkList,
                        'Complete your account setup, and get you up and running.',
                        'Configure your account, including policies specific to your company.',
                        'Add any required new users to the account.'),
                    m(Button, {
                        flags: {
                            primary: true,
                            round: true,
                        },
                        onpress() {
                            spa.$window.location.href = 'https://calendly.com/evident-customer-success/onboarding-meeting';
                        },
                    }, 'Schedule a call'))));
    },
};


export default () => {
    spa.setView(ScheduleOnboardingCall, {
        layout: WithLeftNav,
    });
};
