import { once } from '#/universal-framework/async';
import json from '#/universal-framework/json';

import load from './loader';


const _ENABLE_MARKETING = json.boolean(deploy.WEB_PUBLIC_TELEMETRY_ENABLE_MARKETING, false);

export const installGoogleTagManager = once(($window, containerId) => {
    // eslint-disable-next-line
    if (!_ENABLE_MARKETING) {
        return Promise.resolve(() => null);
    }

    $window.dataLayer = $window.dataLayer || [];
    $window.dataLayer.push({
        'gtm.start': (new Date()).getTime(),
        'event': 'gtm.js',
    });

    return load(`https://www.googletagmanager.com/gtm.js?id=${containerId}`)
        .then(() => (data) => $window.dataLayer.push(data));
});
