import { m, spa } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { WithLeftNav, ScheduleCallMeronomy as btx } from '#/rp-facing/views/Layouts';
import { CheckmarkList } from '#/rp-facing/views/CheckmarkList';

import SalesWomanSvgUri from '#/rp-facing/images/saleswoman.svg';


const ScheduleConsultingCall = {
    view() {
        return m(btx.block,
            m(`h2${btx.heading}`,
                'You\'re all done!'),
            m(btx.subHeading,
                'Once we\'ve verified your identity and your business, ' +
                'we\'ll be in touch, and you\'ll be ready to run some verifications!'),
            m(btx.form,
                m(`img${btx.graphic}`, { src: SalesWomanSvgUri }),
                m(btx.instructions,
                    m(`h2${btx.nextStepHeading}`, 'Next step: schedule your consultation call'),
                    m('p',
                        'Somebody from the Evident team should be reaching out to you shortly, ' +
                        'but we know your time is valuable! If you prefer, you can schedule a ' +
                        'time that works best for you. During your consultation call, we will:'),
                    m(CheckmarkList,
                        'Discuss your current processes and pain points.',
                        'Identify the information you need to verify from your users.',
                        'Customize a solution that’s right for your business.'),
                    m(Button, {
                        flags: {
                            primary: true,
                            round: true,
                        },
                        onpress() {
                            spa.$window.location.href = 'https://calendly.com/evident-sales-team/sales-meeting';
                        },
                    }, 'Schedule a call'))));
    },
};

export default () =>
    spa.setView(ScheduleConsultingCall, {
        layout: WithLeftNav,
    });
