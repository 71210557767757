import { NotRpAdmin } from '#/rp-facing/state/errors';
import RouteSequence from '#/rp-facing/core/routeSequence';

const LANDING_TRANSITIONS = {
    base: 'rpweb',
    entry: 'selfServiceDataEntry',
    pending: 'selfServicePending',
};

export class LandingRoute {
    constructor(user, initialRoute) {
        this.user = user;
        this.initialRoute = initialRoute;
        this._postLoginActions();
    }

    async getRoute() {
        await this.packageSelectionInfoSetup;
        const sequence = this._getSequence();
        return sequence.getRoute();
    }

    _getSequence() {
        return new RouteSequence([
            [() => this.user.isGuest(),
                '/auth'],
            [() => !this.user.hasVerifiedEmail(),
                '/verify-email'],
            [() => this._matchesTransition(LANDING_TRANSITIONS.base) && this.initialRoute,
                this.initialRoute],
            [() => this._matchesTransition(LANDING_TRANSITIONS.base),
                () => this._getFirstRpRoute()],
            [() => this._matchesTransition(LANDING_TRANSITIONS.entry) && this._checkForPackageSelection(),
                '/setup/plan-selection'],
            [() => this._matchesTransition(LANDING_TRANSITIONS.entry) && this._checkPhoneCompletion(),
                '/setup/overview'],
            [() => this._matchesTransition(LANDING_TRANSITIONS.entry),
                '/setup/contact'],
            [() => this._matchesTransition(LANDING_TRANSITIONS.pending) && this.packageSelection.length,
                '/setup/schedule-onboarding-call'],
            [() => this._matchesTransition(LANDING_TRANSITIONS.pending),
                '/setup/schedule-consulting-call'],
        ]);
    }

    _postLoginActions() {
        if (this.user.isLoggedIn()) {
            this.packageSelectionInfoSetup = this._savePackageSelectionInfo();
            this.user.trackLogin();
        }
    }

    async _savePackageSelectionInfo() {
        const state = await this.user.onboarding.deriveOnboardingState();
        this.packageSelection = state.packageSelection;
        this.landingTransition = state.landingTransition;
    }

    _matchesTransition(option) {
        return this.landingTransition === option;
    }

    async _getFirstRpRoute() {
        const rpMap = await this.user.dominion
            .load(this.user.client)
            .catch(() => null);

        return rpMap && rpMap.size > 0
            ? `/${rpMap.keys().next().value}/requests`
            : Promise.reject(NotRpAdmin());
    }

    _checkForPackageSelection() {
        const incomplete = Object.values(this.user.onboarding.request.input)
            .filter(({ complete }) => !complete);
        return incomplete.length === 1
            && incomplete[0].attrType === 'business.evidentid_self_service.package_selection';
    }

    _checkPhoneCompletion() {
        const phone = this.user.onboarding.request.input['identity.phonenumber'];
        return phone.complete;
    }
}
