import { colors, userInput, validation } from '#/browser-framework';


const { colorModel, inputStringToColor, toHex } = colors;
const { field, inputlock } = userInput;
const { isEmail } = validation;


export default async function emailSettings(rpname, submit) {
    function colorField(id) {
        return field({
            id,
            initialValue: colorModel(),
            validate(v) {
                if (!inputStringToColor(v.input).model) {
                    throw new Error(`${v.input} is not a color`);
                }
            },
        });
    }

    function validateEmail(v) {
        if (!isEmail(v)) {
            throw new Error('Invalid email');
        }
    }

    function required(v) {
        if (!v || !v.length) {
            throw new Error('This value is required');
        }
    }


    const lock = await inputlock({
        id: `${rpname}-email-settings`,
        fields: {
            fromName: field({
                id: 'email-settings-from-name',
                validate: required,
            }),
            replyToName: field({
                id: 'email-settings-reply-to-name',
                validate: required,
            }),
            replyToAddress: field({
                id: 'email-settings-reply-to-address',
                validate: validateEmail,
            }),
            barBelowWordmarkBgColor: colorField('email-settings-bar-below-wordmark-bg-color'),
            buttonBgColor: colorField('email-settings-button-bg-color'),
            buttonFgColor: colorField('email-settings-button-fg-color'),
            boxBehindButtonBgColor: colorField('email-settings-box-behind-button-bg-color'),
            boxBehindButtonFgColor: colorField('email-settings-box-behind-button-fg-color'),
        },
        async fire() {
            await submit(lock.dehydrate());
        },
    });

    lock.dehydrate = () => {
        const dump = lock.dump();

        return Object.assign({}, dump, {
            barBelowWordmarkBgColor: toHex(dump.barBelowWordmarkBgColor).input,
            buttonBgColor: toHex(dump.buttonBgColor).input,
            buttonFgColor: toHex(dump.buttonFgColor).input,
            boxBehindButtonBgColor: toHex(dump.boxBehindButtonBgColor).input,
            boxBehindButtonFgColor: toHex(dump.boxBehindButtonFgColor).input,
        });
    };

    lock.hydrate = (s = {}) => {
        const extract = (key) => inputStringToColor(s[key] || '#000');

        const {
            from,
            replyTo,
            barBelowWordmarkBgColor,
            buttonBgColor,
            buttonFgColor,
            boxBehindButtonBgColor,
            boxBehindButtonFgColor,
        } = Object.assign({}, s, {
            barBelowWordmarkBgColor: extract('barBelowWordmarkBgColor'),
            buttonBgColor: extract('buttonBgColor'),
            buttonFgColor: extract('buttonFgColor'),
            boxBehindButtonBgColor: extract('boxBehindButtonBgColor'),
            boxBehindButtonFgColor: extract('boxBehindButtonFgColor'),
        });

        return lock.load({
            fromName: (from || { name: 'Evident' }).name,
            replyToName: (replyTo || { name: 'Evident' }).name,
            replyToAddress: (replyTo || { email: 'support@evidentid.com' }).email,
            barBelowWordmarkBgColor,
            buttonBgColor,
            buttonFgColor,
            boxBehindButtonBgColor,
            boxBehindButtonFgColor,
        });
    };

    return lock;
}
