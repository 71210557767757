import { m } from '#/browser-framework';
import stateEventEmitter from '#/rp-facing/state/stateEventEmitter';
import { Button } from '#/browser-framework/comps';

export const ConfirmPopupEvents = {
    SHOW: 'confirmpopupshow',
};

/**
 * @typedef ConfirmPopupOptions
 * @type {Object}
 * @property {string} cancelBtnMsg
 * @property {string} confirmBtnMsg
 * @property {string} title
 * @property {string} text
 */

/**
 * @module ConfirmPopup
 */
export const ConfirmPopup = {
    isVisible: false,
    onCancelCallbacks: [],
    onConfirmCallbacks: [],
    onCancel() {
        ConfirmPopup.isVisible = false;
        ConfirmPopup.onCancelCallbacks.forEach((fn) => fn());
        ConfirmPopup.onCancelCallbacks.length = 0;
        ConfirmPopup.onConfirmCallbacks.length = 0;
    },
    onConfirm() {
        ConfirmPopup.isVisible = false;
        ConfirmPopup.onConfirmCallbacks.forEach((fn) => fn());
        ConfirmPopup.onCancelCallbacks.length = 0;
        ConfirmPopup.onConfirmCallbacks.length = 0;
    },
    view({attrs: options}) {
        if (!ConfirmPopup.isVisible) {
            return null;
        }

        return [
            m('div.ConfirmPopup__mask', {
                onclick: () => ConfirmPopup.onCancel(),
            }),
            m('div.ConfirmPopup__modal', {
                onclick: () => ConfirmPopup.onCancel(),
            }, m('div.ConfirmPopup__modal-body',
                m('div.ConfirmPopup__title', options.title),
                m('p.ConfirmPopup__text', options.text),
                m('div.ConfirmPopup__buttons',
                    m(Button, {
                        flags: {
                            secondary: true,
                        },
                        onpress() {
                            ConfirmPopup.onCancel();
                        },
                    }, options.cancelBtnMsg),
                    m(Button, {
                        flags: {
                            primary: true,
                        },
                        onpress: () => ConfirmPopup.onConfirm(),
                    }, options.confirmBtnMsg)))),
        ];
    },
};

/**
 * @callback promptCallback
 * @param {ConfirmPopupOptions} options
 */
stateEventEmitter.on(ConfirmPopupEvents.SHOW, (/** @type {ConfirmPopupOptions} */options) => {
    ConfirmPopup.isVisible = true;
    if (options && options.onCancelCallback) {
        ConfirmPopup.onCancelCallbacks.push(options.onCancelCallback);
    }

    if (options && options.onConfirmCallback) {
        ConfirmPopup.onConfirmCallbacks.push(options.onConfirmCallback);
    }
});
