import { lets } from '#/universal-framework/functions';
import { logger, m, spa } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';
import { getUserIdToken } from '#/browser-framework/firebase';

import ComboButton from '#/rp-facing/views/ComboButton';

const EID_DOCS_URL_PREFIX = deploy.WEB_PUBLIC_EID_DOCS_URL_PREFIX;


function matchContent(openApiSpec) {
    try {
        const {
            paths: {
                '/verify/requests/{request_id}': {
                    get: {
                        'x-code-samples': lookupRequestExamples,
                        responses: {
                            '200': {
                                content: {
                                    'application/json': {
                                        example: getRequestResponseExample,
                                    },
                                },
                            },
                        },
                    },
                },
                '/verify/requests': {
                    post: {
                        'x-code-samples': crReqExamples,
                        responses: {
                            '200': {
                                content: {
                                    'application/json': {
                                        example: crResExample,
                                    },
                                },
                            },
                        },
                    },
                },
                '/verify/requests/{request_id}/authToken': {
                    get: {
                        'x-code-samples': riReqExamples,
                    },
                },
                '/requests': {
                    post: {
                        'x-code-samples': submitReqExamples,
                    },
                },
            },
        } = openApiSpec;

        return {
            submitReqExamples,
            crReqExamples,
            crResExample,
            riReqExamples,
            getRequestResponseExample,
            lookupRequestExamples,
        };
    } catch (e) {
        logger.error(e);

        throw e;
    }
}


export function devDocs(rpName, templates, rpWebClient) {
    const iface = {};

    iface._sandbox = true;
    const docsXhr = rpWebClient.requestJson;

    iface._computeDocs = spa.redrawAfter((openApiSpec) => {
        const {
            crReqExamples,
            crResExample,
            riReqExamples,
            submitReqExamples,
            lookupRequestExamples,
            getRequestResponseExample,
        } = matchContent(openApiSpec);

        const findLang = (a, target) => a.find(({ lang }) => lang === target);
        const makeJsonExample = (jsObj) => ({ lang: 'json', source: JSON.stringify(jsObj, null, 4) });

        const content = {
            assureSdkSubmitExample: findLang(submitReqExamples, 'JavaScript'),
            createRequestRequestExample: findLang(crReqExamples, 'Shell'),
            createRequestResponseExample: makeJsonExample(crResExample),
            getRequestResponseExample: makeJsonExample(getRequestResponseExample),
            reissueTokenRequestExample: findLang(riReqExamples, 'Shell'),
            submitAttributesRequestExample: findLang(submitReqExamples, 'Shell'),
            lookupCodeExample: findLang(lookupRequestExamples, 'Shell'),
        };

        iface.error = null;

        Object.assign(iface, { content });
    });

    iface.links = [
        'webhooks',
    ].reduce((p, name) =>
        Object.assign(p, {
            [name]: `#!/${rpName}/docs/${name}`,
        }), {});

    iface.getEndpointToTemplateReference = () =>
        lets([iface.templateSelection.selection._id, iface.environment.getSelectedLabel()],
            (templateId, label) =>
                `/docs/templates/${templateId}?env=${(label === 'Production') ? 'prod' : 'demo'}`);

    iface.getDocsServiceRedirectUrl = () =>
        lets([iface.templateSelection.selection._id, iface.environment.getSelectedLabel()],
            (templateId, label) =>
                `${EID_DOCS_URL_PREFIX}/v1/templates/${templateId}?env=${(label === 'Production') ? 'prod' : 'demo'}`);

    // Request doc content from service and compute docs given
    iface.loadSelectedDocs = () => {
        iface.inflight = docsXhr({ url: iface.getEndpointToTemplateReference() })
            .then((openApiSpec) => iface._computeDocs(openApiSpec))
            .then(() => iface.getTemplateReferenceUrl())
            .then((url) => (iface.templateRefUrl = url))
            .catch((e) => (iface.error = e))
            .then(() => {
                iface.inflight = null;

                spa.redraw();
            });

        return iface.inflight;
    };

    iface.onDocsSelectionChanged = () =>
        ((iface.templateSelection.selection)
            ? iface.loadSelectedDocs()
            : null);

    iface.environment = ComboButton.makeViewModel({
        options: ['Sandbox', 'Production'],
        onSelectionChanged: iface.onDocsSelectionChanged,
    });

    iface.templateSelection = Dropdown.makeViewModel({
        placeholder: 'Select a template',
        values: templates,
        toLabel: ({ name }) => name,
        onSelectionChanged: iface.onDocsSelectionChanged,
    });

    iface.getTemplateReferenceUrl = () =>
        getUserIdToken()
            .then((jwt) =>
                `${EID_DOCS_URL_PREFIX}/auth/session?${m.buildQueryString({
                    id_token: jwt,
                    sign_in_success_url: iface.getDocsServiceRedirectUrl(),
                })}`);

    iface.hasTemplates = () =>
        templates.length > 0;

    return iface;
}
