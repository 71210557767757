import {m} from '#/browser-framework';

const BAR_WIDTH = 20;

const LabeledNumber = {
    view({children}) {
        return m('.LabeledNumber',
            m('h2.LabeledNumber__number', children[0]),
            m('span.LabeledNumber__label', children[1]));
    },
};


const SeriesMarker = {
    view({attrs: {seriesName}}) {
        return m('span.SeriesMarker',
            m(`span.SeriesMarker__circle.${seriesName.replace(/\s/g, '')}`, m.trust('&#9679;')),
            m('span.SeriesMarker__label', seriesName));
    },
};


/**
 * Produces vnodes for a single stacked svg bar graph
 * @param {object} obj
 * @param {number} obj.barIndexFromLeft - padding of the enclosing view
 * @param {number} obj.canvasHeight - the height of the enclosing view
 * @param {number} obj.maxY the largest possible size of the stacked bar
 * @param {number} obj.sent - the total # of sent requests, including expired and shared
 * @param {number} obj.expired - the # of expired requests
 * @param {number} obj.shared - the # of shared requests
 *  */
export const createRequestStatusBar = function({
    barIndexFromLeft,
    canvasHeight,
    maxY,
    sent,
    expired,
    shared,
}) {
    const BAR_MARGIN = barIndexFromLeft * BAR_WIDTH * 2;

    const segmentHeight = (count) => Math.ceil(((count / maxY) * canvasHeight) * (sent / maxY));

    const sentHeight = segmentHeight(sent - expired - shared);
    const expiredHeight = segmentHeight(expired);
    const sharedHeight = segmentHeight(shared);

    const sentRect = {
        x: BAR_MARGIN,
        y: canvasHeight - sentHeight - expiredHeight - sharedHeight,
        width: BAR_WIDTH,
        height: sentHeight,
        fill: '#BCF0E5',
    };

    const expiredRect = {
        x: BAR_MARGIN,
        y: canvasHeight - expiredHeight,
        width: BAR_WIDTH,
        height: expiredHeight,
        fill: '#7EE1CD',
    };

    const sharedRect = {
        x: BAR_MARGIN,
        y: canvasHeight - sharedHeight,
        width: BAR_WIDTH,
        height: sharedHeight,
        fill: '#2AB699',
    };

    return m('g',
        m('rect', sentRect),
        m('rect', expiredRect),
        m('rect', sharedRect));
};



const Histogram = {
    view({attrs: {dayMetrics, maxY}}) {
        return m('svg.SentVsCompleted__histogram', {
            width: 200,
            height: 120,
            viewBox: `0 0 ${BAR_WIDTH * dayMetrics.length * 2} 120`,
        }, dayMetrics.map(([, metrics], i) => {
            return createRequestStatusBar({
                barIndexFromLeft: i,
                canvasHeight: 120,
                maxY,
                sent: metrics.sent,
                expired: metrics.expired,
                shared: metrics.shared,
            });
        }));
    },
};


export default {
    view({attrs: {sentVsComplete: {dayMetrics, maxY, sent, shared}}}) {
        return m('.SentVsCompleted',
            m('.SentVsCompleted__graph',
                m(Histogram, {dayMetrics, maxY}),
                m('.SentVsCompleted__series',
                    m(SeriesMarker, {seriesName: 'Sent'}),
                    m(SeriesMarker, {seriesName: 'Timed Out'}),
                    m(SeriesMarker, {seriesName: 'Shared'}))),
            m('.SentVsCompleted__summary',
                m(LabeledNumber, sent, 'Sent'),
                m(LabeledNumber, shared, 'Shared'))
        );
    },
};
