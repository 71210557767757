import { bem, m } from '#/browser-framework';
import { Named } from '#/browser-framework/comps';


const btx = bem`CheckmarkList item check content`;

export const CheckmarkList = {
    view: ({ children }) => m('.CheckmarkList',
        children.map((vnode, key) =>
            m(btx.item, {key},
                m(btx.check, m(Named.Icon, { name: 'check-circle-outlined' })),
                m(btx.content, vnode)))),
};
