import flatpickr from 'flatpickr';
import moment from 'moment';

import { m, harness, router } from '#/browser-framework';
import { Button, Named, pointable } from '#/browser-framework/comps';
import SearchInput from '#/browser-framework/comps/SearchInput';


const SearchField = {
    view: ({attrs: vm}) => {
        return m(SearchInput, {
            id: 'request-dashboard-search',
            placeholder: 'Search for...',
            value: vm.search,
            oninput: (value) => vm.setSearchString(value),
        });
    },
};


/*
[ 9/12/2016 – 9/18/2016  📅 ]
+‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾+
|        Start       |
|    ← Sep 2016 →    |
|                    |
|     1  2  3  4  5  |
|  6  7  8  9 10 11  |
| 12 13 14 15 16 17  |
| 18 19 20 21 22 23  |
| 24 25 26 27 28 29  |
| 30 31              |
+____________________+
*/
const DateTimeRangePicker = {
    fp: {},
    hasValue: false,
    model: {},
    setRange(start, end) {
        this.model.setDateRange(start, end);
    },
    checkValue() {
        this.hasValue = this.fp
            && this.fp.input
            && this.fp.selectedDates
            && this.fp.selectedDates.length > 1;
        m.redraw();
    },
    getStartingDate() {
        const range = [ this.model.startDate, this.model.endDate ];
        const isDateSet = range.every((date) => moment.isMoment(date));

        return isDateSet
            ? range.map((date) => date.toDate())
            : undefined;
    },
    reset() {
        this.fp.clear();
        if (this.hasValue) {
            this.model.resetDateRange();
        }
    },
    oncreate({ dom, attrs }) {
        this.model = attrs;
        this.fp = flatpickr(dom.querySelector('input'), {
            animate: false,
            dateFormat: 'n/j/Y',
            defaultDate: this.getStartingDate(),
            maxDate: moment().endOf('day').toDate(),
            mode: 'range',
            onClose: (selectedDates) => {
                if (selectedDates.length) {
                    const endDate = selectedDates[1] || selectedDates[0];
                    this.setRange(selectedDates[0], endDate);
                }
            },
        });
        this.checkValue();
    },
    onupdate({ dom }) {
        if (this.fp) {
            this.fp.input = dom.querySelector('input');
        }
    },
    onremove() {
        this.fp = null;
        document.querySelectorAll('.flatpickr-calendar')
            .forEach((n) => n.remove());
    },
    view() {
        const extraClass = this.hasValue ? 'close' : '';
        const id = 'date-picker';
        return m('.DateTimeRangePicker',
            m('input.DateTimeRangePicker__field', {id}),
            m('label.DateTimeRangePicker__label', {for: id},
                m(Named.Icon, {
                    class: `DateTimeRangePicker__icon ${extraClass}`,
                    name: this.hasValue ? 'x' : 'calendar',
                    onclick: (e) => {
                        e.stopPropagation();
                        this.reset();
                    },
                })));
    },
};


/*
Click this thing to start an export to CSV for the current request query.
*/
const ExportCsvButton = {
    view: ({attrs: vm}) => {
        return m(pointable('span'), {
            onpointerup: vm.exportToCsv,
        },
        m('i.fa.fa-file-excel-o'),
        m('span', m.trust('&nbsp;Export')));
    },
};


const NewRequestTool = {
    view: ({attrs: {rp}}) => {
        return m(Button, harness('create-request-table-toolbar', {
            flags: {
                primary: true,
            },
            onpress() {
                router.go(`/${rp.name}/requests/create`);
            },
        }),
        'Create Request');
    },
};


export default {
    view({attrs: {rp}}) {
        return m('.RequestTableToolbar',
            m('.RequestTableToolbar__left',
                m('.TableTool.DateTool', m(DateTimeRangePicker, rp.queriedRequests)),
                m('.TableTool.SearchTool', m(SearchField, rp.queriedRequests))),
            m('.RequestTableToolbar__right',
                m('.TableTool.ExportTool', m(ExportCsvButton, rp.queriedRequests)),
                m('.TableTool.NewRequestTool', m(NewRequestTool, { rp }))));
    },
};
