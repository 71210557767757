import Papa from 'papaparse';

import { lets, type, contract } from '#/universal-framework/functions';
import { validation } from '#/browser-framework';


const _selectTemplateWithNamePreference = (desiredTemplateName, availableTemplates) =>
    lets([availableTemplates.find(({ name }) => name === desiredTemplateName)],
        (template) =>
            ((template)
                ? template
                : (availableTemplates.length === 1)
                    ? availableTemplates[0]
                    : null));


export const deriveTemplate = contract(
    _selectTemplateWithNamePreference,
    () => type(({ arrayOf, isNull, or, object, shape, string }) => ({
        domain: [string, arrayOf(shape({ name: string }))],
        range: or(isNull, object),
    })));

export function createRequestsFromCsvFile(xhrClient, csvFile, availableTemplates) {
    // We need to track errors per-record in the CSV.
    const badRecords = [];

    // Used to syncronize parse completion and when VerifyAPI finishes all POST requests.
    const promises = [];

    let processed = 0;
    const countSuccess = () => ++processed;

    return new Promise((resolve, reject) => {
        Papa.parse(csvFile, {
            // Get strings in memory only.
            dynamicTyping: false,

            // Count the empty lines so currentRow stays correct.
            skipEmptyLines: false,

            complete() {
                // Wait until VerifyAPI finishes.
                Promise.all(promises).then(() => {
                    resolve({
                        badRecords,
                        processed,
                    });
                });
            },
            step(results) {
                for (const row of results.data) {
                    // This predicate skips empty rows, but allows
                    // rows with an email specified and no template.
                    if (row.length > 0 && row[0] !== '') {
                        const [email = '', templateName = ''] = row;

                        const template = deriveTemplate(templateName.trim(), availableTemplates);

                        if (validation.isEmail(email) && template) {
                            const requestPayload = {
                                notes: template.notes || '',
                                summary: template.summary,
                                description: template.description,
                                attributesRequested: template.attributes.map((attributeType) => {
                                    return {
                                        attributeType,
                                    };
                                }),
                                recipient: {
                                    email: email.trim(),
                                },
                            };

                            const promise = xhrClient({
                                method: 'POST',
                                url: '/requests',
                                data: requestPayload,
                            })
                                .then(countSuccess)
                                .catch(() => {
                                    badRecords.push(row);
                                });

                            promises.push(promise);
                        } else {
                            badRecords.push(row);
                        }
                    }
                }
            },
            error(e) {
                reject(e);
            },
        });
    });
}
