import {m} from '#/browser-framework';
import Toolbar from './Toolbar';
import HeaderRow from './HeaderRow';
import Body from './Body';

export default {
    view({attrs: {rp}}) {
        return m('.RequestTable',
            m(Toolbar, { rp }),
            m('table.RequestTable__cells',
                m('thead', m(HeaderRow, rp.queriedRequests)),
                m('tbody',
                    (rp.queriedRequests.requests.length === 0)
                        ? m('tr', m('td', {colspan: 6, style: 'text-align:center'}, 'No matches found'))
                        : m(Body, { rp, requests: rp.queriedRequests.requests }))));
    },
};
