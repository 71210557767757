import { m, router, spa, harness, events } from '#/browser-framework';
import { Avatar, SearchableDropdown, pointable } from '#/browser-framework/comps';


const AuthUserBadge = {
    view({
        attrs: {
            onactivate,
            user,
            'data-harness': dataharness,
        },
    }) {
        if (!user) {
            return null;
        }

        const {
            email,
            displayName,
            photoURL,
        } = user;

        return m(pointable('.AuthUserBadge'), harness(dataharness, {onpointerup: onactivate}),
            m('.AuthUserBadge__photo', m(Avatar, {
                headshot: photoURL,
                bgcolor: '#000',
                diameter: 48,
            })),
            m('.AuthUserBadge__meta',
                m('div.AuthUserBadge__firstname',
                    (typeof displayName === 'string' && displayName.length > 0)
                        ? displayName.split(' ')[0]
                        : email.split('@')[0],
                    m('span.AuthUserBadge__arrow', '▼')),
                m('span.AuthUserBadge__email', email)));
    },
};

const RX_TO_PAGE_NAMES = new Map([
    [/\/[^\/]+\/requests$/, 'Requests'],
    [/\/[^\/]+\/requests\/view/, 'Request Details'],
    [/\/[^\/]+\/requests\/create$/, 'Create request'],
    [/\/[^\/]+\/admin\/[a-z,\-]+/, 'Administration'],
]);

function getPageName() {
    for (const [rx, name] of RX_TO_PAGE_NAMES) {
        if (rx.test(router.here())) {
            return name;
        }
    }

    return '';
}


const SessionMenu = {
    open: false,
    oncreate({ state }) {
        state.rootClickSubscription = () => {
            if (state.open) {
                state.open = false;
                m.redraw();
            }
        };
        events.on('root-click', state.rootClickSubscription);
    },
    onremove({ state }) {
        events.off('root-click', state.rootClickSubscription);
    },
    view({state, attrs: App}) {
        return m('.SessionMenu', {
            onclick(e) {
                e.stopPropagation();
            },
        },
        m(AuthUserBadge, harness('session-controls-menu-button', {
            user: App.user,
            onactivate: () => {
                state.open = !state.open;
                return state.open;
            },
        })),
        (state.open) && m('.SessionMenu__dropdown',
            m(pointable('.SessionMenu__logout'), harness('session-controls-logout-button', {
                onpointerup() {
                    spa.$window.firebase.auth().signOut().then(() => {
                        App.referrer = null;
                        router.go('/auth');
                        state.open = false;
                    });
                },
            }), 'Logout')));
    },
};

const maxOptionsLength = 20;

/*
 * Session controls are used to log in or modify user-specific data.
 */
export default {
    view({ state, attrs: App }) {
        const rpMap = (App.user.dominion)
            ? App.user.dominion.load.cache() || new Map()
            : new Map();
        const options = Array.from(rpMap.values())
            .map((rp) => ({ value: rp.name, title: rp.displayName }));

        const isSelected = options.length && App.user.selectedRp;
        if (isSelected && !state.selected) {
            state.selected = App.user.selectedRp.name;
        }

        return m('.SessionControls', [
            m('h3.SessionControls__heading', getPageName()),
            options && options.length > 1
                ? m(SearchableDropdown, {
                    hideOptionsUnlessSearched: options.length > maxOptionsLength,
                    onSelect(rpId) {
                        state.selected = rpId;
                        router.go(`/${rpId}/requests`);
                    },
                    value: state.selected,
                    options,
                })
                : null,
            m(SessionMenu, App),
        ]);
    },
};
