import { m } from '#/browser-framework';
import { ColorControl } from '#/rp-facing/views/ColorControl';
import { saveHistoryIfPossible } from '#/rp-facing/views/pages/FormFields/SaveHistory';
import { fieldBem } from '#/rp-facing/styles/FormsBem';

export const ColorField = {
    view({attrs: field, children}) {
        return m(fieldBem.block,
            m(`label.${fieldBem.label}`, { for: field.id },
                m('span', children)),
            m(ColorControl, {
                id: field.id,
                color: field.value,
                beforechange: () => saveHistoryIfPossible(field),
                onchange(newColor) {
                    field.value = newColor;
                },
            })
        );
    },
};
