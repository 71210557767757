import { bem, m, harness, spa, router } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { WithLeftNav } from '#/rp-facing/views/Layouts';

import { TermsAndConditionsControl } from './TermsAndConditionsControl';
import { woopraTrackAcceptTerms, woopraTrackCompleteStep } from '#/browser-framework/woopraLib';



// For when the user has an onboarded account, and only needs to sign T&Cs.
const OnboardingTermsAndConditions = {
    btx: bem`
        OnboardingTermsAndConditions
            heading
            subHeading
            form
            controls
            `,
    view({
        attrs: {
            viewModel: {
                user: {
                    onboarding: {
                        termsFormModel,
                    },
                },
            },
            viewModel: {
                user: {
                    onboarding,
                },
            },
        },
    }) {
        const { btx } = OnboardingTermsAndConditions;

        return m(btx.block,
            m(`h2${btx.heading}`, 'Terms and conditions'),
            m(`p${btx.subHeading}`, 'Please acknowledge that you have read and accepted the following terms and conditions.'),
            m(btx.form,
                m(TermsAndConditionsControl, {
                    accepted: termsFormModel.accepted,
                    onAcceptChange: (s) => {
                        termsFormModel.accepted = s;
                    },
                }),
                m('.OnboardingStepControls',
                    m(Button, harness('back-to-overview-button', {
                        flags: {
                            secondary: true,
                        },
                        onpress() {
                            woopraTrackAcceptTerms(false, true);
                            router.go('/setup/overview');
                        },
                    }), 'Do this later'),
                    m(Button, harness('submit-button', {
                        flags: {
                            round: true,
                            primary: true,
                        },
                        disabled: !termsFormModel.maySubmit(),
                        onpress() {
                            woopraTrackAcceptTerms(true, false);
                            woopraTrackCompleteStep(Boolean(onboarding.isUserVerified()),
                                Boolean(onboarding.hasBillingInfo()),
                                Boolean(onboarding.isBusinessVerified()),
                                true);
                            termsFormModel
                                .submit()
                                .then(() => {
                                    router.go('/setup/overview');
                                });
                        },
                    }), 'Continue'),
                )));
    },
};


export default () => {
    spa.setView(OnboardingTermsAndConditions, {
        layout: WithLeftNav,
    });
};
