import { m, harness } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';
import { fieldBem, fileBem } from '#/rp-facing/styles/FormsBem';


export default {
    view({ attrs: { id, label, defaultUrl, dataUrl, onchange, 'data-harness': dataharness}}) {
        return m(`label.input.${fieldBem.input}`, {for: id},
            m('input', harness(dataharness, {
                id,
                type: 'file',
                accept: '.png,.jpg,.jpeg',
                style: 'display:none',
                onchange: (e) => {
                    e.preventDefault();
                    onchange(Array.from(e.target.files)[0]);
                    e.target.value = null;
                },
            })),

            m(fieldBem.file,
                (dataUrl || defaultUrl) && m(fileBem.preview,
                    m(fileBem.image, harness('config-branding-rp-wordmark-url', {
                        style: `background-image: url(${dataUrl || defaultUrl})`,
                    }))),
                m(fileBem.name, label),
                m(pointable(fileBem.delete), {
                    onpointerup: () => {
                        onchange(undefined);
                    },
                }, dataUrl && m(Named.Icon, {name: 'delete'}))));
    },
};
