import { m, spa } from '#/browser-framework';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import {
    bindNavigation,
    DynamicWalkthroughLayout,
    Walkthrough,
    WithRp,
} from './DocsLayout';

import {
    VerifyApiCreateRequestDocs,
    VerifyApiReviewRequestDocs,
} from './VerifyApiDocs';



const {
    TableOfContents,
    step1,
    step2,
    step3,
} = bindNavigation('/docs/verify-api-eid-submit-uis', [
    ['Step 1. Create a request using VerifyAPI', 'step1'],
    ['Step 2. Wait for webhook notification', 'step2'],
    ['Step 3. Review results', 'step3'],
]);

const Content = WithRp({
    view({
        attrs: {
            name: rpName,
            docs: {
                links,
                content: {
                    createRequestResponseExample,
                    createRequestRequestExample,
                    getRequestResponseExample,
                    lookupCodeExample,
                },
            },
        },
    }) {
        return m(Walkthrough.block,
            m(Walkthrough.header,
                m('h2', 'VerifyAPI and Evident Submit UIs'),
                m('p',
                    'This guide shows you how to integrate with the Evident platform such that you only ',
                    'request a verification and wait on ', m('a', { href: links.webhooks }, 'webhooks'), '. ',
                    'We will collect and process information from an end user for you, using a UI that is customizable for ',
                    'your brand on this portal under the Administration Menu.'),
                m(TableOfContents, { rpName })),
            m(Walkthrough.section,
                m(step1),
                m(VerifyApiCreateRequestDocs, {
                    createRequestRequestExample,
                    createRequestResponseExample,
                })),
            m(Walkthrough.section,
                m(step2),
                m('p',
                    'Once you create a request, the Evident platform will send an email to the end user ',
                    'asking to collect their personal data. Webhooks will alert you if the email did not go out ',
                    'or when a verification request has completed.')),
            m(Walkthrough.section,
                m(step3),
                m(VerifyApiReviewRequestDocs, {
                    getRequestResponseExample,
                    links,
                    lookupCodeExample,
                })));
    },
});


export default ensureRpSelection(() => {
    spa.setView(Content, {
        layout: DynamicWalkthroughLayout,
    });
}, {
    requireTerms: true,
    pendingLayout: WithLeftNav,
});
