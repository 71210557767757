import { harness, m, spa } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';

import { WithLeftNav } from '#/rp-facing/views/Layouts';
import { RpView } from '#/rp-facing/views/RpView';
import { ConfirmPopup } from '#/rp-facing/views/ConfirmPopup';
import { ensureRpSelection } from '#/rp-facing/core/routing/routeHandlerDecorators';

import AttributeSelector from './AttributeSelector';
import AttributeSelectedChipWall from './AttributeSelectedChipWall';
import RequestMessageForm from './RequestMessageForm';
import TemplateToolbar from './TemplateToolbar';

// These are placed here to easily identify html text content. Later it can be moved out for i18n.
const POPUP_CANCEL_BTN_TEXT = 'Nevermind, discard changes';
const POPUP_CONFIRM_BTN_TEXT = 'Continue with modified template';
const POPUP_TEXT = 'Any custom settings that were configured to this template will be overridden ' +
    'for this modified request, and will revert to default settings.  The template ' +
    'itself will not be affected, and will keep its configured settings.';
const POPUP_TITLE = 'Making changes to this template will revert this request to the default settings';

const CreateRequest = RpView({
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        if (!App.user.selectedRp) {
            return m(Spinner);
        }

        const stagedRequest = App.user.selectedRp.stagedRequest;
        const {
            archive,
            suggestions,
            notes,
        } = stagedRequest;

        return m('.CreateRequest',
            m('.CreateRequest__toolbar',
                m(TemplateToolbar, {
                    App,
                    archive,
                })),
            m('.CreateRequest__form',
                m(RequestMessageForm, stagedRequest)),
            m('.CreateRequest__notes',
                m('p', 'Notes (Internal view only):'),
                m('textarea', harness('create-request-form-input-note', {
                    oninput({target: {value}}) {
                        stagedRequest.notes = value;
                    },
                    value: notes,
                }))),
            m('.CreateRequest__selected',
                m(AttributeSelectedChipWall, {archive, suggestions, stagedRequest})),
            m('.CreateRequest__selector',
                m(AttributeSelector, {archive})),
            m(ConfirmPopup, { cancelBtnMsg: POPUP_CANCEL_BTN_TEXT,
                confirmBtnMsg: POPUP_CONFIRM_BTN_TEXT,
                text: POPUP_TEXT,
                title: POPUP_TITLE}));
    },
});

export default ensureRpSelection(() => {
    spa.setView(CreateRequest, { layout: WithLeftNav });
}, {
    pendingLayout: WithLeftNav,
});
