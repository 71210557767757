import { harness, m, tx, router, spa } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { RestrictedLayout } from '#/rp-facing/views/Layouts';


const Page = {
    alreadySentEmail: false,
    view({
        attrs: {
            viewModel: {
                user,
            },
        },
        state,
    }) {
        const { resetRequest } = user;
        const { fields } = resetRequest;

        const feedback = (resetRequest.error)
            ? resetRequest.error.message
            : ('feedback' in resetRequest)
                ? resetRequest.feedback
                : null;

        return m('.Dialog.PasswordReset',
            feedback && m(`p.PasswordReset__feedback${tx({
                danger: Boolean(resetRequest.error),
                success: !resetRequest.error,
            })}`, harness('rp-facing-request-reset-dialouge'), feedback),
            m(`label[for=${fields.email.id}]`, `
                Enter your account email here and we will send you
                a link to reset your password.`),
            m('input.TextField.PasswordReset__email', harness('rp-facing-request-reset-password-input-email', {
                id: fields.email.id,
                value: fields.email.value,
                oninput({target: {value}}) {
                    fields.email.value = value;
                },
            })),
            m('.PasswordReset__controls',
                m('.PasswordReset__action',
                    m(Button, harness('rp-facing-request-reset-password-cancel', {
                        flags: {
                            danger: true,
                        },
                        onpress: () => router.go('/auth'),
                    }), 'Cancel')),
                m('.PasswordReset__action',
                    m(Button, harness('rp-facing-request-reset-password-submit-button', {
                        flags: {
                            primary: true,
                        },
                        disabled: !resetRequest.maySubmit || state.alreadySentEmail,
                        onpress() {
                            resetRequest.submit().then(() => {
                                state.alreadySentEmail = true;
                            });
                        },
                    }), (state.alreadySentEmail)
                        ? 'Email sent!'
                        : 'Request reset'))));
    },
};


export default () => {
    spa.viewModel.user.loadFormModels().then(() => {
        spa.setView(Page, {
            layout: RestrictedLayout,
        });
    });
};
