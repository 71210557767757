import { m, harness, spa, router } from '#/browser-framework';
import { Button, Overlay, Spinner, pointable } from '#/browser-framework/comps';
import { MobileSimplexDropbox } from '#/ido-lib/views/MobileSimplexDropbox';

import { MobileLayout } from '#/rp-facing/views/Layouts';
import { DelegateScanner } from './DelegateScanner';


const DelegationPassportScan = {
    view({
        attrs: {
            viewModel: {
                onboarding: {
                    idScanFormModel,
                },
            },
        },
    }) {
        return m(DelegateScanner.block,
            m(pointable(DelegateScanner.switch), {
                onpointerup() {
                    router.go('/setup/delegate/duplex');
                },
            }, 'Use an ID card or driver\'s license'),
            m(DelegateScanner.form,
                m(MobileSimplexDropbox, harness('passport-upload', {
                    id: 'passport-upload',
                    accept: 'image/*',
                    enablePreview: true,
                    dataUrl: idScanFormModel.passport.dataUrl,
                    onchange(files) {
                        idScanFormModel.stage('passport', files);
                    },
                }), 'Passport ID page')),
            m(DelegateScanner.controls,
                m(Button, harness('submit', {
                    flags: {
                        primary: true,
                    },
                    disabled: !idScanFormModel.maySubmit(),
                    onpress() {
                        idScanFormModel.submit().then(() => {
                            router.go('/setup/delegate/done');
                        });
                    },
                }), 'Upload')),
            (idScanFormModel.submitting) && m(Overlay, m(Spinner)));
    },
};


export default () => {
    spa.setView(DelegationPassportScan, {
        layout: MobileLayout,
    });
};
