/**
 * The routeTable does not take components but callbacks that update view state.
 * The file takes components and wraps them with callbacks compatible with
 * routeTable.
 */
import { spa } from '#/browser-framework';
import { WithLeftNav } from '#/rp-facing/views/Layouts';


const RouteTableHandlers = {
    createWithLeftNavHandler: (mithrilComponet) => {
        return () =>
            spa.setView(mithrilComponet, {
                layout: WithLeftNav,
            });
    },
};

export default RouteTableHandlers;

