const PlanSelectionPackages = {
    ID_VERIFICATION: {
        title: 'Identity Verification',
        pricing: {
            price: '3.95',
            unit: 'check'},
        summary: 'Confidently verify the authenticity of identity documents',
        details: [
            'Driver\'s license verification',
            'ID card verification',
            'Passport verification',
        ],
        callToAction: 'First 10 identity verifications free!',
        addendum: false,
        submissionValues: ['IdentityVerification'],
    },
    BACKGROUND_CHECK: {
        title: 'Background Check',
        pricing: {
            price: '7.95',
            unit: 'check*',
        },
        summary: 'Cost effective, standard national background check',
        details: [
            'SSN trace',
            'Address history search',
            'Sex Offender Registry',
            'Terrorist Watchlists',
            'National Criminal Screen',
        ],
        addendum: '+$9.95 per county if policy violations are found',
        submissionValues: ['BackgroundCheck'],
    },
    GROWTH: {
        title: 'Growth Package',
        pricing: {
            price: '24.95',
            unit: 'verification*',
        },
        summary: 'Complete identity verification and background check across multiple countries',
        details: [
            'ID Document Verification',
            'SSN trace',
            'Address history search',
            'Sex Offender Registry',
            'Terrorist Watchlists',
            'National Criminal Screen',
            'Multiple Counties Search',
            'FCRA Adverse Action',
        ],
        submissionValues: ['BackgroundCheck', 'MVR'],
    },
    CUSTOM: {
        title: 'Build Your Own',
        pricing: {
            price: '',
            unit: 'verification',
        },
        summary: 'Create a custom package from our range of identity and credential verification services',
        details: [
            'Motor Vehicle Report',
            'Insurance',
            'State Contractor License',
            'Commercial Driving - CDLIS Address Verification',
            'Commercial Driving - FMSCA-PSP',
        ],
        custom: 'And much more',
        submissionValues: [],
    },
};

export default PlanSelectionPackages;
