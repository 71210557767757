function* attributes(groups) {
    const grps = Object.keys(groups);

    for (const g of grps) {
        yield* groups[g];
    }
}

/*
Subjective attributes of a category, further broken down into groups.
*/
export default function SubjectiveAttributeCategory() {
    const groups = {};

    return {
        selected: false,
        attributes: () => {
            return attributes(groups);
        },
        getGroup(name) {
            return groups[name];
        },
        * groups() {
            const keys = Object.keys(groups);

            for (const k of keys) {
                yield [k, groups[k]];
            }
        },
        addAttribute: (subjective) => {
            if (subjective.group in groups) {
                groups[subjective.group].push(subjective);
            } else {
                groups[subjective.group] = [subjective];
            }

            return groups[subjective.group].length - 1;
        },
        get selectedCount() {
            return Array
                .from(attributes(groups))
                .filter(({selected}) => selected)
                .length;
        },
        get attrCount() {
            return Array
                .from(attributes(groups))
                .length;
        },
    };
}
