import { spa } from '#/browser-framework';
import { lets, mandate, signature } from '#/universal-framework/functions';
import load from './loader';

const FIREBASE_VERSION = '5.3.0';

export const googleSignIn = ($window) =>
    $window
        .firebase
        .auth()
        .signInWithPopup(
            (new $window.firebase.auth.GoogleAuthProvider())
                .addScope('https://www.googleapis.com/auth/userinfo.email'));

export const facebookSignIn = ($window) =>
    $window
        .firebase
        .auth()
        .signInWithPopup(
            (new $window.firebase.auth.FacebookAuthProvider())
                .addScope('email'));

export const hasClaim = (tokenResult, claimName, predicate = (v) => Boolean(v)) => (
    tokenResult &&
    tokenResult.claims &&
    predicate(tokenResult.claims[claimName])
);

const _makeHandlerToNormalizeAuthState = (eventemitter, firebase) =>
    () => lets([firebase.auth()], ({ currentUser }) =>
        ((currentUser)
            ? currentUser.getIdTokenResult().then((tokenResult) =>
                eventemitter.emit('firebase-auth-new-state', {
                    user: currentUser,
                    jwt: tokenResult.token,
                    tokenResult,
                }))
            : eventemitter.emit('firebase-auth-new-state', {
                user: null,
                jwt: null,
                tokenResult: null,
            })));

const _loadFirebaseAppSdk = () =>
    load({
        script: `https://www.gstatic.com/firebasejs/${FIREBASE_VERSION}/firebase-app.js`,
        setup: (node) => {
            node.async = false;
        },
    });

export const getUserIdToken = (forceRefresh = false) => {
    const currentUser = spa.$window.firebase.auth().currentUser;
    return currentUser
        ? currentUser.getIdToken(forceRefresh)
        : Promise.resolve(null);
};


export const _bindFirebaseImpl = ($window, eventemitter, firebaseConfig) =>
    _loadFirebaseAppSdk() // firebase-app MUST load first.
        .then(() =>
            load(`https://www.gstatic.com/firebasejs/${FIREBASE_VERSION}/firebase-auth.js`))
        .then(() =>
            $window.firebase.initializeApp(firebaseConfig))
        .then(() =>
            $window.firebase.auth().onAuthStateChanged(
                _makeHandlerToNormalizeAuthState(eventemitter, $window.firebase)))
        .then(() =>
            $window.firebase);

export const bindFirebase = mandate(
    _bindFirebaseImpl,
    signature(({ func, instanceOf, shape, string }) => ({
        domain: [
            shape({ document: shape({ createElement: func }) }, { allowExtraKeys: true }),
            shape({ on: func, emit: func }, { allowExtraKeys: true }),
            shape({
                apiKey: string,
                authDomain: string,
                databaseURL: string,
                projectId: string,
                storageBucket: string,
                messagingSenderId: string,
            }),
        ],
        range: instanceOf(Promise),
    })));
