import { bem, m } from '#/browser-framework';
import { getPreviewColor } from '#/rp-facing/views/ColorControl';

function value(field) {
    return field && field.value
        ? getPreviewColor(field.value)
        : '#FFFFFF';
}

export const emailPreviewBem = bem`
    EmailSettings__mailBody
        header
        beginBackground
        actionTile
        actionButton
        content
        paragraph
        colorBar
        logo
`;


export const getEmailPreview = (fields, logoUrl) => {
    return m(emailPreviewBem.block,
        m(emailPreviewBem.header,
            (logoUrl
                ? m(`img.${emailPreviewBem.logo}`, {
                    src: logoUrl,
                })
                : null),
            m(emailPreviewBem.colorBar, {
                style: {
                    background: value(fields.barBelowWordmarkBgColor),
                },
            })
        ),
        m(emailPreviewBem.beginBackground, {
            style: {
                background: value(fields.boxBehindButtonBgColor),
            },
        }, m(emailPreviewBem.actionTile, {
            style: {
                color: value(fields.boxBehindButtonFgColor),
            },
        }, 'Action Required to Complete Your Trust & Safety Check'),
        m(emailPreviewBem.actionButton, {
            style: {
                background: value(fields.buttonBgColor),
                color: value(fields.buttonFgColor),
            },
        }, 'Begin Now')),
        m(emailPreviewBem.content,
            m(`p.${emailPreviewBem.paragraph}`, 'Thank you for submitting your application. To ensure the trust and safety of our community, we require all applicants to go through our standard background check and identity proofing process. Please click the link above, and provide all the requested information. You will need access to your photo ID to complete the process.'),
            m(`p.${emailPreviewBem.paragraph}`, 'Thank you for your help in ensuring the trust and safety of our community.')
        )
    );
};
