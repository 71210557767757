import { bem, m, harness, router, spa } from '#/browser-framework';
import { Button, Named } from '#/browser-framework/comps';
import { PhoneDelegationInput } from '#/ido-lib/views/PhoneDelegationInput';

import { WithLeftNav } from '#/rp-facing/views/Layouts';

import PhoneSvgUri from '#/rp-facing/images/delegation-phone.svg';


const DelegationInstructions = {
    btx: bem`
        DelegationInstructions
            form
            check
            heading
            subHeading
            stepsHeading
            steps
            step
            label
            left
            right
            field`,
    view({
        attrs: {
            viewModel: {
                user: {
                    onboarding,
                },
            },
        },
    }) {
        const { btx } = DelegationInstructions;
        const check = m(btx.check, m(Named.Icon, { name: 'check-circle-outlined' }));

        return m(btx.block,
            m(`h2${btx.heading}`,
                'Confirm your identity'),
            m(btx.subHeading,
                'Please photograph the front and back of your ID laying on a ' +
                'dark surface. Your ID should be clearly visible in the photos.'),
            m(btx.form,
                m(`img${btx.left}`, { src: PhoneSvgUri }),
                m(btx.right,
                    m(`h2${btx.stepsHeading}`,
                        'We sent you a text message. Here\'s what to do next.'),
                    m(btx.steps,
                        m(btx.step,
                            check,
                            'Open the text message on your mobile device and click the link. ',
                            m('strong', 'Leave this page open.')),
                        m(btx.step,
                            check,
                            'Follow the instructions to upload photos of your identity document from your mobile device.'),
                        m(btx.step,
                            check,
                            'Once completed, you will be automatically redirected to the next page.')),
                    m(btx.field,
                        m(btx.label, 'Didn\'t get a text? Try again.'),
                        m(PhoneDelegationInput, harness('delegation-input', {
                            id: 'id-delegation-phone',
                            delegationController: onboarding.idScanFormModel.delegationController,
                            onDelegationRequest: onboarding.idScanFormModel.onDelegationRequest,
                            buttonLabel: 'Send',
                        }))))),
            m(Button, {
                flags: {
                    secondary: true,
                },
                onpress() {
                    router.go('/setup/overview');
                },
            }, 'Do this later'));
    },
};


export default () => {
    spa.setView(DelegationInstructions, {
        layout: WithLeftNav,
    });
};
