import { once } from '#/universal-framework/async';

import { spa } from '#/browser-framework';
import { intercomInstance } from '#/intercom';
import { loadStripe, paymentController } from '#/stripe-pos';
import { aggregate } from '#/ido-lib/aggregation';
import { curate } from '#/ido-lib/curation';
import {
    exchangeSingleUseToken,
    getVerificationRequestDetails,
    getCountriesInRegion,
    submitPayment,
    setSessionSource,
    setUp,
} from '#/ido-lib/idoWebClient';

import { onboardPhoneNumberFormModel } from './onboardPhoneNumberFormModel';
import { businessFormModel } from './businessFormModel';
import { idScanFormModel } from './idScanFormModel';
import { paymentFormModel } from './paymentFormModel';
import { termsFormModel } from './termsFormModel';
import { packageSelectionFormModel } from './packageSelectionFormModel';


const _makePosController = once((amount, currency) => {
    return loadStripe(spa.$window).then((stripe) => {
        const paymentC = paymentController({
            stripe,
            $window: spa.$window,
            currency,
            amount,
            submitPayment,
        });

        paymentC.on('before-payment', spa.redraw);
        paymentC.on('element-changed', spa.redraw);

        return paymentC;
    });
});

// Responsible for coordinating the onboarding state of the end user.
export function onboardingController(user) {
    const iface = {};

    iface.isAttrComplete = (attrType) =>
        iface.request.input[attrType].complete;

    iface.isEveryAttrComplete = (predicate) =>
        Object.values(iface.request.input).filter(predicate).every(
            ({ complete }, _, { length }) => length && complete);

    iface.areTermsSigned = () =>
        iface.isAttrComplete('business.evidentid_self_service.tc_acceptance');

    iface.hasProvidedContactNumber = () =>
        iface.isAttrComplete('identity.phonenumber');

    iface.isUserVerified = () =>
        iface.isEveryAttrComplete(({ attrType }) =>
            attrType.startsWith('identity_assurance.'));

    iface.isBusinessVerified = () =>
        iface.isEveryAttrComplete(({ attrType }) =>
            attrType.startsWith('business.') &&
            !attrType.startsWith('business.evidentid_self_service'));

    iface.isPlanSelected = () =>
        iface.isAttrComplete('business.evidentid_self_service.package_selection');

    iface.hasBillingInfo = () =>
        !iface.request.balance || iface.paymentFormModel.complete;

    iface.isDoneOnboarding = () => (
        user.firebaseProfile &&
        user.firebaseProfile.emailVerified &&
        iface.serviceAssertedState &&
        iface.serviceAssertedState.landingTransition === 'rpweb');

    iface.isDataFilledOut = () => (
        iface.areTermsSigned() &&
        iface.isUserVerified() &&
        iface.isBusinessVerified() &&
        iface.hasBillingInfo() &&
        iface.isPlanSelected());

    iface.shouldShowPlanSelection = () => (
        iface.areTermsSigned() &&
        iface.isUserVerified() &&
        iface.isBusinessVerified() &&
        iface.hasBillingInfo() &&
        !iface.isPlanSelected());

    iface.shouldShowTermsInDialog = () => (
        iface.serviceAssertedState.landingTransition === 'selfServiceDataEntry' &&
        !iface.areTermsSigned() &&
        iface.isUserVerified() &&
        iface.isBusinessVerified() &&
        iface.hasBillingInfo());

    iface.isOnboarding = () =>
        !iface.isDoneOnboarding();

    iface.resolveRequirements = (request) => {
        const vowPos = (request.balance)
            ? _makePosController(
                request.balance.amount,
                request.balance.currency)
            : Promise.resolve({ complete: true });

        const vowCountries = getCountriesInRegion();

        return Promise
            .all([vowPos, vowCountries])
            .then(([paymentC, countries]) => {
                iface.request = request;
                iface.onboardPhoneNumberFormModel = onboardPhoneNumberFormModel(request);
                iface.businessFormModel = businessFormModel(request);
                iface.paymentFormModel = paymentFormModel(paymentC);
                iface.idScanFormModel = idScanFormModel(request, { countries, user });
                iface.termsFormModel = termsFormModel(request, { user });
                iface.packageSelectionFormModel = packageSelectionFormModel(request);
            });
    };

    iface.openSalesChannel = () => {
        const intercomSettings = {
            app_id: deploy.WEB_PUBLIC_INTERCOM_APP_ID,
            email: user.email,
            name: user.displayName,
        };

        iface.Intercom = intercomInstance(spa.$window, intercomSettings, {
            beforeLoad: false,
        });

        iface.Intercom('boot', intercomSettings);
    };

    iface.clear = () => {
        if (iface.Intercom) {
            iface.Intercom('shutdown');
        }
    };

    iface.deriveIdoOnboardingState = (canary, requestId) => {
        setSessionSource(spa.$window);
        setUp({ canary, rprId: requestId });

        return getVerificationRequestDetails(requestId)
            .then((request) => iface.resolveRequirements(curate(aggregate(request))));
    };

    iface.deriveOnboardingState = () =>
        user.client.requestJson({ url: '/userInfo', distrustBearerToken: true })
            .then((state) => {
                iface.serviceAssertedState = state;

                return ({
                    'selfServiceDataEntry': () => {
                        const { authToken, requestId } = iface.serviceAssertedState;

                        iface.openSalesChannel();

                        return exchangeSingleUseToken(authToken).then((canary) =>
                            iface.deriveIdoOnboardingState(canary, requestId));
                    },
                    'selfServicePending': iface.openSalesChannel,
                    'rpweb': () => null,
                }[state.landingTransition])();
            })
            .then(() => {
                return iface.serviceAssertedState;
            });

    return iface;
}
