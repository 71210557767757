import { m, spa, support } from '#/browser-framework';
import { shallowKeyFilter } from '#/universal-framework/objects';


function makeViewModel({
    width,
    height,
    hMargin = 10,
    vMargin = 10,
    pointRadius = 5,
    edgeStrokeColor = '#00f',
    pointFillColor = '#f00',
} = {}) {
    let _selected;
    const mobile = support.isMobileBrowser;

    const iface = {};

    iface.edgeStrokeColor = edgeStrokeColor;

    iface[(mobile) ? 'ontouchstart' : 'onmousedown'] = (e) => {
        e.preventDefault();

        _selected = spa.viewModel[e.target.id];
    };

    iface[(mobile) ? 'ontouchmove' : 'onmousemove'] = (e) => {
        if (_selected) {
            const r = spa.viewModel.r;

            _selected.x = e.clientX - r.x;
            _selected.y = e.clientY - r.y;

            spa.redraw();
        }
    };

    iface[(mobile) ? 'ontouchend' : 'onmouseup'] = () => {
        _selected = null;
    };

    iface.r = null;

    iface.tl = {
        id: 'tl',
        x: hMargin,
        y: vMargin,
        pointRadius,
        pointFillColor,
    };

    iface.tr = {
        id: 'tr',
        x: width - hMargin,
        y: vMargin,
        pointRadius,
        pointFillColor,
    };

    iface.br = {
        id: 'br',
        x: width - hMargin,
        y: height - vMargin,
        pointRadius,
        pointFillColor,
    };

    iface.bl = {
        id: 'bl',
        x: hMargin,
        y: height - vMargin,
        pointRadius,
        pointFillColor,
    };

    return iface;
}

const LatticePoint = {
    view({
        attrs: {
            id,
            pointRadius: r,
            pointFillColor: fill,
            x: cx,
            y: cy,
        },
    }) {
        return m('circle', {
            draggable: 'true',
            id,
            r,
            cx,
            cy,
            fill,
        });
    },
};

const Lattice = {
    view({
        attrs: {
            edgeStrokeColor: stroke,
            tl,
            tr,
            bl,
            br,
        },
    }) {
        return m('g',
            m('path', {
                fill: 'transparent',
                stroke, d: `M ${tl.x} ${tl.y} L ${tr.x} ${tr.y} L ${br.x} ${br.y} L ${bl.x} ${bl.y} Z`,
            }),
            m(LatticePoint, tl),
            m(LatticePoint, tr),
            m(LatticePoint, bl),
            m(LatticePoint, br));
    },
};


// 4 freely draggable points connected by edges.
// Used to select an area of an image.
export const QuadrilateralLattice = {
    makeViewModel,
    oncreate({ attrs, dom }) {
        attrs.r = dom.getBoundingClientRect();
        spa.redraw();
    },
    view({
        attrs,
    }) {
        const {
            width,
            height,
            edgeStrokeColor,
            tl,
            tr,
            br,
            bl,
            r,
        } = attrs;

        const svgAttrs = Object.assign(
            shallowKeyFilter(attrs, (k) => k.startsWith('on')),
            { width, height });

        return m('svg', svgAttrs, (r) && m(Lattice, {
            edgeStrokeColor,
            tl,
            tr,
            br,
            bl,
        }));
    },
};
