export function paymentFormModel(paymentController) {
    const iface = {};

    iface.pos = paymentController;
    iface.maySubmit = () => iface.pos.awaitingPaymentRequest;
    iface.submit = () => iface.pos.pay().then(() => {
        iface.complete = true;
    });

    return iface;
}
