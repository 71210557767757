import { CapturedDocument } from '#/evident-attributes/views';
import { spa } from '#/browser-framework';
import { types } from '#/universal-framework/functions';


const _keyFromAttr = (attr) =>
    ((types.nonNullObj(attr) && types.nonNullObj(attr.value))
        ? attr.value.$objectType
        : null);

const stateFromAttr = (attr) => (({
    CapturedDocument: () => CapturedDocument.makeViewModel({
        $window: spa.$window,
        value: attr.value,
    }),
})[_keyFromAttr(attr)] || (() => ({})))();

// Used as a stopgap for unique "missing" names
let autoincrement = 0;

// Used to detect duplicate display names, causing "ambiguous" attributes.
const nameCounts = {};

function getDummyName() {
    return `MISSING ${autoincrement++}`;
}


/*
 * An attribute with a value according to the RP web subject.
 */
export function SubjectiveReportedAttribute(objective) {
    const {
        displayName,
        type,
        status,
        value,
    } = objective;


    return {
        value,
        status,
        path: type,
        name,
        displayName: displayName || getDummyName(),
        state: stateFromAttr(objective),
    };
}


/*
 * An attribute that may be categorized in an archive for selection.
 */
export function SubjectiveOrganizedAttribute(objective, {
    namespace = 'default',
} = {}) {
    const {
        category,
        group,
        readableAttributeName,
        attributePath: path,
        value,
    } = objective;

    const name = readableAttributeName || getDummyName();

    const univocalName = `${category} ➔ ${group} ➔ ${name}`;

    // Track ambiguous names.
    if (!(namespace in nameCounts)) {
        nameCounts[namespace] = {};
    }

    nameCounts[namespace][name] = (nameCounts[namespace][name] || 0) + 1;

    return {
        value,
        path,
        name,
        category,
        group,
        univocalName,
        selected: false,
        get displayName() {
            return (nameCounts[namespace][name] > 1)
                ? univocalName
                : name;
        },
    };
}
