import { m, bem, router } from '#/browser-framework';
import { Button, Named } from '#/browser-framework/comps';
import RouteTableHandlers from './RouteTableHandlers';
import PlanSelectionPackages from './PlanSelectionPackages';
import PlanSelectionServices from './PlanSelectionServices';


const classNames = bem`CustomPackageContactDetails
    subHeading
    form
    nextStepHeading
    instructions
    additionalInfo
    textarea
    label
    services
    service
    checkbox
    iconCheckbox
    controls
    `;

const OTHER_LABEL = 'Other (Please describe below)';

const CustomPackageContactDetailsView = {
    view({ attrs: { viewModel } }) {
        const packageSelectionFormModel = viewModel.user.onboarding.packageSelectionFormModel;

        return m(classNames.block,
            m('h2',
                'Let us know what you\'re looking for'),
            m(classNames.subHeading,
                'Every business has different needs. We can create a unique configuration package for your business from our range of identity and credential verification services.'),
            m(classNames.form,
                m(classNames.instructions,
                    m(`h2${classNames.nextStepHeading}`, 'What verification services are you interested in?'),
                    m(classNames.services,
                        PlanSelectionServices.map((service) => {
                            const serviceLabel = (service === 'Other') ? OTHER_LABEL : service;

                            return m(`label.${classNames.service}`,
                                m(`input${classNames.checkbox}`, {
                                    type: 'checkbox',
                                    onchange: (e) => {
                                        packageSelectionFormModel.selectedServices[service] = e.target.checked;
                                    },
                                }),
                                m(Named.Icon, {
                                    class: `${classNames.iconCheckbox.replace('.', '')}`,
                                    name: packageSelectionFormModel.selectedServices[service] ? 'checked' : 'unchecked' }),
                                serviceLabel
                            );
                        }),
                    ),
                    m(classNames.additionalInfo,
                        m(classNames.label, 'Any addtional information we need to know'),
                        m(
                            `textarea${classNames.textarea}`, {
                                placeholder: 'Additional details describing what you\'re looking to verify',
                                onblur: (e) => {
                                    packageSelectionFormModel.additionalInfo = e.target.value;
                                },
                            }
                        )
                    ),
                    m(classNames.controls,
                        m(Button, {
                            flags: {
                                primary: true,
                                round: true,
                            },
                            disabled: packageSelectionFormModel.isSubmitting || viewModel.snackbar.open,
                            onpress() {
                                packageSelectionFormModel.submit(
                                    PlanSelectionPackages.CUSTOM.submissionValues,
                                    packageSelectionFormModel.additionalInfo,
                                    Object.keys(packageSelectionFormModel.selectedServices).filter(
                                        (key) => packageSelectionFormModel.selectedServices[key])
                                ).then(() => {
                                    router.go('/setup/schedule-consulting-call');
                                }).catch(() => {
                                    viewModel.snackbar.display('Unable to send your information RETRY', false);
                                });
                            },
                        }, 'Submit')))));
    },
};

const CustomPackageContactDetails = {
    view: CustomPackageContactDetailsView,
    routeTableHandler: RouteTableHandlers.createWithLeftNavHandler(CustomPackageContactDetailsView),
};

export default CustomPackageContactDetails;
