import { m } from '#/browser-framework';
import { Toggle } from '#/browser-framework/comps';


export default {
    view({
        children,
        attrs: {
            id,
            checked,
            onchange,
        },
    }) {
        return m('.LabeledToggle',
            m('span.LabeledToggle__text', children),
            m('span.LabeledToggle__buttoncell',
                m(Toggle, {
                    id,
                    checked,
                    onchange,
                })));
    },
};
