import { bem, m, spa } from '#/browser-framework';

import { AdminLayout, WithLeftNav } from '#/rp-facing/views/Layouts';
import { EmailTextField } from '#/rp-facing/views/pages/FormFields/EmailText/EmailTextField';
import { ColorField } from '#/rp-facing/views/pages/FormFields/Color/ColorField';
import { withSelectedSetting } from '#/rp-facing/core/routing/routeHandlerDecorators';
import { formBem } from '#/rp-facing/styles/FormsBem';
import { getEmailPreview } from './EmailPreview';

const emailBem = bem`
    EmailSettings
        theme
        visualAid
        themeConfig
`;


export const EmailSettingsPageView = {
    view({
        attrs: {
            viewModel: App,
        },
    }) {
        if (!App.user.selectedRp) {
            return m(AdminLayout, { App, subtitle: 'Email Settings' });
        }

        const saveHistory = () => App.user.selectedRp.settings.saveHistory();
        const addHistoryFn = (field) => Object.assign(field, { saveHistory });
        const { fields } = App.user.selectedRp.settings.email;
        const { branding } = App.user.selectedRp.settings;
        const { wordmark } = branding.fields;
        const { defaultUrl } = wordmark.value;
        const emailPreview = getEmailPreview(fields, defaultUrl);

        return m(AdminLayout, {App, subtitle: 'Email Settings'},
            m(emailBem.block,
                m(emailBem.theme,
                    m(emailBem.visualAid,
                        emailPreview,
                        m('.extraNote', 'Note: This text displayed in the email above is for preview purposes only. The actual text used in your email is controlled on a per-request or per-template basis.')
                    ),
                    m(emailBem.themeConfig,
                        m('p', 'These settings affect the appearance and configurations of email notifications that your users receive when you send information requests.'),
                        m(`h3.${emailBem.title}`, 'Sender Details'),
                        m(formBem.block,
                            m(formBem.fieldset,
                                m(EmailTextField, addHistoryFn(fields.fromName),
                                    m.trust('Email &ldquo;From&rdquo; Name:')),
                                m(EmailTextField, addHistoryFn(fields.replyToName),
                                    m.trust('Email &ldquo;Reply To&rdquo; Name:'))
                            ),
                            m(formBem.fieldset,
                                m(EmailTextField, addHistoryFn(fields.replyToAddress),
                                    m.trust('Email &ldquo;Reply To&rdquo; Address:'))
                            )),
                        m(`h3.${emailBem.title}`, 'Appearance'),
                        m('p', 'Hexadecimal (ex: #ff5a60) or RGB (ex: 255,90,96)'),
                        m(formBem.block,
                            m(formBem.fieldset,
                                m(ColorField, addHistoryFn(fields.buttonBgColor), '"Begin now" Button:'),
                                m(ColorField, addHistoryFn(fields.buttonFgColor), '"Begin now" Text On Button:')
                            ),
                            m(formBem.fieldset,
                                m(ColorField, addHistoryFn(fields.boxBehindButtonBgColor), 'Background Behind Call to Action:'),
                                m(ColorField, addHistoryFn(fields.boxBehindButtonFgColor), 'Call to Action Text:')
                            ),
                            m(formBem.fieldset,
                                m(ColorField, addHistoryFn(fields.barBelowWordmarkBgColor), 'Color Bar Below Logo:'),
                                m(formBem.field)
                            ))))));
    },
};

export const EmailSettingsPage = withSelectedSetting(() => {
    spa.setView(EmailSettingsPageView, {
        layout: WithLeftNav,
    });
}, {
    pendingLayout: WithLeftNav,
    settingName: 'email',
});
