import { m } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';


export default {
    view({attrs: {metrics, label, chart, startDate, endDate}}) {
        if (!metrics) {
            return m('.AnalyticsChart', m(Spinner));
        }

        let dateRange = 'Last 3 days';

        if (startDate && endDate) {
            const dispStart = startDate.format('M/DD/YYYY');
            const dispEnd = endDate.format('M/DD/YYYY');
            dateRange = m.trust(`${dispStart}&ndash;${dispEnd}`);
        }

        return (
            m('.AnalyticsChart',
                m('header.AnalyticsChart__header',
                    m('strong.AnalyticsChart__label', label),
                    m('span.AnalyticsChart__daterange',
                        dateRange)),
                m(chart, metrics))
        );
    },
};
