import { m, tx } from '#/browser-framework';
import { pointable } from '#/browser-framework/comps';


export default {
    selected: 0,
    view({attrs: {labels}, state, children}) {
        return m('.TabbedView',
            m('.TabbedView__tabs',
                labels.map((text, i) => {
                    return m(pointable(tx('TabbedView__tab', {selected: i === state.selected})), {
                        key: i,
                        onpointerup: () => {
                            state.selected = i;
                        },
                    }, text);
                })),
            children[state.selected]);
    },
};
